import React, { useEffect, useState } from 'react';
import { Button, Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListProject from '../components/ListProject';
import { InputText } from '../../../components/input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import { Tooltip } from 'react-tooltip';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import ButModal from '../../../components/Modal/ButModal';

export const Projects = () => {
  const [search, setSearch] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'projects' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed border-spacing-2">
        <Link
          to={'./new'}
          /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-60 hover:text-blue-600 hover:border-blue-400'}>*/
          className={
            'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
          }
        >
          <div className="absolute left-[40%]">
            <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Project
          </div>
        </Link>

        <div className="block col-span-10 p-4 bg-white ">
          <div className="flex items-center">
            <p className="flex-shrink truncate text-sm font-medium text-blue-600">Project name</p>
            <span className="ml-2 rounded bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">0</span>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className="flex items-center text-sm text-gray-500">Description</p>
            </div>
            <div className=" mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
        </div>
        <div className="flex col-span-1 items-center bg-white">
          <RiEditBoxLine className="h-6 w-6 m-auto text-blue-600"></RiEditBoxLine>
        </div>
        <div className="flex col-span-1 items-center bg-white">
          <RiDeleteBinLine className="h-6 w-6 m-auto text-red-500"></RiDeleteBinLine>
        </div>
      </div>

      <ListProject filter={{ projectName: search, projectDescription: search }} />
    </div>
  );
};
