import { array, object, string } from 'yup';
import { parametersValidation } from './parametersValidation';
import { assessmentConfigurationsValidation } from './assessmentConfigurationsValidation';
import { displayConfigurationsValidation } from './displayConfigurationsValidation';
import { variableConfigurations } from './variableConfigurations';
import { evaluateParameterFormula } from '../../../customization-process/utils/evaluateParameterFormula';
import { SkipTests } from './skipTest';

export const basicScenarioTemplateSchema = object().shape({
  name: string().required('Template name is required').nullable(),
  displayConfigurations: displayConfigurationsValidation,
  assessmentConfigurations: assessmentConfigurationsValidation,
  parameters: array().when(['$skipTests'], ([skipTests], schema, options) => {
    // console.log(skipTests);
    if (skipTests && skipTests.includes(SkipTests.PARAMETER_TEST)) return schema.nullable();
    return parametersValidation;
  }),
  variableConfigurations: variableConfigurations,
});
