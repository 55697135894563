import { TreeInfoDto } from '../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { PhaseDto } from '../../../api/project/projectApi';

export type ImportMapping = {
  type: 'PHASE' | 'TREE';
  id: string;
  name: string;
};

export const checkImportMapping = (importJson: string, trees: TreeInfoDto[], phases: PhaseDto[]) => {
  const mapping: ImportMapping[] = [];
  const parseBasicScenarioTemplate: any = JSON.parse(importJson);

  parseBasicScenarioTemplate.assessmentConfigurations.forEach(
    (ac) =>
      ac.phaseConfigurations?.forEach((pc) => {
        const phase = phases.find((p) => p.id === pc.phaseId);
        if (!phase && !mapping.some((m: any) => m.id === pc.phaseId)) {
          mapping.push({
            type: 'PHASE',
            id: pc.phaseId,
            name: pc.phaseName,
          });
        }
        const tree = trees.find((t) => t.id === pc.treeId);
        if (!tree && !mapping.some((m: any) => m.id === pc.treeId)) {
          console.log({ pc });
          mapping.push({
            type: 'TREE',
            id: pc.treeId,
            name: pc.treeName,
          });
        }
      }),
  );

  return mapping;
};
