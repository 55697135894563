import { MdOutlineLibraryAddCheck } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { DisplayConfiguration, Tab } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Tooltip } from 'react-tooltip';
import classNames from '../../../utils/classNames';
import CustomizationTabForm from './CustomizationTabForm';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { IndexedParameter } from './CustomizationTemplateForm';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../components/Elements';

type CustomizationPhaseFormProps = {
  parameters: IndexedParameter[];
  displayConfiguration: DisplayConfiguration;
  onValidate: (tab: string, callBack: (tab: string) => void) => void;
  onFormChange: () => void;
};

function CustomizationPhaseForm(props: CustomizationPhaseFormProps) {
  const { displayConfiguration, parameters, onValidate, onFormChange } = props;
  const { tabs } = displayConfiguration;

  const renderCounter = useRenderCounter();
  const [currentTab, setCurrentTab] = useState<string | undefined>(() => {
    for (const t of tabs as Tab[]) {
      if (parameters.filter((p) => p.display?.tab === t.name).length > 0) {
        return t.name;
      }
    }
    return undefined;
  });

  const {
    formState: { errors },
    setFocus,
  } = useFormContext();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      //When changing tab we set the focus on the first error
      const invalidParameterIndexes = errors.parameters?.filter(Boolean)?.map((ep, index) => (ep ? index : undefined));
      const invalidTabParameter = parameters?.findIndex(
        (p) => p.display?.tab === currentTab && invalidParameterIndexes.includes(p.index),
      );
      if (invalidTabParameter !== -1) {
        setFocus(`parameters.${parameters[invalidTabParameter].index}.value`);
      }
    }
  }, [currentTab]);

  return (
    <>
      {renderCounter}
      <div className="pt-2 pl-2 border-b border-gray-200 flex bg-white">
        <nav className="flex flex-nowrap overflow-x-auto" aria-label="Tabs">
          {tabs?.map((tab) => {
            const tabParameters = parameters?.filter((p) => p.display?.tab === tab.name);
            if (tabParameters.length) {
              const invalidParameters =
                Object.keys(errors).length > 0
                  ? errors.parameters?.filter((ep, index) => tabParameters.map((p) => p.index).includes(index) && ep)
                  : [];
              return (
                <div
                  key={tab.name}
                  onClick={() => setCurrentTab(tab.name)}
                  className={classNames(
                    currentTab === tab.name
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-blue-500 hover:border-blue-200 hover:text-blue-700',
                    'border-b-2 py-4 flex text-sm font-medium cursor-pointer',
                  )}
                >
                  <div
                    className={classNames(
                      !invalidParameters || invalidParameters?.length === 0 ? '' : 'text-red-500',
                      'flex-1 mx-4 whitespace-nowrap',
                    )}
                  >
                    {tab.name}
                  </div>

                  <div
                    className={classNames(
                      !invalidParameters || invalidParameters?.length === 0
                        ? 'border-transparent bg-white'
                        : 'border-red-500',
                      'h-5 w-6 bg-red-200 mr-2 text-sm text-center rounded text-red-700',
                    )}
                  >
                    {invalidParameters && invalidParameters.length > 0 && invalidParameters?.length}
                  </div>
                </div>
              );
            }
          })}
        </nav>

        <Button
          className="relative bg-white border-0 float-right ml-auto"
          type={'button'}
          variant={'none'}
          onClick={() => onValidate(currentTab as string, setCurrentTab)}
        >
          {/*<Tooltip anchorSelect={'#validate_'+currentTab} content="Validate"
                             place={"left-start"}
                             style={{backgroundColor: "white", color: "rgb(29, 78, 213)", right: "0px", zIndex: 50}}
                             border="1px solid rgb(29, 78, 213)"/>*/}
          <MdOutlineLibraryAddCheck
            id={'validate_' + currentTab}
            className="relative w-6 h-6 cursor-pointer text-blue-600"
          />
        </Button>
      </div>

      {tabs?.map((tab: any) => {
        return (
          <div key={tab.name} className={classNames(tab.name === currentTab ? 'block' : 'hidden')}>
            <CustomizationTabForm
              {...tab}
              currentTab={currentTab}
              onFormChange={onFormChange}
              parameters={parameters.filter((p) => p.display?.tab === tab.name)}
            />
          </div>
        );
      })}
    </>
  );
}

export default CustomizationPhaseForm;
