import React from 'react';
import { RefCallBack } from 'react-hook-form';
import useRenderCounter from '../../hooks/useRenderCounter';
import classNames from '../../utils/classNames';

export type InputProps = {
  addonBefore?: any;
  addonAfter?: any;
  error?: string;
  value?: string | number | undefined;
  placeHolder?: string;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  className?: string;
  rows?: number;
  inputRef?: RefCallBack;
  hidden?: boolean;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, InputProps>((props, ref) => {
  const {
    className,
    addonBefore,
    addonAfter,
    value,
    placeHolder,
    onChange,
    label,
    error,
    description,
    id,
    rows,
    disabled,
    hidden,
    name,
  } = props;

  const renderCounter = useRenderCounter();

  return (
    <div className={classNames(hidden ? 'hidden' : '', className ? className : '', 'relative')}>
      {renderCounter}
      <label className="z-10 whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-50% via-transparent via-55% to-transparent absolute -top-[8px] z-[2] left-4 px-1 text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={classNames(
          error ? 'border-red-500' : '',
          'flex rounded border border-gray-300 focus:border-blue-500',
        )}
      >
        {addonBefore && (
          <span className="inline-flex items-center rounded-l border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonBefore}
          </span>
        )}
        <textarea
          id={id}
          name={name}
          disabled={disabled}
          ref={ref}
          rows={rows ? rows : 3}
          className="p-2 block w-full focus-visible:outline-none focus:border-2 border-transparent focus:border-blue-500  rounded shadow sm:text-sm"
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
        />
        {addonAfter && (
          <span className="inline-flex items-center rounded-r border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonAfter}
          </span>
        )}
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}
      {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  );
});
