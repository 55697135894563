import { Route, Routes } from 'react-router-dom';
import CreateTag from '../components/CreateTag';
import Tag from '../components/Tag';
import React from 'react';
import { Tags } from './Tags';

export const TagRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Tags />} />
      <Route path="edit/:tagId" element={<Tag />} />
      <Route path="/new" element={<CreateTag />} />
    </Routes>
  );
};
