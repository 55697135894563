import { Route, Routes } from 'react-router-dom';
import CreateCustomImpactMethod from '../components/CreateCustomImpactMethod';
import ImpactMethod from '../components/ImpactMethod';
import { ImpactMethods } from './ImpactMethods';

export const ImpactMethodsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ImpactMethods />} />
      <Route path=":impactMethodId" element={<ImpactMethod />} />
      <Route path="/edit/:impactMethodId" element={<ImpactMethod />} />
      <Route path="/new" element={<CreateCustomImpactMethod />} />
    </Routes>
  );
};
