import { Menu, Transition } from '@headlessui/react';
import { HiBars3BottomLeft } from 'react-icons/hi2';
import { Fragment } from 'react';
import classNames from '../../../utils/classNames';
import { IconType } from 'react-icons';

export type MenuItem = {
  name: string | undefined;
  description: string | undefined;
  onClick?: () => void;
  Icon: IconType;
};

type ActionMenuProps = {
  className?: string;
  onClick?: () => void;
  items: MenuItem[];
};

export default function ActionMenu(props: ActionMenuProps) {
  const { className, items, onClick } = props;

  return (
    <Fragment>
      <Menu as={'div'} className={classNames(className ? className : '', 'h-8')}>
        <Menu.Button type="button" className="rounded focus:outline-none" onClick={onClick}>
          <span className="sr-only">Open sidebar</span>
          <HiBars3BottomLeft
            className="text-blue-700 bg-blue-100 h-8 w-8 p-1 rounded hover:bg-blue-200"
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mr-3 w-72 origin-bottom rounded bg-white focus:outline-none border-2 z-50">
            <div className="py-1 z-50">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <div className="cursor-pointer group relative flex gap-x-5 rounded p-3 hover:bg-gray-50 text-sm z-50">
                      <div className="mt-1 flex h-8 w-8 flex-none items-center justify-center rounded">
                        {item.Icon && (
                          <item.Icon className="h-6 w-6 text-gray-600 group-hover:text-blue-600" aria-hidden="true" />
                        )}
                      </div>
                      <div>
                        <div onClick={item.onClick} className="font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </div>
                        <p className="text-xs text-gray-500">{item.description}</p>
                      </div>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  );
}
