import { array, object, string } from 'yup';
import { parametersValidation } from './parametersValidation';
import { variableConfigurations } from './variableConfigurations';
import { SkipTests } from './skipTest';

export const bomScenarioTemplateSchema = object().shape({
  name: string().required('Template name is required').nonNullable(),
  bomId: string().required('Bom is required').nonNullable(),
  itemScenarioTemplateConfiguration: array()
    .of(
      object().shape({
        itemId: string().required('Item is required'),
        scenarioTemplateId: string().required('Scenario template is required'),
        variableConfigurations: variableConfigurations,
      }),
    )
    .min(1, 'At least one scenario template must be applied on an item'),
  assessmentConfigurations: array()
    .of(
      object().shape({
        impactMethodId: string().required('Impact method is required'),
        assessmentType: string().required('Assessment type is required'),
      }),
    )
    .min(1, 'At least one assessment type is required'),
  parameters: array().when(['$skipTests'], ([skipTests], schema, options) => {
    // console.log(skipTests);
    if (skipTests && skipTests.includes(SkipTests.PARAMETER_TEST)) return schema.nullable();
    return parametersValidation;
  }),
});
