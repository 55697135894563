import { ScenarioForm } from './ScenarioForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAllScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import {
  ScenarioDto,
  Tag,
  useGetAllProjectQuery,
  useGetAllTagQuery,
  usePostScenarioMutation,
} from '../../../api/project/projectApi';
import toast from 'react-hot-toast/headless';

function CreateScenario() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [postScenario] = usePostScenarioMutation();
  const { data: projects } = useGetAllProjectQuery({});
  const { data: templates } = useGetAllScenarioTemplateQuery({});
  const { data: tags } = useGetAllTagQuery({});

  const onSubmit = async (scenario: ScenarioDto) => {
    // console.log('tags before', scenario.tags);
    scenario.tags = !scenario.tags ? new Array<Tag>() : scenario.tags?.map((t: any) => ({ id: t }));
    const scenarioToPost = {
      ...scenario,
      tags: scenario.tags,
    };
    // console.log('scenarioToPost', scenarioToPost);
    const newScenario: any = await postScenario({
      scenarioDto: scenarioToPost,
    }).unwrap();
    if ('error' in newScenario) {
      // console.log(newScenario.error);
      toast.error('Failed to create scenario');
    } else {
      toast.success('Scenario created successfully');
      navigate(`/app/customizations/${newScenario.id}`);
    }
  };

  return (
    <ScenarioForm
      initialValues={{
        projectId,
      }}
      className={'mt-3'}
      onSubmit={onSubmit}
      formMode={'CREATE'}
      projects={projects?.content}
      scenarioTemplates={templates?.filter((t) => t.visible && t.valid)}
      tags={tags}
    />
  );
}

export default CreateScenario;
