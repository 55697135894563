import React, { useMemo, useState } from 'react';
import { Item } from '../../../api/bom/bomApi';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi2';
import classNames from '../../../utils/classNames';
import '../style/style.css';
import { createPortal } from 'react-dom';
import { DefaultProps } from '../../../components/carousel/Carousel';

type BomTreeNodeProps = {
  node: Item;
  displayAppendix: boolean;
  parentId: string;
  items: Item[] | undefined;
  itemContentRef: any;
  quantity: number;
  // enableModalView: boolean
  selectedItem: Item & { parentId?: string };
  onSelectItem: (item: any) => void;
  itemContent?: (
    item: Item & {
      parentId?: string;
    },
    selectedItemId: string | undefined,
    items: Item[],
  ) => React.ReactElement | string | null;
  nodeAppendix?: (
    item: Item & {
      parentId?: string;
    },
    width: number,
    items: Item[],
  ) => React.ReactElement | string | null;
  nodeConfiguration: {
    nodeContent?: (item: Item & { parentId?: string }, items: Item[]) => React.ReactNode;
    backgroundColor?: ((item: Item & { parentId?: string }, items: Item[]) => string) | string;
    borderColor?: ((item: Item & { parentId?: string }, items: Item[]) => string) | string;
  };
  containerWidth: number;
} & DefaultProps;

function BomTreeNode(props: BomTreeNodeProps) {
  const {
    node,
    items,
    parentId,
    nodeConfiguration,
    selectedItem,
    displayAppendix,
    itemContentRef,
    containerWidth,
    quantity,
    className,
    nodeAppendix,
    itemContent,
    onSelectItem,
  } = props;

  const { components } = node;

  const [showChildren, setShowChildren] = useState(parentId === `root`);

  const handleClick = () => {
    setShowChildren(!showChildren);
  };

  const handleSelectItem = () => {
    onSelectItem({ ...node, parentId });
  };

  const hasModalContent = useMemo(
    () =>
      itemContent &&
      itemContent(
        {
          ...node,
          parentId,
        },
        selectedItem?.id,
        items as Item[],
      ),
    [node, selectedItem],
  );

  const backgroundColor = useMemo(
    () =>
      typeof nodeConfiguration.backgroundColor === 'function'
        ? nodeConfiguration.backgroundColor(
            {
              ...node,
              parentId,
            },
            items as Item[],
          )
        : nodeConfiguration.backgroundColor,
    [nodeConfiguration, parentId],
  );
  const borderColor = useMemo(() => {
    return typeof nodeConfiguration.borderColor === 'function'
      ? nodeConfiguration.borderColor(
          {
            ...node,
            parentId,
          },
          items as Item[],
        )
      : nodeConfiguration.borderColor;
  }, [nodeConfiguration, parentId, node]);

  const isSelectedItem = useMemo(() => {
    return node.id === selectedItem?.id && parentId === selectedItem?.parentId;
  }, [node, selectedItem]);

  if (!selectedItem && parentId === 'root') {
    handleSelectItem();
  }

  return (
    <li
      key={`${node.id}:${parentId}}`}
      className={classNames(displayAppendix ? '' : 'hidden', className ? className : '')}
    >
      <div className="treeview__level flex">
        {parentId !== 'root' ? (
          <span
            className={
              'absolute rounded border-gray-400 border-2 bg-blue-100 py-1 px-2 my-1 left-[-58px] top-[-20px] text-xs'
            }
          >
            {quantity}
          </span>
        ) : null}
        <span
          className={classNames(components?.length ? 'cursor-pointer' : '', 'treeview__level_before ')}
          onClick={handleClick}
          style={{
            backgroundColor: backgroundColor ? backgroundColor : '#6fd166',
            borderColor,
          }}
        >
          {' '}
          {components?.length ? (
            showChildren ? (
              <HiChevronDown className="h-6 w-6" />
            ) : (
              <HiChevronRight className="h-6 w-6" />
            )
          ) : null}
        </span>
        <span className="level-title block">
          <span
            className={classNames(
              hasModalContent ? 'cursor-pointer' : '',
              isSelectedItem ? 'bg-blue-400 text-white' : '',
              'border-2 rounded p-2 -ml-6 pl-6',
            )}
            onClick={() => (hasModalContent ? handleSelectItem() : undefined)}
          >
            {nodeConfiguration.nodeContent
              ? nodeConfiguration.nodeContent({ ...node, parentId }, items as Item[])
              : node.description}
          </span>
        </span>
        {displayAppendix && nodeAppendix ? (
          <div className="-mt-8 float-right mr-5" style={{ width: containerWidth / 2, zIndex: 100 }}>
            {nodeAppendix({ ...node, parentId }, containerWidth / 2, items as Item[])}
          </div>
        ) : null}
      </div>

      {itemContent &&
        itemContentRef?.current &&
        createPortal(
          <div
            key={`${parentId}.${node.id}`}
            id={`${parentId}.${node.id}`}
            className={classNames(isSelectedItem ? 'visible' : 'hidden', 'flex-1 flex flex-col')}
          >
            {itemContent(
              {
                ...node,
                parentId,
              },
              selectedItem?.id,
              items as Item[],
            )}
          </div>,
          itemContentRef?.current,
        )}

      {items && (
        <ul>
          {components?.map((childNode) => {
            const item = items.find((i) => i.id === childNode.itemId);
            const itemPath = parentId ? `${parentId}:${node.id}` : node.id;
            return (
              item && (
                <BomTreeNode
                  displayAppendix={showChildren}
                  node={item as Item}
                  parentId={itemPath as string}
                  key={item.id}
                  quantity={childNode.quantity ?? 0}
                  nodeAppendix={nodeAppendix}
                  items={items}
                  nodeConfiguration={nodeConfiguration}
                  itemContentRef={itemContentRef}
                  selectedItem={selectedItem}
                  itemContent={itemContent}
                  onSelectItem={onSelectItem}
                  containerWidth={containerWidth}
                />
              )
            );
          })}
        </ul>
      )}
    </li>
  );
}

export default BomTreeNode;
