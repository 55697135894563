import { ListableParameter, ListParameter, Parameter } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { ParameterInput } from './ParameterInput';
import { InputList } from '../../../../components/input/InputList';
import React, { Fragment, useMemo } from 'react';
import { IndexedParameter } from '../CustomizationTemplateForm';
import { useFormContext } from 'react-hook-form';
import { useCustomizationTemplateContext } from '../../hooks/CustomizationTemplateProvider';
import { Button } from '../../../../components/Elements';
import classNames from '../../../../utils/classNames';
import { Tooltip } from 'react-tooltip';
import { property } from 'lodash';
import { HiOutlineInformationCircle } from 'react-icons/hi';

type ListInputProps = IndexedParameter & {
  width: number | undefined;
  onFormChange: () => void;
  parameters: Parameter[];
  className?: string;
  name: string;
  label: string;
  itemName?: string;
};

export const ListInput = (props: ListInputProps) => {
  const { label, itemName, onFormChange, hidden, name, parameters } = props;

  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    register,
  } = useFormContext();

  const { displayErrors } = useCustomizationTemplateContext();

  return (
    <InputList
      control={control}
      {...props}
      name={name}
      item={(props as ListParameter).listItem?.reduce(
        (acc, current: ListableParameter) => ({
          ...acc,
          [current.parameterName as string]: current.value,
        }),
        {},
      )}
      hidden={!!hidden}
      label={label}
      renderItem={(field, index, remove, append) => {
        return (
          <div className={'col-span-12 mt-3 items-center border border-blue-300 px-3 rounded text-center flex'}>
            {itemName ? (
              <p className="self-center font-bold">
                {itemName} {index + 1}
              </p>
            ) : (
              <span className="content-center pr-3 mr-3 border-r border-blue-400 h-full">{index + 1}</span>
            )}
            <div className={'flex flex-col flex-1'}>
              {(props as ListParameter).listItem?.map((property: ListableParameter, indexElement) => {
                console.log(property.tooltip);
                const tooltipElement = property.tooltip ? (
                  <Fragment>
                    <HiOutlineInformationCircle
                      id={`${property.parameterName}${indexElement}-tooltip`}
                      className={classNames('z-2 ml-2 h-4 w-4 cursor-pointer hover:text-blue-600 text-blue-400')}
                    />
                    <Tooltip
                      anchorSelect={`#${property.parameterName}${indexElement}-tooltip`}
                      content={property.tooltip as string}
                      style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
                      border="1px solid rgb(29, 78, 213)"
                    />
                  </Fragment>
                ) : undefined;
                return (
                  <ParameterInput
                    {...property}
                    onFormChange={onFormChange}
                    className="my-3"
                    {...register(`parameters.${props.index}.value.${index}.${property.parameterName}`)}
                    label={
                      <Fragment>
                        <span className={'flex'}>
                          {property.alias} {property.unitOfMeasure ? `[${property.unitOfMeasure}]` : ''}{' '}
                          {tooltipElement}{' '}
                        </span>
                      </Fragment>
                    }
                    parameters={parameters}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};
