import { array, object, string } from 'yup';
import { SkipTests } from './skipTest';
import { validateParameterExists } from './validateParameterExists';

export const dependsOnValidation = array()
  .of(
    object().shape({
      parameterName: string().when(['$skipTests'], ([skipTests], schema) =>
        schema.test({
          name: SkipTests.DEPENDS_ON_FORMULA_PARAMETER_TEST,
          message: ({ value }) => `Depends on "${value}" was not found among the parameters`,
          test: (value, test) => {
            if (skipTests && skipTests.includes(SkipTests.DEPENDS_ON_FORMULA_PARAMETER_TEST)) return true;
            const parameters = test.options.context?.parameters;
            return validateParameterExists(parameters, value);
          },
        }),
      ),
    }),
  )
  .nullable();
