import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { enhancedCustomizationSpaceApi } from '../api/customizationSpace/customizationSpaceEnhancedEndpoint';
import { enhancedCustomizedScenarioApi } from '../api/customizedScenario/customizedScenarioEnhancedEndpoint';
import { enhancedLightweightCalculationEngineApi } from '../api/lightweightCalculationEngine/lightweightCalculationEngineEnhancedEndpoint';
import { enhancedProjectApi } from '../api/project/projectEnhancedEndpoint';
import { enhancedRemoteDispatcherApi } from '../api/remoteDispatcher/remoteDispatcherEnhancedEndpoint';
import { enhancedScenarioTemplateApi } from '../api/scenarioTemplate/scenarioTemplateEnhancedEndpoint';
import { enhancedBomApi } from '../api/bom/bomEnhancedEndpoint';
import { rtkQueryErrorLogger } from './errorMiddleware';
import { enhancedAuthenticationManagerApi } from '../api/authenticationmanager/authenticationManagerEnhancedEndpoint';
import authReducer from '../features/auth/authSlice';
import { enhancedLightweightOlcaCoreApi } from '../api/lightweightOlcaCore/lightweightOlcaCoreEnanchedEndpoint';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [enhancedScenarioTemplateApi.reducerPath]: enhancedScenarioTemplateApi.reducer,
    [enhancedCustomizedScenarioApi.reducerPath]: enhancedCustomizedScenarioApi.reducer,
    [enhancedCustomizationSpaceApi.reducerPath]: enhancedCustomizationSpaceApi.reducer,
    [enhancedLightweightCalculationEngineApi.reducerPath]: enhancedLightweightCalculationEngineApi.reducer,
    [enhancedLightweightOlcaCoreApi.reducerPath]: enhancedLightweightOlcaCoreApi.reducer,
    [enhancedProjectApi.reducerPath]: enhancedProjectApi.reducer,
    [enhancedRemoteDispatcherApi.reducerPath]: enhancedRemoteDispatcherApi.reducer,
    [enhancedBomApi.reducerPath]: enhancedBomApi.reducer,
    [enhancedAuthenticationManagerApi.reducerPath]: enhancedAuthenticationManagerApi.reducer,
    auth: authReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(enhancedScenarioTemplateApi.middleware)
      .concat(enhancedCustomizedScenarioApi.middleware)
      .concat(enhancedCustomizationSpaceApi.middleware)
      .concat(enhancedLightweightCalculationEngineApi.middleware)
      .concat(enhancedLightweightOlcaCoreApi.middleware)
      .concat(enhancedProjectApi.middleware)
      .concat(enhancedRemoteDispatcherApi.middleware)
      .concat(enhancedBomApi.middleware)
      .concat(enhancedAuthenticationManagerApi.middleware)
      .concat(rtkQueryErrorLogger),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
