import { IntegrationForm } from './IntegrationForm';
import { useNavigate } from 'react-router-dom';
import {
  IntegrationTemplate,
  PayloadType,
  RemoteFlow,
  useGetAllPayloadTypesQuery,
  useGetAllRemoteFlowsQuery,
  usePostIntegrationTemplateMutation,
} from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { toast } from 'react-hot-toast/headless';
import { Spinner } from '../../../components/Elements';
import { ScenarioTemplate, useGetAllScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';

function CreateIntegration() {
  const navigate = useNavigate();
  const [postIntegrationTemplate] = usePostIntegrationTemplateMutation();
  const { data: flows, isLoading: areFlowsLoading } = useGetAllRemoteFlowsQuery({});
  const { data: scenarioTemplates, isLoading: areScenarioTemplatesLoading } = useGetAllScenarioTemplateQuery({});
  const { data: payloadTypes, isLoading: arePayloadTypesLoading } = useGetAllPayloadTypesQuery({});

  const onSubmit = async (integrationTemplate: IntegrationTemplate) => {
    console.log('integration', integrationTemplate);
    const newIntegrationTemplate: any = await postIntegrationTemplate({
      integrationTemplate: integrationTemplate,
    }).unwrap();
    if ('error' in newIntegrationTemplate) {
      toast.error('Failed to create integration template');
    } else {
      toast.success('integration template created successfully');
    }
  };

  if (areFlowsLoading || arePayloadTypesLoading || areScenarioTemplatesLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <IntegrationForm
      className={'mt-2'}
      onSubmit={onSubmit}
      formMode={'CREATE'}
      payloadTypes={payloadTypes as PayloadType[]}
      flows={flows as RemoteFlow[]}
      scenarioTemplates={scenarioTemplates as ScenarioTemplate[]}
    />
  );
}

export default CreateIntegration;
