import { Fragment } from 'react';

type MapProps<T> = {
  items: T[];
  children?: T;
};

export const MapItems = (props: MapProps<any>) => {
  const { items, children } = props;

  return <Fragment>{items && items.map((item, index: number, array: any[]) => children(item, index, array))}</Fragment>;
};
