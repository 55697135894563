import { RiEditBoxLine, RiDeleteBinLine } from 'react-icons/ri';

import { ProjectDto, useDeleteProjectMutation, useGetAllProjectQuery } from '../../../api/project/projectApi';
import { Link } from '../../../components/Elements';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import { toast } from 'react-hot-toast/headless';
import React from 'react';
import ButModal from '../../../components/Modal/ButModal';
import { Tooltip } from 'react-tooltip';

function ListProject(props: any) {
  const { filter } = props;

  const [deleteProject] = useDeleteProjectMutation();

  const handleDeleteProject = async (projectId: string) => {
    const response: any = await deleteProject({ projectId });

    if (response.error) {
      toast.error('Failed to delete project');
    } else {
      toast.success(`Project deleted successfully`);
    }
  };

  const renderItem = (project: ProjectDto, additionalParameters: any) => {
    return (
      <li key={project.id}>
        <div className="grid grid-cols-12">
          <Link to={`${project.id}`} className="block col-span-10 hover:bg-blue-100 p-4">
            <div className="flex items-center">
              <p className="flex-shrink truncate text-sm font-medium text-blue-600">{project.name}</p>
              <span className="ml-2 rounded bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                {project.scenarioCount}
              </span>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">{project.description}</p>
              </div>
              <div className=" mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <p>{new Date(project?.created as string).toDateString()}</p>
              </div>
            </div>
          </Link>
          <Link to={`edit/${project.id}`} className="flex col-span-1 items-center hover:bg-blue-100">
            <Tooltip
              anchorSelect={'#edit'}
              content="Edit"
              style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
              border="1px solid rgb(29, 78, 213)"
            />

            <RiEditBoxLine id={'edit'} className="h-6 w-6 m-auto text-blue-600"></RiEditBoxLine>
          </Link>
          <ButModal
            label={<RiDeleteBinLine className="h-6 w-6" />}
            submitLabel={'Yes'}
            confirm={() => handleDeleteProject(project.id as string)}
            className={'flex col-span-1 items-center hover:bg-red-100 border-0 text-red-500 rounded-none'}
          >
            <Tooltip anchorSelect={'#delete'} content="Delete" />

            <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
              Are you sure you want to delete the "{project?.name}" project?{' '}
            </h2>
            {!!project?.scenarioCount && (
              <h4 className="text-md text-red-600 mb-4 mt-1 px-4 text-center">
                Please note that by deleting this project, all contained scenarios ({project?.scenarioCount}) will be
                deleted.
              </h4>
            )}
          </ButModal>
        </div>
      </li>
    );
  };

  return (
    <div className="bg-white shadow rounded">
      <ul className="divide-y divide-gray-200">
        <PaginationTable
          args={filter}
          noDataMessage={'No projects'}
          fetchData={useGetAllProjectQuery}
          renderItem={renderItem}
        />
      </ul>
    </div>
  );
}

export default ListProject;
