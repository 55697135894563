import axios from 'axios';
import { USER_REALM_MAPPER_API_URL } from '../../config';
import { setAuthenticated } from '../../features/auth/authSlice';
import { store } from '../../store';
import storage from '../../utils/storage'; // Replace with the correct import path

export async function logout(redirectUrl?: string) {
  // console.log(redirectUrl);
  try {
    const axiosInstance = axios.create({
      // Your Axios instance configuration here
      baseURL: USER_REALM_MAPPER_API_URL,
      // Other default options...
      withCredentials: true, // Enable sending credentials with requests
    });

    await axiosInstance.post('/logout', {}).then(() => {
      storage.clearToken();
      storage.clearUsername();
      storage.clearTargetRealm();
    });

    store.dispatch(setAuthenticated(false));
    // console.log("Logout called")
    window.location.replace(`/login?${redirectUrl ? 'redirectUrl=' + redirectUrl : ''}`);

    // Return the updated tokens or handle as needed
    return;
  } catch (error) {
    // Handle token refresh error
    throw error;
  }
}
