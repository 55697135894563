import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useGetAllowedRealmsUserRealmGetQuery,
  useLoginAuthPostMutation,
  useSetDefaultRealmDefaultRealmPostMutation,
} from '../../api/authenticationmanager/authenticationManagerApi';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated } from './authSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import storage from '../../utils/storage';

type AuthProps = {
  children: JSX.Element;
};

export type AuthUser = {
  username: string;
  role: 'USER' | 'ADMIN';
};

interface AuthContextType {
  login: (username: string, password: string, realm: string, onSuccess: any, OnError: any) => Promise<void>;
  user: AuthUser | undefined;
  changeDefaultRealm: (realm: string) => Promise<boolean>;
  setActualRealm: (realm: string) => void;
  allowedRealms: string[];
  actualRealm: string | undefined;
  defaultRealm: string | undefined;
}

const AuthContext = React.createContext<AuthContextType>({
  login: () => Promise.resolve(),
  user: undefined,
  changeDefaultRealm: () => Promise.resolve(false),
  setActualRealm: (undefined) => {},
  allowedRealms: [],
  actualRealm: undefined,
  defaultRealm: undefined,
});

function AuthProvider(props: AuthProps) {
  const { children } = props;
  const [storedAccessToken, setStoredAccessToken] = useState(storage.getToken);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const { data: realms, isSuccess } = useGetAllowedRealmsUserRealmGetQuery(
    storage.getUsername() ? { username: storage.getUsername() as string } : skipToken,
  );

  const [changeDefaultRealmFunction] = useSetDefaultRealmDefaultRealmPostMutation();
  const [loginFunction] = useLoginAuthPostMutation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const [defaultRealm, setDefaultRealm] = useState(realms?.default_realm || undefined);
  const [actualRealm, setActualRealm] = useState(defaultRealm);
  // const [redirectUrl, setRedirectUrl] = useState('/app/projects');
  const allowedRealms = realms?.allowed_realms || [];

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);

  useEffect(() => {
    setStoredAccessToken(storage.getToken());
  });

  useEffect(() => {
    if (!isAuthenticated) {
      // console.log("HERE",[...queryParams?.values()])
      let redirect = queryParams.has('redirectUrl') ? queryParams.get('redirectUrl') : window.location.pathname;

      if (redirect && ['/login', '/'].includes(redirect as string)) {
        redirect = '';
      } else {
        redirect = `?redirectUrl=${redirect}`;
      }
      // console.log("HERE1", redirect)
      navigate(`/login${redirect}`);
    } else {
      const { preferredUsername, roles } = extractPreferredUsernameFromToken(storedAccessToken);

      const role = roles?.includes('end-user') ? 'USER' : roles?.includes('expert') ? 'ADMIN' : '';
      setUser({
        username: preferredUsername || '',
        role: role || 'USER',
      });

      setActualRealm(storage.getTargetRealm() || storage.getLastLoggedInRealm() || realms?.default_realm || undefined);
      setDefaultRealm(realms?.default_realm || undefined);
    }
  }, [isAuthenticated, isSuccess, storedAccessToken]);

  const extractPreferredUsernameFromToken = (
    token: string | null,
  ): { preferredUsername: string | null; roles: string[] | null } => {
    if (token === null) {
      return { preferredUsername: null, roles: null };
    }

    try {
      const decodedToken: any = jwt(token);
      if (decodedToken && typeof decodedToken === 'object') {
        const preferredUsername = decodedToken.preferred_username || null;
        const roles = decodedToken.realm_access.roles || null; // Replace 'roles' with the actual claim name
        return { preferredUsername, roles };
      }
      return { preferredUsername: null, roles: null };
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return { preferredUsername: null, roles: null };
    }
  };

  const login = async (username: string, password: string, realm: string, onSuccess: any, onError: any) => {
    try {
      const { access_token: accessToken, roles } = await loginFunction({
        authenticationDto: {
          username: username,
          password: password,
          realm: realm,
        },
      }).unwrap();

      const role = roles.includes('end-user') ? 'USER' : roles.includes('expert') ? 'ADMIN' : '';

      const authUser = {
        username: username,
        role: role,
      } as AuthUser;

      // console.log(authUser);

      storage.setUsername(username);
      storage.setToken(accessToken);

      dispatch(setAuthenticated(true));
      setUser(authUser);

      onSuccess();
    } catch (error) {
      onError(error);
      console.error('Error during api call to login function', error);
    }
  };

  const changeDefaultRealm = async (defaultRealm: string) => {
    if (user !== undefined) {
      const response: any = await changeDefaultRealmFunction({
        defaultRealmDto: {
          username: user.username,
          default_realm: defaultRealm,
        },
      });

      setDefaultRealm(defaultRealm);

      if (!('error' in response)) {
        return true;
      }
    }

    return false;
  };

  const authContextValue: AuthContextType = {
    login,
    user,
    changeDefaultRealm,
    setActualRealm,
    allowedRealms,
    actualRealm,
    defaultRealm,
  };

  return <AuthContext.Provider value={{ ...authContextValue }}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const { login, user, setActualRealm, changeDefaultRealm, actualRealm, defaultRealm, allowedRealms } =
    useContext<AuthContextType>(AuthContext);
  return {
    login,
    user,
    setActualRealm,
    changeDefaultRealm,
    actualRealm,
    defaultRealm,
    allowedRealms,
  };
};

export default AuthProvider;
