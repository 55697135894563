import React, { useEffect, useMemo } from 'react';
import classNames from '../../../utils/classNames';
import { CustomizationParameterForm } from './CustomizationParameterForm';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { groupBy } from '../../../utils/groupBy';
import { IndexedParameter } from './CustomizationTemplateForm';
import { useFormContext } from 'react-hook-form';
import { getParameterErrorsForItem } from '../../comparison/utils/getParameterErrorsForItem';

type CustomizationTabFormProps = {
  description: string;
  currentTab: string;
  parameters: IndexedParameter[];
  onFormChange: () => void;
};

function CustomizationTabForm(props: CustomizationTabFormProps) {
  const { description, currentTab, parameters, onFormChange } = props;

  const renderCounter = useRenderCounter();

  const groupedParameters = useMemo(() => {
    //Get all parameter that are not displayed as dependency of a formula parameter
    const params = parameters.filter((p) => !p.display?.formulaParameter);
    return groupBy(params, (x) => x?.display?.group) ?? [];
  }, [parameters]);
  const {
    formState: { errors },
    setFocus,
  } = useFormContext();

  useEffect(() => {
    const errorParameters = getParameterErrorsForItem(errors, parameters);
    if (errorParameters) {
      setFocus(`parameters.${errorParameters[0]?.index}.value`);
    }
  }, [currentTab]);

  return (
    <div className="bg-white rounded-b mt-4">
      {renderCounter}
      {description && (
        <div className="p-2 border-2 ml-3 mr-3 mt-5 rounded">
          <div dangerouslySetInnerHTML={{ __html: description as string }} />
        </div>
      )}
      {Object.entries(groupedParameters).map(([group, params]) => {
        return (
          <div
            key={group}
            className={classNames(group !== 'undefined' ? 'border-2 pb-3 pt-3 rounded ml-3 mr-3' : '', 'mt-5 relative')}
          >
            {group !== 'undefined' && (
              <h1 className="w-full leading-border-text absolute -top-3 ">
                <span className="text-gray-500 ml-12 bg-white px-3">{group}</span>
              </h1>
            )}
            <div>
              {params.map((p: IndexedParameter) => {
                return <CustomizationParameterForm key={p.parameterName} onFormChange={onFormChange} {...p} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CustomizationTabForm;
