import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Form } from '../../../components/Form';
import { FormProps } from '../../../components/types';
import { PhaseDto } from '../../../api/project/projectApi';

type PhaseFormProps = FormProps;

export const PhaseForm = (props: PhaseFormProps) => {
  const { onSubmit, onError, initialValues, submitLabel } = props;

  const phaseValidation = Yup.object().shape({
    name: Yup.string().required('Phase name is required').nullable(),
    color: Yup.string().required('Color is required').nullable(),
  });

  const handleOnSubmit = (data: PhaseDto) => {
    onSubmit(data);
  };

  return (
    <>
      <Form
        className={'mt-3'}
        onSubmit={handleOnSubmit}
        onError={onError}
        validationSchema={phaseValidation}
        submitLabel={submitLabel || 'Create'}
        initialValues={initialValues}
        editing={true}
      >
        <Form.Item name="name" type="InputText" label="Phase Name" />
        <Form.Item name="description" type="InputText" label="Description" />
        <Form.Item name="color" type="ColorPicker" label="Phase Color" />
        <Form.Item name="id" type="InputText" hidden={true} />
      </Form>
    </>
  );
};
