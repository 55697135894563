import classNames from '../../utils/classNames';
import React, { Fragment, ReactNode } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button } from '../Elements';
import { HiPlusCircle } from 'react-icons/hi2';
//
type InputListProps = {
  control: any;
  name: string;
  item?: any;
  hidden?: boolean | undefined;
  disabled?: boolean;
  label?: string;
  addButtonLabel?: string;
  description?: string;
  renderItem: (field: any, index: number, remove: any, append: any) => ReactNode;
  className?: string;
  error?: string | null | undefined;
  onAppend?: (item: any) => Promise<any>;
};

export const InputList = React.forwardRef<any, InputListProps>((props, ref) => {
  const { label, addButtonLabel, hidden, control, name, item, className, error, renderItem, description, onAppend } =
    props;

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <div className={classNames(className ? className : '', hidden ? 'hidden' : '', 'relative')}>
      <label className="-mt-2 ml-3 whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-65%  via-transparent via-65% to-transparent absolute z-[2] text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={classNames(
          error ? 'border-red-500' : '',
          fields.length > 0 || item ? 'border p-3 rounded border-gray-300 bg-white' : '',
          'grid grid-cols-12',
        )}
      >
        {fields.map((field, index) => {
          return <Fragment key={field.id}>{renderItem(field, index, remove, append)}</Fragment>;
        })}
        {item ? (
          <Button
            variant="inverse"
            type="button"
            className="col-span-12 mt-3"
            onClick={async () => {
              let itemToAppend = { ...item };
              if (onAppend) {
                itemToAppend = await onAppend(itemToAppend);
              }
              append(itemToAppend);
            }}
          >
            <HiPlusCircle className="w-6 h-6 mr-4" />
            {addButtonLabel ? addButtonLabel : 'Add item'}
          </Button>
        ) : null}
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}

      {description && <p className="ml-2 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
