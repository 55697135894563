import { Link } from 'react-router-dom';
import { BreadcrumbItem, useBreadcrumbs } from '../../../hooks/breadcrumbs';
import './style.css';
import classNames from '../../../utils/classNames';

export function Breadcrumbs() {
  const { items } = useBreadcrumbs();

  return (
    <div className="relative border-gray-300 rounded flex flex-shrink font-bold bg-white shadow">
      {items.map((i: BreadcrumbItem) => (
        <div key={i.label} className="breadcrumb-space">
          {i.path ? (
            <Link to={i.path} className="breadcrumb-item p-2 text-gray-600 hover:underline">
              {i.label}
            </Link>
          ) : (
            <span
              onClick={i.onClick}
              className={classNames(
                typeof i.onClick === 'function' ? 'cursor-pointer hover:underline' : '',
                'text-gray-600 breadcrumb-item p-2',
              )}
            >
              {i.label}
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

export default Breadcrumbs;
