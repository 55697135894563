export const groupBy = <T, K extends keyof any | undefined>(list: T[], getKey: (item: T) => K) => {
  return list.reduce(
    (previous, currentItem) => {
      let group = getKey(currentItem);
      if (!group) group = undefined;
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>,
  );
};
