import { useNavigate } from 'react-router-dom';
import { ProjectDto, usePostProjectMutation } from '../../../api/project/projectApi';
import { ProjectForm } from './ProjectForm';
import toast from 'react-hot-toast/headless';

function CreateProject() {
  const history = useNavigate();
  const [postProject] = usePostProjectMutation();

  const onSubmit = async (project: ProjectDto) => {
    const newProject: any = await postProject({ projectDto: project }).unwrap();

    if (newProject.error) {
      // console.log(newProject.error);
      toast.error('Failed to create project');
    } else {
      history(`../${newProject.id}`);
      toast.success('Project created successfully');
    }
  };

  return <ProjectForm className={'mt-3'} onSubmit={onSubmit} formMode={'CREATE'} />;
}

export default CreateProject;
