import { array, object, string } from 'yup';

export const assessmentConfigurationsValidation = array()
  .of(
    object().shape({
      assessmentType: string().required('ModeledProcess is required'),
      impactMethodId: string().required('ModeledProcess is required'),
      phaseConfigurations: array()
        .of(
          object().shape({
            treeId: string().required('ModeledProcess is required'),
            phaseId: string().required('Phase is required'),
          }),
        )
        .min(1, 'A phase is required')
        .required(),
    }),
  )
  .min(1, 'An assessment type is required')
  .required();
