import axios from 'axios';
import { USER_REALM_MAPPER_API_URL } from '../../config';
import { logout } from './logoutAction';
import storage from '../../utils/storage';

export async function refreshToken(username: string, realm: string) {
  try {
    const axiosInstance = axios.create({
      // Your Axios instance configuration here
      baseURL: USER_REALM_MAPPER_API_URL,
      // Other default options...
      withCredentials: true, // Enable sending credentials with requests
    });

    // console.log("RefreshCalled called")
    await axiosInstance
      .post('/refresh-token', {
        // Include the necessary data for token refresh
        username: username,
        realm: realm,
      })
      .then((response) => {
        // console.log(response);
        storage.setToken(response.data.access_token);
      });
    return true;
  } catch (error) {
    const queryParams = new URLSearchParams(window.location.search);
    logout(queryParams.has('redirectUrl') ? (queryParams.get('redirectUrl') as string) : window.location.pathname).then(
      () => {
        // console.log('logout');
      },
    );
    return false;
  }
}
