import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Integrations } from './Integrations';
import { StateMachineProvider } from 'little-state-machine';
import Integration from '../components/Integration';
import CreateIntegration from '../components/CreateIntegration';

export const IntegrationsRoutes = () => {
  return (
    <StateMachineProvider>
      <Routes>
        <Route path="" element={<Integrations />} />
        <Route path=":integrationTemplateId" element={<Integration />} />
        <Route path="/edit/:integrationTemplateId" element={<Integration />} />
        <Route path="/new" element={<CreateIntegration />} />
      </Routes>
    </StateMachineProvider>
  );
};
