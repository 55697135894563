import { useFormContext } from 'react-hook-form';
import React from 'react';
import { SelectControl } from '../../../../components/input';
import { useGetAllRemoteFlowsQuery } from '../../../../api/remoteDispatcher/remoteDispatcherApi';

type NumericFormProps = {
  prefix: string;
};

function RemoteForm(props: NumericFormProps) {
  const { prefix } = props;

  const { data: flows } = useGetAllRemoteFlowsQuery({});
  const { control, register, watch } = useFormContext();

  return (
    <div className="w-full">
      <SelectControl
        control={control}
        name={`${prefix}.flowId`}
        label="Remote flow"
        className="flex-1"
        options={flows?.map((p) => ({
          value: p.flowId,
          label: p.flowName,
        }))}
      />
    </div>
  );
}

export default RemoteForm;
