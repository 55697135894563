import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Elements';
import TabManagement from './TabManagement';
import { HiArrowDownOnSquare, HiDocumentDuplicate, HiOutlineEye, HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { InputText } from '../../../../components/input';
import { toast } from 'react-hot-toast/headless';
import {
  Commit,
  usePostCommitMutation,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { scenarioTemplateSteps } from './ScenarioTemplate';
import ArrayFieldPositionMover from '../ArrayFieldPositionMover';
import { yupResolver } from '@hookform/resolvers/yup';
import { basicScenarioTemplateSchema } from '../validation/basicScenarioTemplateSchema';
import GuidedNavigation from '../../../../components/navigation/GuidedNavigation';
import ButSaveModal from '../ButSaveModal';
import ButSlidePanel from '../../../../components/SlidePanel/ButSlidePanel';
import { FiGitCommit } from 'react-icons/fi';
import CommitList from '../CommitList';
import { Tooltip } from 'react-tooltip';

function ScenarioTemplateDisplay() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const [postCommit] = usePostCommitMutation();

  const [saveWithErrorModal, setSaveWithErrorModal] = useState(false);

  useEffect(() => {
    if (!state.template) {
      navigate('/app/templates/basic');
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    resolver: (values, context, options) => {
      return yupResolver(basicScenarioTemplateSchema)(
        values,
        {
          ...values,
        },
        options,
      );
    },
    mode: 'onChange',
  });
  const { register, setValue, control, handleSubmit, watch, getValues, trigger } = methods;
  const {
    fields: groupFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'groups',
  });

  const { fields: displayConfigurationFields, move: moveDisplayConfigurationField } = useFieldArray({
    control,
    name: `displayConfigurations`,
  });

  const displayConfigurations: any[] = watch('displayConfigurations');

  const handleNavigate = (href: string) => {
    const template = getValues();
    template.displayConfigurations = template.displayConfigurations.map((pc: { tabs: any[] }) => {
      return {
        ...pc,
        tabs: pc.tabs.map((t, index) => ({ ...t, displayOrder: index })),
      };
    });

    actions.initScenarioTemplateAction({ template });
    navigate(href);
  };

  const handleFormSubmit = async (values: any, valid: boolean, commit: Commit) => {
    console.log('Template', { values });
    values.valid = valid;
    const scenarioTemplate: any = await postScenarioTemplate({ body: values });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      postCommit({
        commit: {
          ...commit,
          message: `${commit?.message}${!valid ? ' [saved with error]' : ''}`,
          scenarioTemplateId: scenarioTemplate.data.id,
        } as Commit,
      });
      if (!values.id) {
        setValue('id', scenarioTemplate.id);
      }
      toast.success(`Scenario template created successfully${valid ? '' : ' with errors'}`);
    }
    setSaveWithErrorModal(false);
  };

  const handleSaveWithError = async (errors: any) => {
    setSaveWithErrorModal(true);
    console.warn(errors);
  };

  const handleTrigger = async () => {
    const result = await trigger();
    if (result) {
      toast.success('Scenario template is valid');
    } else {
      toast.error('Scenario template contains some errors');
    }
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleNavigate(href)}
        className="mt-2"
      />
      <div className="">
        <form className="flex flex-col bg-white mt-2 shadow rounded">
          <div className="mt-2 flex ml-auto">
            <Tooltip variant={'info'} content="Save" anchorSelect={'#save'} className="float-right mr-2" />
            <ButSaveModal
              buttonLabel={
                <HiArrowDownOnSquare id={'save'} className="focus:outline-none w-8 h-8 cursor-pointer mr-2" />
              }
              onFormSubmit={handleFormSubmit}
              scenarioTemplateId={state.template?.id}
            />
            <Tooltip variant={'info'} content="Copy" anchorSelect={'#copy'} className="float-right mr-2 " />
            <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
              <HiDocumentDuplicate id={'copy'} className="focus:outline-none w-8 h-8 cursor-pointer mr-2" />
            </CopyToClipboard>
            <Tooltip variant={'info'} anchorSelect={'#info'} content="Commits" />
            {state.template?.id && (
              <ButSlidePanel
                id={'info'}
                buttonSize={'none'}
                buttonLabel={<FiGitCommit className={'h-8 w-8 cursor-pointer mr-2'} />}
                label={'Changes on the scenario template'}
              >
                <CommitList scenarioTemplateId={state.template?.id} />
              </ButSlidePanel>
            )}
            <Tooltip variant={'info'} anchorSelect={'#validate'} content="Validate" />
            <HiOutlineEye
              className="h-8 w-8 cursor-pointer focus:outline-none mr-2"
              id={'validate'}
              onClick={() => handleTrigger()}
            />
          </div>
          <div className="flex gap-4 flex-col">
            <h3 className="flex-1 text-lg my-4 text-center">Phases order management</h3>
            <hr className="bg-gray-300 h-[1px]" />
            <div className="flex-1 flex flex-col">
              {displayConfigurationFields.map((field, index) => {
                return (
                  <div key={field.id} className="flex-1 flex flex-col border-2 border-gray-300 m-3 py-3 rounded">
                    <div className="flex mx-3">
                      <InputText
                        className="flex-1"
                        {...register(`displayConfigurations.${index}.phaseName`)}
                        label={'Phase name'}
                      />
                      <ArrayFieldPositionMover
                        max={displayConfigurations.length}
                        move={moveDisplayConfigurationField}
                        index={index}
                      />
                    </div>
                    <div className="bg-white rounded p-3 my-2">
                      <TabManagement prefix={`displayConfigurations.${index}`} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex-1">
            <h3 className={`text-lg mt-4 text-center`}>Groups</h3>
            <div className="bg-white rounded p-2 m-2 mt-8 border-2 grid grid-cols-12 gap-3">
              {groupFields.map((groupField: any, index: number) => {
                return (
                  <div key={groupField.id} className="col-span-2 py-1 my-1 flex flex-row  justify-around">
                    <InputText
                      {...register(`groups.${index}.name`)}
                      addonAfter={
                        <HiOutlineTrash className="h-6 w-6 cursor-pointer text-red-500" onClick={() => remove(index)} />
                      }
                      label={'Group name'}
                    />
                  </div>
                );
              })}
              <Button
                type="button"
                variant={'inverse'}
                className={`col-span-2 flex border-2 my-2`}
                onClick={() => append({ name: '' })}
              >
                <div className="flex items-center">
                  <HiPlusCircle className="h-6 w-6 mr-4" />
                  Add Group
                </div>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

export default ScenarioTemplateDisplay;
