import React, { FC, Fragment, PropsWithChildren } from 'react';
import { HiXMark } from 'react-icons/hi2';
import { Dialog, Transition } from '@headlessui/react';

type ConfirmationPopupProps = PropsWithChildren<{
  onConfirm: () => void;
  onCancel: () => void;
}>;

const ConfirmationPopup: FC<ConfirmationPopupProps> = ({ onConfirm, onCancel, children }: ConfirmationPopupProps) => {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="grid grid-cols-12 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className={`col-span-6 col-start-4 max-h-[80vh] rounded bg-white px-4 pb-4 pt-5 text-left shadow sm:p-6 text-xl text-gray-700 mb-4 mt-1 px-4 text-center`}
              >
                {children}
                <div className="mt-5 sm:mt-6 flex">
                  <button
                    type="button"
                    className="bg-red-500 text-white p-2 m-2 rounded hover:bg-blue-600 hover:text-white flex-1"
                    onClick={() => onConfirm()}
                  >
                    Yes
                  </button>

                  <button
                    type="button"
                    className="bg-white text-blue-500 border-2 border-blue-500 p-2 m-2 rounded hover:bg-blue-600 hover:text-white flex-1"
                    onClick={() => onCancel()}
                  >
                    No
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationPopup;
