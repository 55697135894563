import { HiArrowPath } from 'react-icons/hi2';
import classNames from '../../../utils/classNames';

type SpinnerProps = {
  size?: string;
  style?: any;
  className?: string;
  color?: 'dark-gray' | 'white' | 'light-gray';
};

const iconColors = {
  ['dark-gray']: 'text-gray-500',
  white: 'text-white',
  ['light-gray']: 'text-gray-200',
};

export const Spinner = (props: SpinnerProps) => {
  const { size, style, className, color } = props;

  const iconColor = iconColors[color ? color : 'dark-gray'];

  return (
    <div className={className} style={style}>
      <HiArrowPath className={classNames(size ? size : 'h-6 w-6', iconColor, 'animate-spin')} />
    </div>
  );
};
