import React, { Fragment, useState } from 'react';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';
import { HiOutlineEyeDropper } from 'react-icons/hi2';
import { ChromePicker, SketchPicker } from 'react-color';
import { Dialog, Transition } from '@headlessui/react';

export type ColorPickerProps = {
  className: string;
  error?: string;
  value: string | number | undefined;
  placeHolder?: string;
  onChange?: (value: any) => void;
  label?: string;
  description?: string;
  id?: string;
  name?: string;
};

export const ColorPicker = React.forwardRef<ChromePicker, ColorPickerProps>((props, ref) => {
  const { className, value, name, placeHolder, onChange, label, error, description, id } = props;

  const renderCounter = useRenderCounter();
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(value);

  const handleColorChange = () => {
    onChange?.(color);
    setOpen(false);
  };

  const handleOnClose = () => {
    setColor(undefined);
    setOpen(false);
  };
  const renderColorPicker = (children) => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={handleOnClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto" style={{ zoom: 'calc(1/var(--zoom))' }}>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded bg-white px-4 pb-4 pt-5 text-left shadow transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  {children}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => handleColorChange()}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className={classNames(className)}>
      {renderCounter}
      <label className="block text-sm font-medium focus:border-none text-gray-700">{label}</label>
      <div
        onClick={() => setOpen(true)}
        style={{ backgroundColor: color as string }}
        className={`flex rounded border-2 cursor-pointer focus-visible:outline-none focus:border-blue-500 ${
          error ? 'border-red-500' : ''
        }`}
      >
        <HiOutlineEyeDropper className={`h-6 w-6 m-2  ${color ? 'text-white' : 'text-black'}`} />
        <span
          className={`inline-flex items-center rounded-l border-gray-300 px-3 text-md ${
            color ? 'text-white' : 'text-black'
          }`}
        >
          {placeHolder ? placeHolder : 'Pick a color'}
        </span>
      </div>

      {renderColorPicker(
        <SketchPicker
          id={id}
          name={name}
          disableAlpha={true}
          className={'contents'}
          ref={ref}
          color={color as string}
          onChange={(color) => setColor(color.hex)}
        />,
      )}

      {error && <p className="text-sm text-red-500">{error}</p>}

      {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  );
});
