// import { MouseEvent, ReactNode, useState } from 'react';
// import classNames from '../../utils/classNames';
// import Tabs, { useTabsContext } from '../Tabs/Tabs';
// import { MapItems } from '../map/Map';
//
// export type NavigationStep = {
//   id: string;
//   name: string;
// };
//
// type GuidedNavigationProps = {
//   steps: NavigationStep[];
//   className?: string;
//   children: ReactNode;
// };
//
// type NavigationStepProps = NavigationStep & {
//   index: number;
//   last: boolean;
//   onClick: (event: MouseEvent<HTMLLIElement>) => void;
// };
//
// const NavigationStep = (props: NavigationStepProps) => {
//   const { id, name, last, index, onClick } = props;
//   const { activeTabId } = useTabsContext();
//
//   // console.log({ activeTabId, last });
//
//   return (
//     <li key={name} onClick={onClick} className="relative md:flex md:flex-1">
//       <div aria-current="step" className="cursor-pointer">
//         <span
//           className={classNames(
//             activeTabId === index ? 'bg-blue-600 -ml-[18px]' : '',
//             'absolute left-0 top-0 h-full w-1 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full',
//           )}
//           aria-hidden="true"
//         />
//         <span className={classNames(index !== 0 ? 'lg:pl-9' : '', 'flex px-6 py-5 text-sm font-medium items-center')}>
//           <span className="flex-shrink-0">
//             <span className="flex h-10 w-10 items-center justify-center rounded-full border border-blue-600">
//               <span className="text-blue-600">{id}</span>
//             </span>
//           </span>
//           <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
//             <span className="text-sm font-medium text-blue-600">{name}</span>
//           </span>
//         </span>
//       </div>
//       {!last ? (
//         <>
//           {/* Arrow separator for lg screens and up */}
//           <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
//             <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
//               <path
//                 d="M0 -2L20 40L0 82"
//                 vectorEffect="non-scaling-stroke"
//                 stroke="currentcolor"
//                 strokeLinejoin="round"
//               />
//             </svg>
//           </div>
//         </>
//       ) : null}
//     </li>
//   );
// };
//
// function GuidedNavigation(props: GuidedNavigationProps) {
//   const { steps, children, className } = props;
//   const [internalSteps, setInternalSteps] = useState<NavigationStep[]>(steps);
//
//   // console.log({ activeTabId });
//
//   return (
//     <Tabs>
//       <nav aria-label="Progress" className={classNames(className ? className : '', 'bg-white')}>
//         <ol role="list" className="divide-y divide-gray-300 rounded border border-gray-300 md:flex md:divide-y-0">
//           <MapItems items={internalSteps}>
//             {(step, index, steps) => (
//               <Tabs.Tab index={index} condition={step.condition}>
//                 <GuidedNavigation.Step index={index} {...step} last={index === steps.length - 1} />
//               </Tabs.Tab>
//             )}
//           </MapItems>
//         </ol>
//       </nav>
//       {children}
//     </Tabs>
//   );
// }
//
// GuidedNavigation.Step = NavigationStep;
// export default GuidedNavigation;
import { useState } from 'react';
import classNames from '../../utils/classNames';

export type NavigationStep = {
  id: string;
  name: string;
  href: string;
};

type GuidedNavigationProps = {
  steps: NavigationStep[];
  className?: string;
  callback: (href?: string) => void;
};

export default function GuidedNavigation(props: GuidedNavigationProps) {
  const { steps, callback, className } = props;
  const [internalSteps, setInternalSteps] = useState<NavigationStep[]>(steps);

  return (
    <nav aria-label="Progress" className={classNames(className ? className : '', 'bg-white')}>
      <ol role="list" className="divide-y divide-gray-300 rounded border border-gray-300 md:flex md:divide-y-0">
        {internalSteps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1 cursor-pointer" onClick={() => callback(step.href)}>
            <div aria-current="step">
              <span
                className={classNames(
                  stepIdx !== 0 ? 'lg:pl-9' : '',
                  'flex px-6 py-5 text-sm font-medium items-center ',
                )}
              >
                <span className="flex-shrink-0">
                  <span className="flex h-10 w-10 items-center justify-center rounded-full border border-blue-600">
                    <span className="text-blue-600">{step.id}</span>
                  </span>
                </span>
                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span className="text-sm font-medium text-blue-600">{step.name}</span>
                </span>
              </span>
            </div>

            {stepIdx !== internalSteps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
