import { useMemo } from 'react';
import * as Yup from 'yup';
import { ProjectDto, Tag } from '../../../api/project/projectApi';
import { ScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Form } from '../../../components/Form';
import { FormProps } from '../../../components/types';

type ProjectList = {
  projects?: ProjectDto[];
  scenarioTemplates?: ScenarioTemplate[];
  tags?: Tag[];
  className?: string;
};

type ScenarioFormProps = FormProps & ProjectList;

export const ScenarioForm = (props: ScenarioFormProps) => {
  const { onSubmit, onError, initialValues, submitLabel, projects, scenarioTemplates, tags, className } = props;

  const mappedProjects: { value: string; label: string }[] = useMemo(
    () =>
      projects?.map((p) => ({
        value: p.id as string,
        label: p.name as string,
      })) || [],
    [projects],
  );

  const mappedScenarioTemplates: { value: string; label: string }[] = useMemo(
    () =>
      scenarioTemplates?.map((p) => ({
        value: p.id as string,
        label: p.name as string,
      })) || [],
    [scenarioTemplates],
  );

  const mappedTags: { value: string; label: string }[] = useMemo(
    () => tags?.map((p) => ({ value: p.id as string, label: p.name as string })) || [],
    [tags],
  );

  const scenarioValidation = Yup.object().shape({
    name: Yup.string().required('Scenario name is required').nullable(),
    description: Yup.string().required('Scenario description is required').nullable(),
    scenarioTemplateId: Yup.string().required('Scenario template is required').nullable(),
    projectId: Yup.string().required('Project is required').nullable(),
  });

  const handleOnSubmit = (data: any) => {
    // console.log("data:::::",{ data })
    onSubmit(data);
  };

  return (
    <Form
      className={className}
      onSubmit={handleOnSubmit}
      onError={onError}
      validationSchema={scenarioValidation}
      submitLabel={submitLabel || 'Create'}
      initialValues={initialValues}
      editing={true}
    >
      <Form.Item name="name" type="InputText" label="Scenario Name" />

      <Form.Item name="id" type="InputText" hidden={true} />

      <Form.Item name="projectId" type="Select" label="Project" options={mappedProjects} />

      <Form.Item name="scenarioTemplateId" type="Select" label="Scenario Template" options={mappedScenarioTemplates} />

      <Form.Item name="tags" type="MultipleSelect" label="Tags" options={mappedTags} />

      <Form.Item name="description" type="InputText" label="Scenario Description" />
    </Form>
  );
};
