import { HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Button } from '../../../../components/Elements';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import BasicParameterForm from '../Parameter/BasicParameterForm';
import { DisplayConfiguration } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import ArrayFieldPositionMover from '../ArrayFieldPositionMover';
import { IndexedParameter } from '../../../customization-process/components/CustomizationTemplateForm';
import classNames from '../../../../utils/classNames';
import { UseFieldArrayReturn } from 'react-hook-form/dist/types/fieldArray';
import { getInputErrors } from '../../../../lib/getInputError';
import { useFormContext } from 'react-hook-form';
import { HiRefresh } from 'react-icons/hi';
import VisibleAccordion from '../../../../components/Accordion/VisibleAccordion';
import { Tooltip } from 'react-tooltip';
import { SelectControl } from '../../../../components/input';
import FlowSwitchAdditionalFields from '../Parameter/FlowSwitchAdditionalFields';
import DisplayManagement from './DisplayManagement';

type ParameterSectionProps = UseFieldArrayReturn & {
  type: 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER';
  name: string;
  parameters?: IndexedParameter[];
  item: any;
  displayConfigurations: DisplayConfiguration[];
};

function ParameterSection(props: ParameterSectionProps) {
  const {
    type: filterType,
    fields,
    move,
    remove,
    append,
    parameters: itemParameters,
    item,
    displayConfigurations,
    name,
  } = props;

  const [filterErrors, setFilterErrors] = useState(true);
  const [refreshErrors, setRefreshErrors] = useState(false);
  const [memoErrors, setMemoErrors] = useState<any>();
  const {
    formState: { errors },
  } = useFormContext();

  const fieldParameters = useMemo(() => {
    return fields
      ? fields
          ?.map((p, index) => ({ ...p, index }))
          ?.filter(
            ({ type, index }) =>
              (type === filterType && !itemParameters) ||
              (type === filterType && itemParameters?.map((ip) => ip.index).includes(index)),
          )
      : [];
  }, [itemParameters, fields]);

  useEffect(() => {
    // console.log('Refreshing memo:', typeof errors?.parameters?.length, errors?.parameters?.length ?? 0, ' > ',typeof memoErrors?.parameters?.length ,memoErrors?.parameters?.length ?? 0, ' = ', (errors?.parameters?.length ?? 0) > (memoErrors?.parameters?.length ?? 0));
    if (refreshErrors || (errors?.parameters?.length ?? 0) > (memoErrors?.parameters?.length ?? 0)) {
      setMemoErrors({ parameters: [...(errors?.parameters ?? [])] });
      setRefreshErrors(false);
    }
  }, [refreshErrors, filterErrors, errors]);

  return (
    <div className="m-4 rounded border-2 rounded border-gray-500">
      <div className={`text-lg py-2 justify-center flex bg-gray-200`}>
        <h3>{name} </h3>
        <Tooltip
          anchorSelect={`#${filterType}-refreshValidation`}
          content={`Refresh ${filterType} validation`}
          className={'z-30'}
          variant={'info'}
        />
        <HiRefresh
          id={`${filterType}-refreshValidation`}
          className="ml-4 h-8 w-8 text-blue-500 focus:outline-none cursor-pointer"
          onClick={() => setRefreshErrors(true)}
        />
      </div>
      <hr className="bg-gray-300 h-[1px]" />

      <div className="grid grid-cols-12 p-2 px-4">
        {fieldParameters
          .filter((param) => !!getInputErrors(memoErrors, `parameters.${param.index}`))
          .map((parameterField: any) => {
            const { index, color } = parameterField;
            return (
              <div key={parameterField.id} className={classNames('col-span-6 p-1 m-1 flex flex-row justify-around')}>
                <BasicParameterForm
                  {...parameterField}
                  defaultValues={{}}
                  displayConfigurations={displayConfigurations}
                  prefix={`parameters.${index}`}
                  color={color}
                  additionalInputs={(props, control) => {
                    const { prefix, display, displayConfigurations, type } = props;

                    return (
                      <Fragment>
                        <SelectControl
                          control={control}
                          name={`${prefix}.type`}
                          className="my-3"
                          label={'Type'}
                          options={['AMOUNT_CHANGE', 'FLOW_SWITCH', 'ADDITIONAL_PARAMETER'].map((o) => ({
                            label: o,
                            value: o,
                          }))}
                        />

                        <SelectControl
                          control={control}
                          name={`${prefix}.parameterType`}
                          className="my-3"
                          disabled={type === 'FLOW_SWITCH'}
                          label={'Parameter type'}
                          options={['FORMULA', 'OPTION', 'NUMERIC', 'STRING', 'REMOTE', 'LIST'].map((o) => ({
                            label: o,
                            value: o,
                          }))}
                        />
                        {type === 'FLOW_SWITCH' && <FlowSwitchAdditionalFields prefix={prefix} />}
                        <div className="p-4 border border-gray-300 mt-3 rounded">
                          <DisplayManagement
                            display={display}
                            parameterPrefix={`${prefix}.display`}
                            displayConfigurations={displayConfigurations}
                          />
                        </div>
                      </Fragment>
                    );
                  }}
                />
                <ArrayFieldPositionMover max={fields.length} index={index} move={move} />
                <RiDeleteBinLine className="h-full w-6 text-red-500" onClick={() => remove(index)} />
              </div>
            );
          })}
      </div>
      <VisibleAccordion className={''} height={'md'} onClick={() => setRefreshErrors(!refreshErrors)}>
        <div className="grid grid-cols-12 p-2 relative">
          {fieldParameters
            .filter((param) => !getInputErrors(memoErrors, `parameters.${param.index}`))
            .map((parameterField: any) => {
              const { index, color } = parameterField;
              return (
                <div key={parameterField.id} className={classNames('col-span-6 p-1 m-1 flex flex-row justify-around')}>
                  <BasicParameterForm
                    {...parameterField}
                    defaultValues={{}}
                    displayConfigurations={displayConfigurations}
                    prefix={`parameters.${index}`}
                    color={color}
                    additionalInputs={(props, control) => {
                      const { prefix, display, displayConfigurations, type } = props;

                      return (
                        <Fragment>
                          <SelectControl
                            control={control}
                            name={`${prefix}.type`}
                            className="my-3"
                            label={'Type'}
                            options={['AMOUNT_CHANGE', 'FLOW_SWITCH', 'ADDITIONAL_PARAMETER'].map((o) => ({
                              label: o,
                              value: o,
                            }))}
                          />

                          <SelectControl
                            control={control}
                            name={`${prefix}.parameterType`}
                            className="my-3"
                            disabled={type === 'FLOW_SWITCH'}
                            label={'Parameter type'}
                            options={['FORMULA', 'OPTION', 'NUMERIC', 'STRING', 'REMOTE', 'LIST'].map((o) => ({
                              label: o,
                              value: o,
                            }))}
                          />
                          {type === 'FLOW_SWITCH' && <FlowSwitchAdditionalFields prefix={prefix} />}
                          <div className="p-4 border border-gray-300 mt-3 rounded">
                            <DisplayManagement
                              display={display}
                              parameterPrefix={`${prefix}.display`}
                              displayConfigurations={displayConfigurations}
                            />
                          </div>
                        </Fragment>
                      );
                    }}
                  />
                  <ArrayFieldPositionMover max={fields.length} index={index} move={move} />
                  <RiDeleteBinLine className="h-full w-6 text-red-500" onClick={() => remove(index)} />
                </div>
              );
            })}
          <Button
            type="button"
            variant={'none'}
            className={`col-span-6 my-2 mx-2 px-2 flex text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white`}
            onClick={() => append(item)}
          >
            <div className="flex items-center">
              <HiPlusCircle className="h-8 w-8 mr-4" />
              Add Parameter
            </div>
          </Button>
        </div>
      </VisibleAccordion>
    </div>
  );
}

export default ParameterSection;
