import { customizedScenarioApi as api } from './customizedScenarioApi';

export const enhancedCustomizedScenarioApi = api.enhanceEndpoints({
  addTagTypes: ['CustomizedProcess'],
  endpoints: {
    getCustomizedProcess: {
      providesTags: (result, error, arg) => [{ type: 'CustomizedProcess', id: arg.scenarioId }],
    },
    upsertCustomizedProcess: {
      invalidatesTags: (result, error, arg) => [
        {
          type: 'CustomizedProcess',
          id: arg.customizedProcess.refId as string,
        },
      ],
    },
  },
});
