import { useFormContext } from 'react-hook-form';
import { Commit, ScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Fragment, ReactNode, useState } from 'react';
import { Button } from '../../../components/Elements';
import { Tooltip } from 'react-tooltip';
import CreateCommit from './CreateCommit';
import { MapItems } from '../../../components/map-items/MapItems';
import Modal from '../../../components/Modal/Modal';
import { HiArrowDownOnSquare } from 'react-icons/hi2';
import { useGetAllScenarioByTemplateIdQuery } from '../../../api/project/projectApi';
import { skipToken } from '@reduxjs/toolkit/query';
import classNames from '../../../utils/classNames';

type ButSaveModalProps = {
  scenarioTemplateId: string | undefined;
  variant?: 'inverse' | 'primary' | 'none' | 'danger';
  size?: 'sm' | 'none' | 'md';
  buttonLabel: string | ReactNode;
  className?: string;
  onFormSubmit: (values: ScenarioTemplate, valid: boolean, commit: Commit) => void;
};

function ButSaveModal(props: ButSaveModalProps) {
  const { scenarioTemplateId, buttonLabel, size, className, variant, onFormSubmit } = props;

  const {
    trigger,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [commit, setCommit] = useState<Commit | undefined>();

  const { data: scenarios } = useGetAllScenarioByTemplateIdQuery(
    scenarioTemplateId ? { scenarioTemplateId } : skipToken,
  );

  const handleSave = (valid: boolean) => {
    onFormSubmit(getValues(), valid, commit as Commit);
    setOpenSaveModal(false);
  };

  return (
    <Fragment>
      <Button
        className={className}
        size={size ? size : 'none'}
        variant={variant ? variant : 'none'}
        onClick={() => {
          trigger();
          setOpenSaveModal(true);
        }}
      >
        {buttonLabel}
      </Button>

      <Modal open={openSaveModal} handleOpen={setOpenSaveModal} cancel={() => setCommit(undefined)}>
        {scenarios && scenarios.length > 0 && (
          <div className={'border border-blue-400 p-2 rounded text-center'}>
            <h2 className={'text-blue-600 font-bold text-md'}>Existing scenarios for this scenario template</h2>
            <div className={'grid grid-cols-3 gap-3 mt-3'}>
              <MapItems items={scenarios ?? []}>
                {(scenario: ScenarioTemplate) => (
                  <div className={'col-span-1 shadow bg-gray-100 rounded p-3 text-blue-600 text-lg'}>
                    <div className={'text-center'}>{scenario.name}</div>
                  </div>
                )}
              </MapItems>
            </div>
          </div>
        )}
        <CreateCommit onChange={setCommit} className={'mt-3'} />

        {!commit && <Tooltip anchorSelect={'#save'} content={'Fill up commit to save'} />}
        {Object.keys(errors).length > 0 ? (
          <div className={'mt-3 text-center flex flex-col'} id={'save'}>
            <h2 className={classNames(!commit ? 'text-gray-600' : 'text-red-600', 'font-bold text-lg text-center')}>
              Scenario template contains some errors, do you want to save it?{' '}
            </h2>
            <Button variant={'danger-inverse'} className={'mt-3'} disabled={!commit} onClick={() => handleSave(false)}>
              Save with errors
            </Button>
          </div>
        ) : (
          <Button
            variant={'inverse'}
            className={'mt-3 float-right'}
            disabled={!commit}
            onClick={() => handleSave(true)}
          >
            Save
          </Button>
        )}
      </Modal>
    </Fragment>
  );
}

export default ButSaveModal;
