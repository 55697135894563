import React from 'react';
import { InputText, SelectControl } from '../../../../components/input';
import { HiLink, HiQuestionMarkCircle, HiXCircle } from 'react-icons/hi2';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Parameter } from '../../../../api/customizationSpace/customizationSpaceApi';
import { getInputError } from '../../../../lib/getInputError';

type VariableConfigurationProps = {
  prefix: string;
  parameters: Parameter[];
  className?: string;
};

function VariableConfiguration(props: VariableConfigurationProps) {
  const { prefix, parameters, className } = props;

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const { append: appendParameter } = useFieldArray({
    control,
    name: 'parameters',
  });

  const variableConfiguration = watch(prefix);

  // console.log({ prefix }, { variableConfiguration });

  return (
    <div className={'col-span-1 grid grid-cols-12 gap-2 justify-around items-center m-2'}>
      <div className="col-span-1">
        {!variableConfiguration.parameterName ? (
          <HiXCircle className="h-6 w-6 text-red-600" />
        ) : variableConfiguration.parameterName === 'UNLINKED' ? (
          <HiQuestionMarkCircle className="h-6 w-6 text-orange-300" />
        ) : (
          <HiLink className="h-6 w-6 text-green-600" />
        )}
      </div>
      <InputText
        disabled={true}
        className="col-span-5"
        label={'Variable name'}
        {...register(`${prefix}.name`)}
        addonAfter={<div className="flex-shrink">{variableConfiguration.value}</div>}
      />
      <SelectControl
        control={control}
        className="col-span-6"
        label={'Linked parameter'}
        name={`${prefix}.parameterName`}
        options={[
          {
            value: 'UNLINKED',
            label: 'Unlinked',
          },
          ...parameters
            ?.filter((p) => p.type === 'AMOUNT_CHANGE')
            .map((p) => ({
              value: p.parameterName,
              label: p.parameterName,
            })),
        ]}
        error={getInputError(errors, `${prefix}.parameterName`)}
      />
      {/*Below to add autocreation of parameter but requires some thought*/}
      {/*{!variableConfiguration.parameterName && <HiOutlinePlusCircle className="h-6 w-6 text-green-600 col-span-1" onClick={() => appendParameter({*/}
      {/*  parameterName: variableConfiguration.name,*/}
      {/*  type: 'AMOUNT_CHANGE',*/}
      {/*  parameterType: 'NUMERIC',*/}
      {/*  defaultValue: variableConfiguration.value*/}
      {/*})} />}*/}
    </div>
  );
}

export default VariableConfiguration;
