import { HiOutlineTrash } from 'react-icons/hi2';
import { useFormContext } from 'react-hook-form';
import { InputText, SelectControl } from '../../../../components/input';
import React, { Fragment } from 'react';
import { InputList } from '../../../../components/input/InputList';
import BasicParameterForm from './BasicParameterForm';

type ListFormProps = {
  prefix: string;
  requiresDefault: boolean;
};

function ListForm(props: ListFormProps) {
  const { prefix, requiresDefault } = props;

  const { control, register } = useFormContext();

  return (
    <div className="w-full mt-3">
      <InputList
        label={'Item format'}
        control={control}
        name={`${prefix}.listItem`}
        item={{ parameterName: '', parameterType: 'STRING' }}
        renderItem={(field, index, remove, append) => {
          // console.log(field)
          return (
            <div className="col-span-12 mx-1 my-2 flex">
              <BasicParameterForm
                {...field}
                defaultValues={{}}
                prefix={`${prefix}.listItem.${index}`}
                slideSize={'md'}
                additionalInputs={(props, control) => {
                  const { prefix, display, displayConfigurations, type } = props;

                  return (
                    <SelectControl
                      control={control}
                      name={`${prefix}.parameterType`}
                      className="my-3"
                      disabled={type === 'FLOW_SWITCH'}
                      label={'Parameter type'}
                      options={['OPTION', 'NUMERIC', 'STRING'].map((o) => ({
                        label: o,
                        value: o,
                      }))}
                    />
                  );
                }}
              />
              <HiOutlineTrash
                className="h-8 w-8 text-red-500 self-center mx-2 cursor-pointer"
                onClick={() => remove(index)}
              />
            </div>
          );
        }}
      />
    </div>
  );
}

export default ListForm;
