import { array, object, string } from 'yup';
import { SkipTests } from './skipTest';

export const displayConfigurationsValidation = array().of(
  object().shape({
    phaseName: string().required('Phase name is required'),
    tabs: array().of(
      object().shape({
        name: string().when(['$skipTests'], ([skipTests], schema, options) => {
          if (skipTests && skipTests.includes(SkipTests.DISPLAY_TEST)) return schema.nullable();
          return schema.required(`Tab  name is required`);
        }),
      }),
    ),
  }),
);
