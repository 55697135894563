import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../../../Error';

import { Login } from './Login';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
