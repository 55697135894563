import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import React, { useEffect, useState } from 'react';
import { Tag, useGetAllScenarioQuery } from '../../../api/project/projectApi';
import { Button, Link } from '../../../components/Elements';
import { InputText } from '../../../components/input';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListScenario from '../components/ListScenario';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';
import { LiaCubeSolid, LiaCubesSolid } from 'react-icons/lia';
import { Tooltip } from 'react-tooltip';
import { AssessmentConfiguration, ScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import ButModal from '../../../components/Modal/ButModal';

export const Scenarios = () => {
  const [search, setSearch] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'scenarios' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {/*<Link to="./new/">
          <Button className={'ml-3'} style={{ height: '46px' }} variant="inverse" size={'md'}>
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>*/}
      </div>
      <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed">
        <Link
          to={'./new'}
          /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer backdrop-blur-[3px] content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-60 hover:text-blue-600 hover:border-blue-400'}>*/
          className={
            'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
          }
        >
          <div className="absolute left-[40%]">
            <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Scenario
          </div>
        </Link>

        <div className="block col-span-9 hover:bg-blue-100 p-4 focus:outline-none bg-white">
          <div className="grid grid-cols-12">
            <div className="col-span-1">
              <LiaCubeSolid className="h-6 w-6" />
            </div>
            <p className="col-span-7 truncate text-sm font-medium text-blue-600">Scenario name</p>
            <div className="col-span-2 flex py-1"></div>
            <p className="col-span-2 h-5 m-1 text-center content-center rounded bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" />
          </div>
          <div className="grid grid-cols-12">
            <div className="col-start-2 col-span-9 sm:justify-between">
              <p className="whitespace-wrap flex items-center text-sm text-gray-500 pr-2">Description</p>
            </div>
            <div className="mt-2 col-span-2 text-center content-center text-sm text-gray-500 sm:mt-0">
              <p>{new Date().toDateString()}</p>
            </div>
          </div>
        </div>
        <div className="flex col-span-1 items-center text-center justify-center bg-white">
          <RiEditBoxLine className="h-6 w-6 m-auto text-blue-600 focus:outline-none"></RiEditBoxLine>
        </div>

        <div className="flex col-span-1 items-center text-center justify-center bg-white">
          <GrDuplicate className="h-5 w-5 m-auto text-blue-600 focus:outline-none"></GrDuplicate>
        </div>

        <div className="flex col-span-1 items-center text-center justify-center text-red-500 bg-white">
          <RiDeleteBinLine className="h-6 w-6" />
        </div>
      </div>
      <ListScenario filter={{ scenarioName: search, scenarioDescription: search }} fetchData={useGetAllScenarioQuery} />
    </div>
  );
};
