import React from 'react';
import { InputText } from '../../../../components/input';
import { HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '../../../../components/Elements';
import ArrayFieldPositionMover from '../ArrayFieldPositionMover';

type TabManagementProps = {
  prefix: string;
};

function TabManagement(props: TabManagementProps) {
  const { prefix } = props;

  const { control, watch, register } = useFormContext();

  const {
    fields: tabFields,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: `${prefix}.tabs`,
  });

  return (
    <>
      <div className="rounded grid grid-cols-2 gap-6">
        {tabFields.map((s: any, index: number) => {
          return (
            <div key={s.id} className="col-span-1 flex gap-2 items-center">
              <InputText
                {...register(`${prefix}.tabs.${index}.name`)}
                label={'Tabs name'}
                addonBefore={<div className="text-blue-400">{index}</div>}
              />
              <InputText {...register(`${prefix}.tabs.${index}.description`)} label={'Tabs Description'} />
              <ArrayFieldPositionMover max={tabFields.length} index={index} move={move} />
              <HiOutlineTrash className="cursor-pointer h-6 w-6 text-red-500" onClick={() => remove(index)} />
            </div>
          );
        })}
      </div>
      <Button
        type="button"
        variant={'inverse'}
        className={'w-full mt-4 flex border-2'}
        onClick={() => append({ name: '' })}
      >
        <div className="flex items-center">
          <HiPlusCircle className="h-8 w-8 mr-4" />
          Add tab to phase
        </div>
      </Button>
    </>
  );
}

export default TabManagement;
