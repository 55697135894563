import { bomApi as api } from './bomApi';

export const enhancedBomApi = api.enhanceEndpoints({
  addTagTypes: ['Bom'],
  endpoints: {
    getAllBoms: {
      providesTags: (result, error, arg) => [{ type: 'Bom', id: 'LIST' }],
    },
    postPdx: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Bom', id: 'LIST' },
        { type: 'Bom', id: result?.id },
      ],
    },
    deleteBom: {
      invalidatesTags: (result, error, arg) => [{ type: 'Bom', id: 'LIST' }],
    },
  },
});
