import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Elements';
import { Bom, useGetAllBomsQuery } from '../../../../api/bom/bomApi';
import BillOfMaterial from '../../../bom-tree/components/BillOfMaterial';
import { RiDeleteBinLine, RiFileCopyLine } from 'react-icons/ri';
import { LiaFileImportSolid } from 'react-icons/lia';
import {
  AssessmentConfiguration,
  BasicScenarioTemplate,
  FormulaParameter,
  ItemScenarioTemplatePair,
  Parameter,
  useGetAllScenarioTemplateQuery,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import {
  useGetAllImpactMethodsQuery,
  useGetAllTreesInfoQuery,
} from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import React, { Fragment, useMemo, useState } from 'react';
import { InputText, SelectControl } from '../../../../components/input';
import { getInputError } from '../../../../lib/getInputError';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast/headless';
import { TextArea } from '../../../../components/input/TextArea';
import Modal from '../../../../components/Modal/Modal';
import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import { ParameterType } from '../../../../api/customizationSpace/types/ParameterType';
import { checkBomImportMapping } from '../../utils/checkBomImportMapping';
import ScenarioTemplateMapping from './ScenarioTemplateMapping';
import { InputList } from '../../../../components/input/InputList';
import { yupResolver } from '@hookform/resolvers/yup';
import { bomScenarioTemplateSchema } from '../validation/bomScenarioTemplateSchema';
import { SkipTests } from '../validation/skipTest';
import { Checkbox } from '../../../../components/input/Checkbox';
import { Show } from '../../../../components/show/Show';
import { BsFiletypeJson } from 'react-icons/bs';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/index';
import { debounce } from 'lodash';

type ScenarioTemplateFormProps = {
  initialValues: any;
};

function BomScenarioTemplateForm(props: ScenarioTemplateFormProps) {
  const { initialValues } = props;

  const navigate = useNavigate();
  const [doUpdate, setDoUpdate] = useState(false);
  const [importJson, setImportJson] = useState<string | boolean>('');
  const [importMapping, setImportMapping] = useState<any>([]);
  const [importModal, setImportModal] = useState(false);
  const [editJson, setEditJson] = useState(false);
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });

  const { data: boms } = useGetAllBomsQuery({});
  const { data: scenarioTemplates } = useGetAllScenarioTemplateQuery({
    type: 'BASIC',
  });
  const { data: trees } = useGetAllTreesInfoQuery({
    includeSubprocesses: true,
  });
  const { data: impactMethods } = useGetAllImpactMethodsQuery({});

  const methods = useForm({
    resolver: (values, context, options) => {
      return yupResolver(bomScenarioTemplateSchema)(
        values,
        {
          ...values,
          skipTests: [
            // SkipTests.PARAMETER_DISPLAY_TEST,
            // SkipTests.DEPENDS_ON_FORMULA_PARAMETER_TEST,
            // SkipTests.LINKED_PARAMETERS_TEST,
            // SkipTests.REMOTE_FLOW_TEST,
            SkipTests.VARIABLE_MAPPING_TEST,
            // SkipTests.FLOW_SWITCH_TEST,
            SkipTests.DISPLAY_TEST,
            SkipTests.PARAMETER_TEST,
          ],
        },
        options,
      );
    },
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    reset,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = methods;

  const template = watch();

  const filteredScenarioTemplates = useMemo(() => {
    if (!scenarioTemplates || !impactMethods || !trees) return;

    let assessmentTemplates: any[] = [];
    template.assessmentConfigurations?.forEach((ac: AssessmentConfiguration) => {
      assessmentTemplates.push(
        ...scenarioTemplates
          .filter((s) => s.assessmentConfigurations?.some((c) => c.assessmentType === ac.assessmentType))
          .filter((s) => {
            const configurations = s.assessmentConfigurations?.find((c) => c.assessmentType === ac.assessmentType);
            const templateTrees = trees?.filter(
              (t) => configurations?.phaseConfigurations?.map((pc) => pc.treeId).includes(t.id),
            );
            // Calculating an intersect between al the available methods of each  tree configured in the Basic Scenario template
            return templateTrees
              ?.reduce((acc, current) => {
                if (acc.length === 0) return current.impactMethodRefIds;
                return [...acc?.filter((m: string) => current.impactMethodRefIds?.indexOf(m) !== -1)];
              }, [])
              ?.includes(ac.impactMethodRefId as string);
          }),
      );
    });
    return [...new Map(assessmentTemplates?.map((item) => [item['id'], item])).values()];
  }, [doUpdate, scenarioTemplates, trees]);

  const {
    fields: itemScenarioTemplateConfigurationFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'itemScenarioTemplateConfiguration',
  });

  const { remove: removeParameter } = useFieldArray({
    control,
    name: 'parameters',
  });

  const handleImportJson = async () => {
    const mapping = checkBomImportMapping(
      importJson as string,
      scenarioTemplates as BasicScenarioTemplate[],
      boms as Bom[],
    );
    if (mapping.length === 0) {
      await reset(JSON.parse(importJson as string));
      setImportModal(false);
    } else {
      setImportModal(true);
      setImportMapping(mapping);
    }
  };

  const createTemplate = (values, forCopy: boolean = false) => {
    if (Object.keys(values).length === 0) return undefined;

    const templateCopy: any = {
      ...values,
      id: !values.id || forCopy ? undefined : values.id,
      type: 'BOM',
    };

    const parameters = [...(templateCopy.parameters ?? [])];
    const complexParameters: Parameter[] = [];
    const groups = [...(templateCopy.groups ?? [])];
    templateCopy.itemScenarioTemplateConfiguration = templateCopy.itemScenarioTemplateConfiguration.map(
      (ist, index) => {
        // if a configuration already exists than we don't want to override it.
        //therefore we return it unchanged.

        const basicScenarioTemplate: BasicScenarioTemplate = {
          ...scenarioTemplates?.find((st) => st.id === ist.scenarioTemplateId),
        };

        groups.push(...(basicScenarioTemplate.groups ?? []));
        if (ist.variableConfigurations) {
          return {
            // ...basicScenarioTemplate,
            displayConfigurations: basicScenarioTemplate.displayConfigurations,
            ...ist,
          };
        }

        if (Object.keys(basicScenarioTemplate).length === 0) return;

        let variableConfigurations = basicScenarioTemplate.variableConfigurations;
        let suffix = `:${ist.itemId}`;
        // If it's a specific configuration we use the parent id to identify it
        if (ist.parentId) {
          suffix = `:${ist.parentId}:${ist.itemId}`;
        }
        variableConfigurations = variableConfigurations?.map((vc) => {
          return {
            ...vc,
            parameterName: vc.parameterName ? `${vc.parameterName}${suffix}` : undefined,
          };
        });

        parameters.push(
          ...(basicScenarioTemplate.parameters?.map((p: Parameter) => {
            const parameter = { ...p };
            if (
              p.parameterType === ParameterType[ParameterType.OPTION] ||
              p.parameterType === ParameterType[ParameterType.FORMULA]
            ) {
              complexParameters.push(parameter);
            }

            return {
              ...parameter,
              item: suffix,
              parameterName: `${p.parameterName}${suffix}`,
              defaultParameterName: p.parameterName,
              display: {
                ...p.display,
                item: suffix,
                formulaParameter: p.display?.formulaParameter ? `${p.display.formulaParameter}${suffix}` : undefined,
              },
            };
          }) as Parameter[]),
        );

        delete basicScenarioTemplate.parameters;

        return {
          ...ist,
          ...basicScenarioTemplate,
          variableConfigurations,
          assessmentConfigurations: basicScenarioTemplate.assessmentConfigurations?.map((ac) => ({
            phaseConfigurations: ac.phaseConfigurations,
          })),
          index,
        };
      },
    );

    const substitutionErrors: any[] = [];

    complexParameters.forEach((cp) => {
      if (cp.parameterType === ParameterType[ParameterType.OPTION]) {
        cp.options = cp.options?.map((o) => {
          const option = { ...o };
          option.linkedParameters = o.linkedParameters?.map((lp) => {
            const linkedParameter = parameters.filter((p) => p.defaultParameterName === lp.parameterName);
            if (linkedParameter.length === 1) {
              return {
                ...lp,
                parameterName: linkedParameter[0].parameterName,
              };
            } else {
              substitutionErrors.push({
                message: `Warning substitution for option: ${cp.parameterName} linkedParameter not compliant (>1 or 0): ${linkedParameter}`,
                item: cp.item,
              });
              console.warn(
                'Warning substitution for option: ',
                cp.parameterName,
                ' linkedParameter not compliant (>1 or 0): ',
                linkedParameter,
              );
            }

            return lp;
          });
          return option;
        });
      }

      if (cp.parameterType === ParameterType[ParameterType.FORMULA]) {
        const formulaParameter = cp as FormulaParameter;
        formulaParameter.dependsOn = formulaParameter.dependsOn?.map((d) => {
          const dependency = parameters.filter((p) => p.defaultParameterName === d.parameterName);
          const originalDependency = { ...d };
          if (dependency.length === 1) {
            formulaParameter.formula = formulaParameter.formula?.replaceAll(
              d.parameterName as string,
              dependency[0].parameterName,
            );
            originalDependency.parameterName = dependency[0].parameterName;
          } else {
            substitutionErrors.push({
              message: `Warning substitution for formulaParameter: ${formulaParameter.parameterName} dependency not compliant (>1 or 0): ${dependency.length}`,
              item: cp.item,
            });
            console.warn(
              'Warning substitution for formulaParameter: ',
              formulaParameter.parameterName,
              ' dependency not compliant (>1 or 0): ',
              dependency.length,
            );
          }
          return d;
        });
      }
    });

    //Running DISTINCT on parameterName
    templateCopy.parameters = [...new Map(parameters?.map((item) => [item['parameterName'], item])).values()]?.map(
      (p: Parameter, index) => ({
        ...p,
        index,
      }),
    );

    //Running DISTINCT on name
    templateCopy.groups = [...new Map(groups?.map((item) => [item['name'], item])).values()];
    return templateCopy;
  };

  const handleFormSubmit = (values: BasicScenarioTemplate) => {
    console.log('handleFormSubmit', values);
    const template = createTemplate(values);
    console.log({ template });
    actions.initScenarioTemplateAction({ template });
    navigate('./../customization');
  };

  const handleRemoveTemplateConfiguration = (index: number) => {
    console.log('handleRemove');
    const ist = template.itemScenarioTemplateConfiguration[index];
    let suffix = `:${ist.itemId}`;
    if (ist.parentId) {
      suffix = `:${ist.parentId}:${ist.itemId}`;
    }
    const parameters = template.parameters?.filter((p) => p.item === suffix);
    if (parameters?.length > 0) {
      console.log('removing ', parameters.length, ' parameters');
      //Need to remove them in a DESC order otherwise indexes are wrong -
      // while we remove them their indexes do not change
      parameters.sort((a, b) => b.index - a.index);
      parameters?.forEach((p) => {
        console.log('Removing parameter: ', p);
        removeParameter(p.index);
      });
    }

    remove(index);
  };

  const debouncedClickHandler = debounce((values) => reset(values), 1000);

  return (
    <>
      <FormProvider {...methods}>
        <div className="rounded bg-white p-4 mt-3 flex flex-col shadow">
          <form onSubmit={handleSubmit(handleFormSubmit, console.error)}>
            <div className={'flex-1'}>
              <div className="my-4 flex justify-around">
                <input type="text" {...register('id')} hidden={true} />
                <InputText
                  label={'Name'}
                  type="text"
                  {...register('name')}
                  className="flex-1"
                  error={getInputError(errors, 'name')}
                />
                <InputText label={'Description'} type="text" {...register('description')} className="flex-1 ml-3" />
                <Checkbox className={'col-span-1 ml-3 flex-1'} label={'Visible'} {...register(`visible`)} />
              </div>

              <InputList
                error={getInputError(errors, 'assessmentConfigurations')}
                label={'Assessment types'}
                addButtonLabel={'Add Assessment Type'}
                control={control}
                name={'assessmentConfigurations'}
                item={{
                  assessmentType: '',
                  impactMethodId: '',
                }}
                renderItem={(field, index, remove) => (
                  <div className="col-span-12 flex py-3 gap-3 justify-around items-center">
                    <SelectControl
                      control={control}
                      name={`assessmentConfigurations.${index}.assessmentType`}
                      options={['LCA', 'LCC', 'SLCA', 'CE'].map((type) => ({
                        value: type,
                        label: type,
                      }))}
                      placeholder="Choose option"
                      label={'Assessment type'}
                      error={getInputError(errors, `assessmentConfigurations.${index}.assessmentType`)}
                      className={'flex-1'}
                    />
                    <SelectControl
                      control={control}
                      name={`assessmentConfigurations.${index}.impactMethodId`}
                      options={impactMethods?.map((p) => ({
                        value: p.id as string,
                        label: p.name as string,
                      }))}
                      onChange={(id) => {
                        setValue(
                          `assessmentConfigurations.${index}.impactMethodRefId`,
                          impactMethods?.find((m) => m.id === id)?.refId,
                        );
                        setDoUpdate(!doUpdate);
                      }}
                      error={getInputError(errors, `assessmentConfigurations.${index}.impactMethodId`)}
                      label={'Impact Method'}
                      className="flex-1"
                    />
                    <Button type="button" variant="none" onClick={() => remove(index)}>
                      <RiDeleteBinLine className="h-6 w-6 text-red-500" />
                    </Button>
                  </div>
                )}
              />

              {boms && (
                <SelectControl
                  control={control}
                  name={'bomId'}
                  className="bg-white my-6"
                  placeholder="Select the BOM"
                  error={getInputError(errors, 'bomId')}
                  options={boms.map((m) => ({
                    label: m.description,
                    value: m.id,
                  }))}
                />
              )}

              <div className="mt-3">
                {template.bomId ? (
                  <Fragment>
                    <BillOfMaterial
                      className={'border-gray-200 border-2'}
                      bom={boms?.find((b: Bom) => b.id === template.bomId) as Bom}
                      nodeConfiguration={{
                        nodeContent: (item) => {
                          let specific = true;
                          let config = template.itemScenarioTemplateConfiguration?.find(
                            (i: ItemScenarioTemplatePair) =>
                              i.parentId && i.itemId === item.id && i.parentId === item.parentId,
                          );

                          if (!config) {
                            config = template.itemScenarioTemplateConfiguration?.find(
                              (i: ItemScenarioTemplatePair) => !i.parentId && i.itemId === item.id,
                            );
                            specific = false;
                          }
                          if (!config) {
                            return `${item.description}:${item.id}`;
                          } else {
                            // console.log("Item: ", item, config)
                            if (config.scenarioTemplateId) {
                              return (
                                <span className={specific ? 'text-blue-700' : 'tex-green-700'}>
                                  {item.description}:{item.id}: success
                                </span>
                              );
                            } else {
                              return (
                                <span className="text-red-700">
                                  {item.description}:{item.id}: error
                                </span>
                              );
                            }
                          }
                        },
                      }}
                      itemContent={(item) => {
                        //Searching first for an individual config (for the specific item)
                        let config = template.itemScenarioTemplateConfiguration?.find(
                          (i) => i.itemId === item.id && i.parentId === item.parentId,
                        );
                        if (!config) {
                          //if an individual config does not exist I search for a specific one
                          config = template.itemScenarioTemplateConfiguration?.find(
                            (i) => i.itemId === item.id && !i.parentId,
                          );
                        }
                        if (config) {
                          // console.log({config}, itemScenarioTemplateConfigurationFields, item)
                          const { index, id } = itemScenarioTemplateConfigurationFields
                            .map((f, index) => ({
                              ...f,
                              index,
                            }))
                            .find(
                              (f: ItemScenarioTemplatePair) =>
                                f.itemId === config.itemId && f.parentId === config.parentId,
                            ) as any;
                          // console.log({field})
                          return (
                            <Fragment>
                              <div key={id} className="pt-6 grid grid-cols-8 mx-3 rounded flex flex-row justify-around">
                                <SelectControl
                                  name={`itemScenarioTemplateConfiguration.${index}.scenarioTemplateId`}
                                  control={control}
                                  onChange={() => setDoUpdate(!doUpdate)}
                                  className="bg-white max-w-md col-span-7 w-full"
                                  placeholder={'Select a scenario template'}
                                  options={filteredScenarioTemplates?.map((m) => ({
                                    label: m.name,
                                    value: m.id,
                                  }))}
                                  error={getInputError(
                                    errors,
                                    `itemScenarioTemplateConfiguration.${index}.scenarioTemplateId`,
                                  )}
                                />

                                <Button
                                  type="button"
                                  className="bg-blue-300"
                                  onClick={() => handleRemoveTemplateConfiguration(index)}
                                >
                                  <RiDeleteBinLine className="h-4 w-4" />
                                </Button>
                              </div>
                              {!config.parentId && (
                                <Button
                                  type="button"
                                  variant={'primary'}
                                  className="mt-3 mx-3"
                                  onClick={() =>
                                    append({
                                      parentId: item.parentId,
                                      itemId: item.id,
                                      scenarioTemplateId: '',
                                    })
                                  }
                                >
                                  {item.id}:Link a scenario template only to this item
                                </Button>
                              )}
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment>
                              <Button
                                type="button"
                                variant="primary"
                                className="mt-3 mx-3"
                                onClick={() =>
                                  append({
                                    parentId: item.parentId,
                                    itemId: item.id,
                                    scenarioTemplateId: '',
                                  })
                                }
                              >
                                {item.id} : Link a scenario template only to this item
                              </Button>
                              <Button
                                type="button"
                                variant="primary"
                                className="mt-3 mx-3"
                                onClick={() =>
                                  append({
                                    itemId: item.id,
                                    scenarioTemplateId: '',
                                  })
                                }
                              >
                                {item.id} : Link a scenario template to all items of this type
                              </Button>
                            </Fragment>
                          );
                        }
                      }}
                    />
                    {
                      <p className="text-sm text-red-500">
                        {getInputError(errors, 'itemScenarioTemplateConfiguration')}
                      </p>
                    }
                  </Fragment>
                ) : null}
              </div>
            </div>

            <div className="mt-6 flex-shrink">
              <Button type="submit" className="float-right">
                Add Customization
              </Button>
              {/*<Button className="float-right mr-2" onClick={saveScenarioTemplate}>*/}
              {/*    Save*/}
              {/*</Button>*/}

              <div className="flex">
                <CopyToClipboard
                  text={JSON.stringify(createTemplate(getValues(), true))}
                  onCopy={() => toast.success('Copied to clipboard')}
                >
                  <Button variant="none" className="">
                    <RiFileCopyLine className="w-6 h-6 text-sm" />
                    Copy as JSON
                  </Button>
                </CopyToClipboard>

                <Button
                  variant="none"
                  className="flex border-l-2 rounded-none"
                  onClick={() => setImportJson(!importJson)}
                >
                  <LiaFileImportSolid className="w-6 h-6 text-sm" />
                  <div>Import from JSON</div>
                </Button>
                <Button
                  variant="none"
                  className="flex border-l-2 rounded-none text-sm"
                  onClick={() => setEditJson(!editJson)}
                >
                  <BsFiletypeJson className="w-6 h-8" /> Edit JSON
                </Button>
              </div>

              <Show>
                <Show.When isTrue={!!importJson}>
                  <TextArea rows={10} onChange={(e) => setImportJson(e.target.value)} />
                  <Button onClick={handleImportJson}>Import</Button>
                </Show.When>
              </Show>
              <Show>
                <Show.When isTrue={editJson}>
                  <JsonEditor value={getValues()} onChange={debouncedClickHandler} />
                </Show.When>
              </Show>
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal size="lg" open={importModal} handleOpen={handleImportJson}>
        <div className="my-12">
          <ScenarioTemplateMapping
            boms={boms as Bom[]}
            importMapping={importMapping}
            scenarioTemplates={scenarioTemplates as BasicScenarioTemplate[]}
            onImportMappingChange={setImportMapping}
            importJson={importJson as string}
            onImportJsonChange={setImportJson}
          />
        </div>
      </Modal>
    </>
  );
}

export default BomScenarioTemplateForm;
