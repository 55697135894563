import { InputText } from './InputText';
import { SelectControl } from './controls/SelectControl';
import { InputNumber } from './InputNumber';
import { ColorPicker } from './ColorPicker';

export { InputText } from './InputText';
export { SelectControl } from './controls/SelectControl';
export { ColorPicker } from './ColorPicker';

const I: any = {
  InputText: {
    input: InputText,
    defaultValue: '',
  },
  TextArea: {
    input: InputText,
    defaultValue: '',
  },
  InputNumber: {
    input: InputNumber,
  },
  Select: {
    input: SelectControl,
    defaultValue: '',
  },
  MultipleSelect: {
    input: SelectControl,
    props: {
      isMulti: true,
    },
    defaultValue: null,
  },
  ColorPicker: {
    input: ColorPicker,
  },
};

export default I;
