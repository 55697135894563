export const PROJECT_API_URL = process.env.REACT_APP_PROJECT_API_URL as string;
export const CUSTOMIZED_SCENARIO_API_URL = process.env.REACT_APP_CUSTOMIZED_SCENARIO_API_URL as string;
export const SCENARIO_TEMPLATE_API_URL = process.env.REACT_APP_SCENARIO_TEMPLATE_API_URL as string;
export const CUSTOMIZATION_SPACE_API_URL = process.env.REACT_APP_CUSTOMIZATION_SPACE_API_URL as string;
export const LIGHTWEIGHT_CALCULATION_ENGINE_API_URL = process.env
  .REACT_APP_LIGHTWEIGHT_CALCULATION_ENGINE_API_URL as string;
export const LIGHTWEIGHT_OLCA_CORE_API_URL = process.env.REACT_APP_LIGHTWEIGHT_OLCA_CORE_API_URL as string;
export const REMOTE_DISPATCHER_API_URL = process.env.REACT_APP_REMOTE_DISPATCHER_API_URL as string;
export const BOM_API_URL = process.env.REACT_APP_BOM_API_URL as string;
export const USER_REALM_MAPPER_API_URL = process.env.REACT_APP_USER_REALM_MAPPER_API_URL as string;
