import React from 'react';
import * as Yup from 'yup';
import { FormProps } from '../../../components/types';
import { RemoteFlow } from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { InputText, SelectControl } from '../../../components/input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../components/Elements';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { InputList } from '../../../components/input/InputList';
import { Checkbox } from '../../../components/input/Checkbox';
import { ScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';

type IntegrationFormProps = FormProps & {
  flows: RemoteFlow[] | undefined;
  payloadTypes: any[];
  scenarioTemplates: ScenarioTemplate[];
};

export const IntegrationForm = (props: IntegrationFormProps) => {
  const { onSubmit, onError, scenarioTemplates, initialValues, payloadTypes, flows, submitLabel, className } = props;

  const integrationSchema = Yup.object().shape({
    name: Yup.string().required('Integration name is required').nullable(),
    description: Yup.string().required('Integration name is required').nullable(),
    label: Yup.string().required('Button label is required').nullable(),
    flowId: Yup.string().required('Flow mapping is required').nullable(),
    id: Yup.string(),
  });

  const { register, control, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(integrationSchema),
  });

  return (
    <form className={'shadow rounded bg-white p-4 mt-3'} onSubmit={handleSubmit(onSubmit, console.error)}>
      <InputText className={'my-3'} {...register('name')} label="Integration Name" />
      <InputText className={'my-3'} {...register('label')} label="Button label" />
      <InputText className={'my-3'} {...register('description')} label="Button description" />
      <InputText className={'my-3'} {...register('id')} hidden={true} />
      <Checkbox className={'my-3'} {...register('allowImpactsCustomization')} label={'Allow impacts customization'} />
      <SelectControl
        className={'my-3'}
        name={'flowId'}
        control={control}
        label="Flow"
        options={flows?.map((f: RemoteFlow) => ({ value: f.flowId as string, label: f.flowName as string }))}
      />
      <InputList
        control={control}
        name={'payload'}
        className={'my-3'}
        label={'Payload Items'}
        item={{ type: '' }}
        renderItem={(field, index, remove, append) => {
          return (
            <div className={'col-span-12 mt-4 flex'}>
              <SelectControl
                control={control}
                label={'Item'}
                className={'flex-1'}
                name={`payload.${index}.id`}
                options={payloadTypes?.map((pt) => ({ value: pt.id, label: pt.type }))}
              />
              <RiDeleteBinLine className="h-full w-6 text-red-500 self-center mx-2" onClick={() => remove(index)} />
            </div>
          );
        }}
      />
      <InputList
        control={control}
        name={'integrationTemplateScenarioTemplate'}
        className={'my-3'}
        label={'Associated scenario templates'}
        item={{ scenarioTemplateId: '' }}
        renderItem={(field, index, remove, append) => {
          return (
            <div className={'col-span-12 mt-4 flex'}>
              <SelectControl
                control={control}
                className={'flex-1'}
                label={'Scenario template'}
                name={`integrationTemplateScenarioTemplate.${index}.scenarioTemplateId`}
                options={scenarioTemplates?.map((st) => ({ value: st.id, label: st.name }))}
              />

              <RiDeleteBinLine className="h-full w-6 text-red-500 self-center mx-2" onClick={() => remove(index)} />
            </div>
          );
        }}
      />

      <Button variant={'inverse'} type="submit" className={'float-right'}>
        <RiEditBoxLine className="h-6 w-6 mr-2" /> {submitLabel ? submitLabel : 'Create'}
      </Button>
    </form>
  );
};
