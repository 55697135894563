import { Route, Routes } from 'react-router-dom';
import { StateMachineProvider } from 'little-state-machine';
import React, { Suspense } from 'react';
import { Spinner } from '../../../components/Elements';

const AssessmentMatching = React.lazy(() => import('../components/AssessmentMatching'));
export const ComparisonRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="text-center mt-16">
          <Spinner size={'h-16 w-16'} className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
        </div>
      }
    >
      <StateMachineProvider>
        <Routes>
          <Route path="" element={<AssessmentMatching />} />
        </Routes>
      </StateMachineProvider>
    </Suspense>
  );
};
