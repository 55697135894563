import OptionForm from './OptionForm';
import NumericForm from './NumericForm';
import FormulaForm from './FormulaForm';
import RemoteForm from './RemoteForm';
import ListForm from './ListForm';

export const VariableCustomization: any = {
  OPTION: OptionForm,
  NUMERIC: NumericForm,
  STRING: NumericForm,
  FORMULA: FormulaForm,
  REMOTE: RemoteForm,
  LIST: ListForm,
};
