import { DisplayConfiguration } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { CustomizationSpace } from '../../../../api/customizationSpace/customizationSpaceApi';
import { PhaseDto } from '../../../../api/project/projectApi';
import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import { SelectControl } from '../../../../components/input';
import { Button } from '../../../../components/Elements';
import { HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { generateParameters } from '../../utils/generateParameters';
import classNames from '../../../../utils/classNames';

type ScenarioTemplatePhaseCustomizationProps = {
  phase: PhaseDto;
  prefix: string;
  displayConfiguration: DisplayConfiguration;
  customizationSpaces: CustomizationSpace[];
  onParameterCreation: (parameters: any[]) => void;
};

function PhaseCustomization(props: ScenarioTemplatePhaseCustomizationProps) {
  const { phase, prefix, onParameterCreation, displayConfiguration, customizationSpaces } = props;

  const [open, setOpen] = useState(false);
  const [autogeneratedParameters, setAutogeneratedParameters] = useState<any[]>([]);
  const [autogeneratedTabs, setAutogeneratedTabs] = useState<any[]>([]);
  const { control, watch } = useFormContext();

  const {
    fields: customizationSpaceFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.customizationSpaces`,
  });

  const { fields: tabFileds, append: appendTab } = useFieldArray({
    control,
    name: `${prefix}.tabs`,
  });

  const selectedSpaceRefIds = watch(`${prefix}.customizationSpaces`);
  const existingTabs = watch(`${prefix}.tabs`);
  const existingParameters = watch(`parameters`);

  const handleParameterGeneration = () => {
    const g = generateParameters(customizationSpaces, selectedSpaceRefIds, displayConfiguration);
    //Filtering the already existing ones
    const parameters = g.parameters.map((gp) => {
      return {
        ...gp,
        create: !existingParameters.map((et) => et.parameterName).includes(gp.parameterName),
      };
    });
    setAutogeneratedParameters(parameters);
    const tabs = g.tabs.map((gt) => {
      return {
        ...gt,
        create: !existingTabs.map((et) => et.name).includes(gt.name),
      };
    });
    setAutogeneratedTabs(tabs);
  };

  const handleAppend = (values: any) => {
    append(values);
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleFormSubmit = () => {
    onParameterCreation(autogeneratedParameters.filter((ap) => ap.create));
    appendTab(autogeneratedTabs.filter((at) => at.create));
    setAutogeneratedParameters([]);
    setOpen(false);
  };

  return (
    <div className="bg-white py-2 rounded my-2">
      <h3 className="ml-4 text-lg font-bold my-4" style={{ color: phase.color }}>
        {phase?.name}
      </h3>
      <hr className="bg-gray-300 h-[1px]" />
      <div className="gap-2">
        <div className="">
          <div className="flex">
            {selectedSpaceRefIds.map((csRefId: string) => {
              const cs = customizationSpaces?.find((cs) => cs.customizationSpaceRefId === csRefId);
              return cs ? (
                <div key={cs.id} className="bg-gray-200 p-2 m-2 rounded">
                  {cs.name}
                </div>
              ) : (
                <div key={csRefId} className="bg-red-200 p-2 m-2 rounded">
                  {csRefId}
                </div>
              );
            })}
          </div>
          <Modal confirm={handleFormSubmit} open={open} handleOpen={setOpen} size={'lg'}>
            <div className="shadow sm:rounded bg-white p-4">
              <form className="grid grid-cols-12">
                {
                  <div className="mb-6 col-span-6">
                    {customizationSpaceFields.map((field: any, index: number) => {
                      return (
                        <div
                          key={field.id}
                          className="my-2 p-6 border rounded border-blue-100 shadow flex flex-row gap-4 justify-around"
                        >
                          <SelectControl
                            control={control}
                            className={'flex-1'}
                            onChange={() => setAutogeneratedParameters([])}
                            name={`${prefix}.customizationSpaces.${index}`}
                            options={customizationSpaces?.map((cs) => ({
                              value: cs.customizationSpaceRefId as string,
                              label: cs.name as string,
                              disabled: selectedSpaceRefIds?.includes(cs.customizationSpaceRefId),
                            }))}
                          />
                          <Button type="button" variant={'none'} onClick={() => handleRemove(index)}>
                            <HiOutlineTrash className="h-6 w-6 text-red-500" />
                          </Button>
                        </div>
                      );
                    })}
                    <Button type="button" className="w-full bg-blue-300" onClick={() => handleAppend('')}>
                      Add item
                    </Button>
                  </div>
                }
                <div className="m-3 col-span-6">
                  <Button className="w-full" onClick={handleParameterGeneration}>
                    Generate Parameters
                  </Button>
                  {autogeneratedParameters.length > 0 && (
                    <div>
                      <h3 className="font-bold text-lg">Parameters</h3>
                      <div className="grid grid-cols-2">
                        {autogeneratedParameters.map((p: any) => (
                          <p key={p.id} className={classNames(p.create ? 'text-green' : 'line-through', 'col-span-1')}>
                            {p.parameterName}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                  {autogeneratedTabs.length > 0 && (
                    <div>
                      <h3 className="font-bold text-lg">Tabs</h3>
                      <div className="grid grid-cols-2">
                        {autogeneratedTabs.map((t: any) => (
                          <p key={t.id} className={classNames(t.create ? 'text-green' : 'line-through', 'col-span-1')}>
                            {t.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </Modal>
          <Button className="w-full border-2 border-blue-500 rounded m-2" variant="none" onClick={() => setOpen(true)}>
            <HiPlusCircle className="h-6 w-6 text-blue-500" /> Add customization spaces
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PhaseCustomization;
