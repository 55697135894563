import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { InputText, SelectControl } from '../../../../components/input';
import Tag from '../../../tag/components/Tag';
import { HiOutlineTrash, HiXCircle } from 'react-icons/hi2';
import { re } from 'mathjs';
import BasicParameterForm from './BasicParameterForm';
import { Button } from '../../../../components/Elements';
import { DisplayConfiguration, Parameter } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { HiOutlineX, HiOutlineXCircle } from 'react-icons/hi';
// import { usePapaParse } from "react-papaparse";

type MappedField = {
  name: string;
  label: string;
  mapped?: string;
};

const defaultFields: MappedField[] = [
  {
    name: 'phase',
    label: 'Phase',
  },
  {
    name: 'processName',
    label: 'Process name',
  },
  {
    name: 'parameterName',
    label: 'Parameter name',
  },
  {
    name: 'visible',
    label: 'Visible',
  },
  {
    name: 'alias',
    label: 'Alias',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'defaultValue',
    label: 'Default Value',
  },
  {
    name: 'options',
    label: 'Options',
  },
  {
    name: 'formula',
    label: 'Formula',
  },
  {
    name: 'uom',
    label: 'Unit of measure',
  },
  {
    name: 'dependsOn',
    label: 'Depends on',
  },
  {
    name: 'range',
    label: 'Range',
  },
];

type ParameterImporter = {
  onAdd: (parameter) => void;
  displayConfigurations: DisplayConfiguration[];
};

function ParameterImporter(props: ParameterImporter) {
  // const { readString } = usePapaParse();
  const { onAdd, displayConfigurations } = props;

  const [data, setData] = useState<any[]>([]);
  // const [parameters, setParameters] = useState<any[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const methods = useForm<FieldValues & { mappings: MappedField[]; parameters: any[] }>({
    defaultValues: { mappings: [] },
  });

  const { reset, watch, setValue, formState, control } = methods;

  const { fields, update } = useFieldArray<FieldValues & { mappings: MappedField[]; parameters: any[] }>({
    name: 'mappings',
    control,
  });

  const { fields: parameters, remove } = useFieldArray<FieldValues & { mappings: MappedField[]; parameters: any[] }>({
    name: 'parameters',
    control,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          console.log(result.data);
          setData(result.data as any[]);
          setKeys(Object.keys(result.data[0] as any));
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  };

  useEffect(() => {
    if (keys?.length > 0) {
      reset({
        mappings: defaultFields.map((f, index) => ({
          ...f,
          mapped: keys[index],
        })),
      });
      setRefresh(true);
    }
  }, [keys]);

  const w = watch();

  console.log('Form: ', w);

  useEffect(() => {
    if (refresh) {
      refreshParameters();
      setRefresh(false);
    }
  }, [refresh]);

  const refreshParameters = () => {
    setValue(
      'parameters',
      data.map((p) => {
        const parameterType = !!p[w.mappings.find((m) => m.name === 'options')?.mapped]
          ? 'OPTION'
          : !!p[w.mappings.find((m) => m.name === 'formula')?.mapped]
            ? 'FORMULA'
            : 'NUMERIC';
        return {
          parameterName: !!p[w.mappings.find((m) => m.name === 'parameterName')?.mapped]
            ? `${p[w.mappings.find((m) => m.name === 'parameterName')?.mapped]}`.trim()
            : '',
          alias: p[w.mappings.find((m) => m.name === 'alias')?.mapped],
          description: p[w.mappings.find((m) => m.name === 'description')?.mapped],
          hidden: !!p[w.mappings.find((m) => m.name === 'visible')?.mapped]
            ? `${!p[w.mappings.find((m) => m.name === 'visible')?.mapped]}`.trim()
            : 'false',
          value: !!p[w.mappings.find((m) => m.name === 'defaultValue')?.mapped]
            ? `${p[w.mappings.find((m) => m.name === 'defaultValue')?.mapped]}`.trim()
            : '',
          parameterType,
          ...(parameterType === 'FORMULA'
            ? { formula: p[w.mappings.find((m) => m.name === 'formula')?.mapped] }
            : undefined),
          ...(parameterType === 'OPTION'
            ? { options: getOptions(p[w.mappings.find((m) => m.name === 'options')?.mapped]) }
            : undefined),
          type: 'AMOUNT_CHANGE',
          ...(parameterType === 'FORMULA'
            ? { dependsOn: getDepends(p[w.mappings.find((m) => m.name === 'dependsOn')?.mapped]) }
            : undefined),
          unitOfMeasure: !!p[w.mappings.find((m) => m.name === 'uom')?.mapped]
            ? `${p[w.mappings.find((m) => m.name === 'uom')?.mapped]}`.trim()
            : '',
          display: {
            tab: !!p[w.mappings.find((m) => m.name === 'processName')?.mapped]
              ? `${p[w.mappings.find((m) => m.name === 'processName')?.mapped]}`.trim()
              : '',
            phaseId: displayConfigurations.find(
              (dc) => dc.phaseName === p[w.mappings.find((m) => m.name === 'phase')?.mapped]?.trim(),
            )?.phaseId,
          },
        } as Parameter;
      }),
    );
  };

  const getOptions = (stringOption: string) => {
    console.log(stringOption);
    const opts = stringOption ? `${stringOption}`.split(';') : [];
    const options = opts.map((p) => {
      const split = p.split(':');
      return {
        value: split[1]?.replaceAll('"', '')?.replaceAll("'", '').trim(),
        label: split[0]?.replaceAll('"', '')?.replaceAll("'", '').trim(),
      };
    });
    console.log(options);
    return options;
  };

  const getDepends = (stringDependsOn: string) => {
    console.log(stringDependsOn);
    const opts = stringDependsOn ? `${stringDependsOn}`.split(';') : [];
    const depends = opts.map((o) => ({
      parameterName: o,
    }));
    console.log(depends);
    return depends;
  };

  console.log(parameters);

  return (
    <FormProvider {...methods}>
      {keys.length == 0 && (
        <div className="h-36 w-full relative border-2 rounded border-blue-200 border-dashed border-r-0">
          <div className={' absolute h-36 w-full'}>
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">.csv files</p>
            </div>
          </div>
          <input
            className="absolute h-36 w-full z-10 bg-transparent text-transparent"
            accept="text/csv"
            type="file"
            onChange={handleFileChange}
          />
        </div>
      )}
      {keys.length > 0 && (
        <div>
          <div className={'mb-2'}>
            {[...keys].map((f, index) => {
              return (
                <div className="h-8 inline-flex my-1 items-center gap-x-1 px-2 rounded shadow">
                  {f}
                  <HiOutlineXCircle
                    className="cursor-pointer text-red-500 h-6 w-6 "
                    aria-hidden="true"
                    onClick={() => setKeys((keys) => keys.filter((k) => k != f))}
                  />
                </div>
              );
            })}
          </div>
          {fields.map((f, index) => {
            return (
              <div className={'grid grid-cols-3 p-1'}>
                <div className={'col-span-1 content-center text-center'}>{f['label']}</div>
                <div className={'col-span-2'}>
                  <SelectControl
                    control={control}
                    name={`mappings.${index}.mapped`}
                    onChange={refreshParameters}
                    options={[...keys, 'Not Mapped'].map((a) => ({ label: a, value: a }))}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {parameters.length > 0 && (
        <div className={'py-2 flex flex-col'}>
          {parameters.map((f, index) => (
            <div className={'flex'}>
              <InputText className={'flex-1 m-2'} disabled={true} value={f.parameterName} key={index} />
              <HiOutlineTrash className={'h-6 w-6 self-center text-red-500'} onClick={() => remove(index)} />
            </div>
          ))}
          <Button className={'mt-2'} variant={'inverse'} onClick={() => onAdd(parameters)}>
            Import parameter{' '}
          </Button>
        </div>
      )}
    </FormProvider>
  );
}

export default ParameterImporter;
