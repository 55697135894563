import React, { Fragment, ReactNode, useState } from 'react';
import { Button } from '../Elements';
import Modal from './Modal';
import classNames from '../../utils/classNames';

type ButModalProps = {
  onClick?: (e) => void;
  children: React.ReactNode | (() => JSX.Element);
  label: string | ReactNode;
  variant?: 'primary' | 'inverse' | 'none' | 'danger';
  submitLabel?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  buttonSize?: 'none' | 'sm' | 'md' | 'lg' | 'xs';
  className?: string;
  confirm?: () => void;
  closeOnSubmit?: boolean;
  lazyRender?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export default function ButModal(props: ButModalProps) {
  const {
    children,
    onClick,
    buttonSize,
    size,
    lazyRender: lazy,
    variant,
    label,
    submitLabel,
    className,
    closeOnSubmit,
    confirm,
    ...buttonProps
  } = props;

  const [open, setOpen] = useState(false);
  const [lazyRender, setLazyRender] = useState(lazy);

  const handleConfirm = () => {
    confirm?.();
    if (closeOnSubmit) {
      setOpen(false);
      setLazyRender(lazy);
    }
  };
  const handleOpen = () => {
    setLazyRender(false);
    setOpen(true);
  };

  return (
    <>
      <Button
        {...buttonProps}
        variant={variant ? variant : 'none'}
        size={buttonSize ? buttonSize : 'none'}
        className={classNames(className ? className : '')}
        onClick={(e) => {
          onClick?.(e);
          handleOpen();
        }}
      >
        {label}
      </Button>
      {
        <Modal
          open={open}
          handleOpen={setOpen}
          size={size}
          confirm={confirm ? handleConfirm : undefined}
          label={submitLabel}
        >
          {!lazyRender ? (typeof children === 'function' ? children() : children) : undefined}
        </Modal>
      }
    </>
  );
}
