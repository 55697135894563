import { get } from '../utils/objectUtils';

export const getInputError = (errors, name) => {
  let errorMessage = undefined;
  const values = get(errors, name)?.filter(Boolean);

  if (values && values.length) {
    let error = values[0];
    errorMessage = error.message;
    return errorMessage;
  }
  return undefined;
};

export const getInputErrors = (errors, name) => {
  const analysed = get(errors, name)?.filter(Boolean);
  return analysed && analysed.length > 0
    ? analysed.reduce((a, v) => {
        return { ...a, ...v };
      }, {})
    : undefined;
};
