import { useFormContext } from 'react-hook-form';
import React from 'react';
import { InputList } from '../../../../components/input/InputList';
import { InputText, SelectControl } from '../../../../components/input';
import { InputNumber } from '../../../../components/input/InputNumber';

type NumericFormProps = {
  prefix: string;
};

function NumericForm(props: NumericFormProps) {
  const { prefix } = props;

  const { control, register, watch } = useFormContext();

  return (
    <div className="w-full">
      <InputList
        label={'Validations'}
        control={control}
        name={`${prefix}.validations`}
        renderItem={(field, index, remove, append) => {
          return (
            <div className="col-span-6 my-2 mx-1 p-2 gap-y-4 flex flex-col border border-blue-300 rounded">
              <SelectControl
                label="type"
                control={control}
                name={`${prefix}.validations.${index}.type`}
                options={['max', 'min'].map((i) => ({ label: i, value: i }))}
              />
              <InputNumber label={'Value'} {...register(`${prefix}.validations.${index}.value`)} />
              <InputText label={'Message'} {...register(`${prefix}.validations.${index}.message`)} />
            </div>
          );
        }}
        item={{ type: '', value: '', message: '' }}
      />
    </div>
  );
}

export default NumericForm;
