import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectDto,
  useGetAllScenarioQuery,
  useGetProjectQuery,
  usePostProjectMutation,
} from '../../../api/project/projectApi';
import { Button, Link, Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListScenario from '../../scenario/components/ListScenario';
import { ProjectForm } from './ProjectForm';
import { toast } from 'react-hot-toast/headless';
import { InputText } from '../../../components/input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import { LiaCubeSolid } from 'react-icons/lia';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { GrDuplicate } from 'react-icons/gr';

function Project() {
  const { projectId } = useParams();
  const [search, setSearch] = useState();
  const { data: project, isLoading } = useGetProjectQuery(projectId ? { projectId: projectId } : skipToken);
  const [postProject] = usePostProjectMutation();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (project) {
      setBreadcrumbs([
        { label: 'projects', path: '/app/projects' },
        { label: project.name as string, path: `/app/projects/${projectId}` },
      ]);
    }
  }, [project]);

  const onSubmit = async (project: ProjectDto) => {
    const updatedProject: any = await postProject({
      projectDto: project,
    }).unwrap();
    if (updatedProject.error) {
      // console.log(updatedProject.error);
      toast.error('Failed to update project');
    } else {
      toast.success('Project updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <>
      {project && <ProjectForm className="mt-3" initialValues={project} onSubmit={onSubmit} submitLabel={'Update'} />}

      <div className="flex flex-col">
        <div className="flex flex-row ml-auto my-3">
          <InputText
            addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed">
          <Link
            to={`/app/scenarios/new/${projectId}`}
            /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-80 hover:text-blue-600 hover:border-blue-400'}>*/
            className={
              'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
            }
          >
            <div className="absolute left-[40%]">
              <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Scenario
            </div>
          </Link>

          <div className="block bg-white col-span-9 hover:bg-blue-100 px-4 py-3.5 focus:outline-none">
            <div className="grid grid-cols-12">
              <div className="col-span-1">
                <LiaCubeSolid className="h-6 w-6" />
              </div>
              <p className="col-span-7 truncate text-sm font-medium text-blue-600">Scenario name</p>
              <div className="col-span-2 flex py-1"></div>
              <p className="col-span-2 h-5 m-1 text-center content-center rounded bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" />
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-2 col-span-9 sm:justify-between">
                <p className="whitespace-wrap flex items-center text-sm text-gray-500 pr-2">Description</p>
              </div>
              <div className="mt-2 col-span-2 text-center content-center text-sm text-gray-500 sm:mt-0">
                <p>{new Date().toDateString()}</p>
              </div>
            </div>
          </div>
          <div className="flex col-span-1 items-center text-center justify-center bg-white">
            <RiEditBoxLine className="h-6 w-6 m-auto text-blue-600 focus:outline-none"></RiEditBoxLine>
          </div>

          <div className="flex col-span-1 items-center text-center justify-center bg-white">
            <GrDuplicate className="h-5 w-5 m-auto text-blue-600 focus:outline-none"></GrDuplicate>
          </div>

          <div className="flex col-span-1 items-center text-center justify-center text-red-500 bg-white">
            <RiDeleteBinLine className="h-6 w-6" />
          </div>
        </div>
        <ListScenario
          filter={projectId ? { projectId: projectId, scenarioName: search, scenarioDescription: search } : skipToken}
          fetchData={useGetAllScenarioQuery}
        />
      </div>
    </>
  );
}

export default Project;
