import { HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '../../../../components/Elements/Button';
import { InputText, SelectControl } from '../../../../components/input';
import React from 'react';
import OptionLinkForm from './OptionLinkForm';
import { Checkbox } from '../../../../components/input/Checkbox';
import { getInputError } from '../../../../lib/getInputError';
import { useGetAllRemoteFlowsQuery } from '../../../../api/remoteDispatcher/remoteDispatcherApi';

type OptionFormProps = {
  prefix: string;
  type: string;
};

function OptionForm(props: OptionFormProps) {
  const { prefix, type } = props;

  const { data: flows } = useGetAllRemoteFlowsQuery({});
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const {
    fields: optionsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.options`,
  });

  return (
    <div className="w-full">
      {optionsFields.map((o, index) => {
        // console.log('optionsFields test:', getInputError(errors, `options.${index}.value`));
        return (
          <>
            <div className="flex my-3 items-center gap-x-5">
              <span className="font-bold">Option {index}</span>
              <InputText
                className="flex-1"
                {...register(`${prefix}.options.${index}.label`)}
                label={'Label'}
                error={getInputError(errors, `${prefix}.options.${index}.label`)}
              />
              <InputText
                className="flex-1"
                {...register(`${prefix}.options.${index}.value`)}
                label={'Value'}
                error={getInputError(errors, `${prefix}.options.${index}.value`)}
              />
              <InputText className="flex-1" {...register(`${prefix}.options.${index}.condition`)} label={'Condition'} />
              {type === 'FLOW_SWITCH' && (
                <Checkbox {...register(`${prefix}.options.${index}.isDefault`)} label={'Is Default'} />
              )}
              <Button type="button" variant="none" onClick={() => remove(index)}>
                <HiOutlineTrash className="h-6 w-6 text-red-500" />
              </Button>
            </div>
            <OptionLinkForm prefix={`${prefix}.options.${index}`} className="mx-12" errors={errors} />
          </>
        );
      })}
      <Button
        type="button"
        variant="none"
        className="flex text-blue-500 border border-blue-500"
        onClick={() => {
          setValue(`${prefix}.optionsFlowId`, undefined);
          append({ label: '', value: '' });
        }}
      >
        <HiPlusCircle className="h-6 w-6 mr-2" /> Option
      </Button>

      <SelectControl
        control={control}
        name={`${prefix}.optionsFlowId`}
        label="Remote flow (if options are gathered from ENDPOINT)"
        className="flex-1 my-3"
        options={flows?.map((p) => ({
          value: p.flowId,
          label: p.flowName,
        }))}
        disabled={optionsFields.length > 0}
        placeholder={
          optionsFields.length > 0 ? 'Remove all options to enable endpoint retrieval' : 'Select a remote flow'
        }
        onChange={() => {
          setValue(`${prefix}.options`, []);
        }}
      />
    </div>
  );
}

export default OptionForm;
