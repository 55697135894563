import React, { Fragment, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FieldValue } from 'react-hook-form/dist/types/fields';
import { Parameter } from '../../../../api/customizationSpace/customizationSpaceApi';
import classNames from '../../../../utils/classNames';
import VariableConfiguration from './VariableConfiguration';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';
import VisibleAccordion from '../../../../components/Accordion/VisibleAccordion';

type VariableConfigurationsManagerProps = {
  prefix: string;
  parameters: Parameter[];
  className?: string;
};

function VariableConfigurationsManager(props: VariableConfigurationsManagerProps) {
  const { prefix, parameters, className } = props;

  const { control, watch, register } = useFormContext();
  const [expand, setExpand] = useState(false);

  const { fields: itemVariableConfigurationFields } = useFieldArray({
    control,
    name: prefix,
  });

  return (
    <div className={'border-2 rounded border-gray-500 py-4 mx-2'}>
      <h3 className={'text-center font-bold text-lg my-2'}>Model variables mapping</h3>
      <VisibleAccordion height={'lg'}>
        <div className={classNames(className ? className : 'grid-cols-1', 'px-2 grid mt-4')}>
          {itemVariableConfigurationFields?.map((variableConfigurationField: FieldValue<any>, index: number) => (
            <Fragment key={variableConfigurationField.id}>
              <VariableConfiguration prefix={`${prefix}.${index}`} parameters={parameters} />
            </Fragment>
          ))}
        </div>
      </VisibleAccordion>
    </div>
  );
}

export default VariableConfigurationsManager;
