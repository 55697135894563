import { projectApi as api } from './projectApi';

export const enhancedProjectApi = api.enhanceEndpoints({
  addTagTypes: ['Scenario', 'Project', 'Phase', 'Tag'],
  endpoints: {
    getAllScenario: {
      providesTags: (result, error, arg) => [{ type: 'Scenario', id: 'LIST' }],
    },
    getScenario: {
      providesTags: (result, error, arg) => [
        { type: 'Scenario', id: arg.scenarioId },
        { type: 'Scenario', id: arg.scenarioId },
      ],
    },
    getAllScenarioByProjectId: {
      providesTags: (result, error, arg) => [{ type: 'Scenario', id: 'LIST' }],
    },
    postScenario: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Scenario', id: 'LIST' },
        { type: 'Scenario', id: result?.id },
      ],
    },
    deleteScenario: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Scenario', id: arg.scenarioId },
          { type: 'Scenario', id: 'LIST' },
          { type: 'Project', id: 'LIST' },
        ];
      },
    },
    getAllProject: {
      providesTags: (result, error, arg) => [
        { type: 'Project', id: 'LIST' },
        { type: 'Scenario', id: 'LIST' },
      ],
    },
    postProject: {
      invalidatesTags: (result, error, arg) => [{ type: 'Project', id: 'LIST' }],
    },
    deleteProject: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Project', id: arg.projectId },
          { type: 'Project', id: 'LIST' },
          { type: 'Scenario', id: 'LIST' },
        ];
      },
    },
    getAllPhase: {
      providesTags: (result, error, arg) => [{ type: 'Phase', id: 'LIST' }],
    },
    postPhase: {
      invalidatesTags: (result, error, arg) => [{ type: 'Phase', id: 'LIST' }],
    },
    deletePhase: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Phase', id: arg.phaseId },
          { type: 'Phase', id: 'LIST' },
        ];
      },
    },
    deleteTag: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Tag', id: 'LIST' },
          { type: 'Tag', id: arg.tagId },
        ];
      },
    },
    getTag: {
      providesTags: (result, error, arg) => [{ type: 'Tag', id: result?.id }],
    },
    getAllTag: {
      providesTags: (result, error, arg) => [{ type: 'Tag', id: 'LIST' }],
    },
    postTag: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Tag', id: arg.tag.id },
          { type: 'Tag', id: 'LIST' },
        ];
      },
    },
    patchTag: {
      invalidatesTags: (result, error, arg) => {
        return [
          { type: 'Tag', id: arg.tag.id },
          { type: 'Tag', id: 'LIST' },
        ];
      },
    },
  },
});
