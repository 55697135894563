import { Route, Routes } from 'react-router-dom';
import { RemoteFlows } from './RemoteFlows';

export const RemoteFlowRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<RemoteFlows />} />
    </Routes>
  );
};
