import { PhaseForm } from './PhaseForm';
import { useNavigate } from 'react-router-dom';
import { PhaseDto, usePostPhaseMutation } from '../../../api/project/projectApi';
import React from 'react';
import toast from 'react-hot-toast/headless';

function CreatePhase() {
  const history = useNavigate();
  const [postPhase] = usePostPhaseMutation();

  const onSubmit = async (phase: PhaseDto) => {
    const newPhase: any = await postPhase({ phaseDto: phase }).unwrap();
    if (newPhase.error) {
      // console.log(newPhase.error);
      toast.error('Failed to create phase');
    } else {
      toast.success('Phase created successfully');
      history(`../`);
    }
  };

  return <PhaseForm onSubmit={onSubmit} formMode={'CREATE'} />;
}

export default CreatePhase;
