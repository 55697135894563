import * as React from 'react';

import classNames from '../../../utils/classNames';

const disabled =
  ' disabled:opacity-30 disabled:bg-gray-300 disabled:border-2 disabled:border-blue-600 hover:disabled:bg-gray-400  hover:disabled:text-gray-900 disabled:cursor-not-allowed';

const variants = {
  primary:
    'bg-blue-600 text-white border hover:bg-white rounded hover:border-blue-600 hover:text-blue-600 flex' + disabled,
  inverse: 'bg-white text-blue-600 border border-blue-600 hover:bg-blue-600 rounded hover:text-white flex' + disabled,
  danger: 'bg-red-600 text-white border hover:bg-white rounded hover:text-red-600 hover:border-red-600 flex' + disabled,
  'danger-inverse':
    'hover:bg-red-600 hover:text-white border bg-white rounded text-red-600 border-red-600 flex' + disabled,
  none: '',
};

const sizes = {
  none: '',
  xs: 'py-1 px-1 text-sm',
  sm: 'py-1 px-2 text-sm',
  md: 'py-2 px-4 text-md',
  lg: 'py-3 px-8 text-lg',
};

type IconProps = {
  startIcon?: React.ReactElement | undefined;
  endIcon?: React.ReactElement | undefined;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', className = '', variant = 'primary', size = 'md', startIcon, endIcon, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type={type}
        className={classNames('justify-center items-center', variants[variant], sizes[size], className)}
        {...props}
      >
        {startIcon}
        {props.children}
        {endIcon}
      </button>
    );
  },
);

Button.displayName = 'Button';
