import React, { Fragment } from 'react';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

export type InputNumberProps = {
  addonBefore?: any;
  addonAfter?: any;
  error?: string;
  value?: string | number | undefined;
  placeHolder?: string;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  className?: string;
  hidden?: boolean;
  min?: number | string | undefined;
  step?: number;
};

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const {
    addonBefore,
    addonAfter,
    value,
    name,
    placeHolder,
    onChange,
    label,
    error,
    description,
    id,
    disabled,
    hidden,
    className,
    min,
    step,
  } = props;

  const renderCounter = useRenderCounter();

  return (
    <div className={classNames(className ? className : '', hidden ? 'hidden' : '', 'relative')}>
      {renderCounter}
      <label className="whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-50%  via-transparent via-55% to-transparent absolute -top-[8px] z-2 left-4 px-1 text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={`flex rounded border-2 focus-visible:outline-none focus:border-blue-500 ${
          error ? 'border-red-500' : ''
        }`}
      >
        {addonBefore && (
          <span className="inline-flex items-center rounded-l border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonBefore}
          </span>
        )}
        <input
          type={'number'}
          {...(min !== null ? { min } : {})}
          {...(step !== null ? { step } : {})}
          disabled={disabled}
          name={name}
          ref={ref}
          style={{ backgroundColor: '#F8FAFC' }}
          id={id}
          className={classNames(
            disabled ? 'p-2 border-gray-200 text-gray-500 bg-gray-50' : '',
            'pt-3 p-2 w-0 w-full flex-1 focus-visible:outline-none  leading-5',
            error
              ? 'placeholder-red-600 placeholder-opacity-70 placeholder:italic focus:placeholder-transparent'
              : 'focus:ring-1 ring-inset focus:ring-blue-500 rounded',
          )}
          placeholder={error ? error : placeHolder}
          onChange={onChange}
          value={value}
        />
        {error && (
          <Fragment>
            <div className={'bg-gray-50 rounded mr-2'}>
              <HiOutlineExclamationCircle
                id={`${name?.replaceAll('.', '')}-error`}
                className="focus:outline-none w-5 text-red-500 flex-shrink-0 self-center h-full focus-visible:outline-none shadow-none cursor-default"
              />
            </div>
            <Tooltip
              anchorSelect={`#${name?.replaceAll('.', '')}-error`}
              place={'right-end'}
              className="cursor-none"
              variant={'error'}
              style={{ backgroundColor: 'white', color: 'rgb(239, 68, 68)' }}
              border="1px solid rgb(239, 68, 68)"
              content={error}
            />
          </Fragment>
        )}
        {addonAfter && (
          <span className="inline-flex items-center rounded-r border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonAfter}
          </span>
        )}
      </div>

      {description && <p className="ml-2 mt-1 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
