import { Route, Routes } from 'react-router-dom';
import CreatePhase from '../components/CreatePhase';
import Phase from '../components/Phase';
import { Phases } from './Phases';
import React from 'react';

export const PhaseRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Phases />} />
      <Route path="edit/:phaseId" element={<Phase />} />
      <Route path="/new" element={<CreatePhase />} />
    </Routes>
  );
};
