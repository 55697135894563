import React, { useState } from 'react';
import { HiXMark } from 'react-icons/hi2';

type PasswordPopupProps = {
  message: string;
  onClose: () => void;
  handleOnSubmit: (password: string) => void;
};

export const PasswordPopup = ({ message, onClose, handleOnSubmit }: PasswordPopupProps) => {
  const [password, setPassword] = useState<string>('');

  return (
    <div className="bg-white p-4 rounded text-center z-100">
      <HiXMark className="h-6 w-6 float-right mb-2 cursor-pointer" onClick={onClose} />
      <h2 className="text-xl text-gray-700 mb-4">{message}</h2>
      <div className="mb-4 pb-2">
        <input
          type="password"
          id="password"
          className="w-full border border-gray-300 p-2 rounded"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <div className="w-full flex justify-center pb-2">
        <button
          className="w-full bg-white text-blue-500 border-2 border-blue-500 p-2 rounded hover:bg-blue-600 hover:text-white"
          onClick={() => handleOnSubmit(password)}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
