import { Route, Routes } from 'react-router-dom';
import { StateMachineProvider } from 'little-state-machine';

import CreateScenario from '../components/CreateScenario';
import Scenario from '../components/Scenario';
import { Scenarios } from './Scenarios';

export const ScenarioRoutes = () => {
  return (
    <StateMachineProvider>
      <Routes>
        <Route path="" element={<Scenarios />} />
        <Route path="edit/:scenarioId" element={<Scenario />} />
        <Route path="new/:projectId" element={<CreateScenario />} />
        <Route path="new/" element={<CreateScenario />} />
      </Routes>
    </StateMachineProvider>
  );
};
