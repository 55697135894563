import { emptyCustomizationSpaceApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomizationSpaces: build.query<GetCustomizationSpacesApiResponse, GetCustomizationSpacesApiArg>({
      query: (queryArg) => ({
        url: `/v1/customization-space/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { customizationSpaceRefIds: queryArg.customizationSpaceRefIds },
      }),
    }),
    upsertCustomizationSpace: build.mutation<UpsertCustomizationSpaceApiResponse, UpsertCustomizationSpaceApiArg>({
      query: (queryArg) => ({
        url: `/v1/customization-space/`,
        method: 'POST',
        body: queryArg.customizationSpace,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getByCustomizationSpaceRefId: build.query<
      GetByCustomizationSpaceRefIdApiResponse,
      GetByCustomizationSpaceRefIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customization-space/${queryArg.customizationSpaceRefId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { last: queryArg.last },
      }),
    }),
    deleteCustomizationSpace: build.mutation<DeleteCustomizationSpaceApiResponse, DeleteCustomizationSpaceApiArg>({
      query: (queryArg) => ({
        url: `/v1/customization-space/${queryArg.customizationSpaceId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as customizationSpaceApi };
export type GetCustomizationSpacesApiResponse = /** status 200 OK */ CustomizationSpace[];
export type GetCustomizationSpacesApiArg = {
  customizationSpaceRefIds?: string[];
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type UpsertCustomizationSpaceApiResponse = /** status 200 OK */ string;
export type UpsertCustomizationSpaceApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  customizationSpace: CustomizationSpace;
};
export type GetByCustomizationSpaceRefIdApiResponse = /** status 200 OK */ CustomizationSpace;
export type GetByCustomizationSpaceRefIdApiArg = {
  customizationSpaceRefId: string;
  last?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteCustomizationSpaceApiResponse = /** status 200 OK */ string;
export type DeleteCustomizationSpaceApiArg = {
  customizationSpaceId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type LinkedParameter = {
  parameterName: string;
  value: string;
};
export type Option = {
  label: string;
  value: string;
  isDefault?: boolean;
  linkedParameters?: LinkedParameter[];
};
export type Validation = {
  type?: string;
  value?: number;
  message?: string;
};
export type DisplayOption = {
  phaseId?: string;
  tab?: string;
  group?: string;
  item?: string;
  formulaParameter?: string;
};
export type Parameter = {
  alias?: string;
  value?: string;
  item?: string;
  description?: string;
  tooltip?: string;
  parameterName: string;
  // options?: Option[];
  // validations?: Validation[];
  unitOfMeasure?: string;
  type: 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER';
  parameterType: 'NUMERIC' | 'STRING' | 'OPTION' | 'FORMULA' | 'REMOTE' | 'LIST';
  hidden?: string;
  editable?: string;
  display?: DisplayOption;
};
export type FlowSwitchParameter = Parameter & {
  parentRefId?: string;
  rootModeledProcessRefId?: string;
};
export type Dependency = {
  parameterName?: string;
};
export type FormulaParameter = Parameter & {
  formula?: string;
  dependsOn?: Dependency[];
  accuracy?: number;
};
export type RemoteParameter = Parameter & {
  flowId?: string;
};
export type CustomizationSpace = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  customizationSpaceRefId: string;
  alias?: string;
  name?: string;
  description?: string;
  displayOrder?: number;
  parameters: (FlowSwitchParameter | FormulaParameter | RemoteParameter)[];
};
export const {
  useGetCustomizationSpacesQuery,
  useUpsertCustomizationSpaceMutation,
  useGetByCustomizationSpaceRefIdQuery,
  useDeleteCustomizationSpaceMutation,
} = injectedRtkApi;
