import { useFormContext } from 'react-hook-form';
import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import classNames from '../../../utils/classNames';
import React, { Fragment, Suspense, useMemo } from 'react';
import useRenderCounter from './../../../hooks/useRenderCounter';
import { useResizeDetector } from 'react-resize-detector';
import { IndexedParameter } from './CustomizationTemplateForm';
import { InputList } from '../../../components/input/InputList';
import { ListableParameter, ListParameter } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { InputText } from '../../../components/input';
import { Button, Spinner } from '../../../components/Elements';
import { evaluateScriptWithScope } from '../utils/evaluateScriptWithScope';
import { Inputs } from './input';
/*import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';*/
import { Tooltip } from 'react-tooltip';
import { ParameterInput } from './input/ParameterInput';
import { Show } from '../../../components/show/Show';
import { HiOutlineInformationCircle } from 'react-icons/hi';

type CustomizationParameterFormProps = IndexedParameter & {
  onFormChange: () => void;
};

export const CustomizationParameterForm = (props: CustomizationParameterFormProps) => {
  const { alias, parameterName, tooltip, parameterType, unitOfMeasure, hidden, onFormChange } = props;

  const { watch, formState, getValues } = useFormContext();

  const renderCounter = useRenderCounter();
  const { width, ref } = useResizeDetector();
  const parameters: IndexedParameter[] = watch('parameters');

  const hide = useMemo(
    () => (hidden ? evaluateScriptWithScope(hidden, getValues().parameters, parameterName) : false),
    [hidden, formState],
  );

  const Input = Inputs[parameterType];

  const tooltipElement = useMemo(
    () => (
      <Fragment>
        <HiOutlineInformationCircle
          id={`${parameterName}-tooltip`}
          className={classNames(
            'z-2 ml-1 h-4 w-4 self-center items-center cursor-pointer focus-visible:outline-none hover:text-blue-600 text-blue-400 rounded-full bg-white',
          )}
        />
        <Tooltip
          anchorSelect={`#${parameterName}-tooltip`}
          content={tooltip as string}
          style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)' }}
          border="1px solid rgb(29, 78, 213)"
        />
      </Fragment>
    ),
    [parameterName, tooltip],
  );

  return (
    <>
      <div ref={ref} className={classNames(hide ? 'hidden' : 'block', 'bg-white px-4 p-1 xs:p-4')}>
        <div className="grid grid-cols-3 gap-6">
          <Show>
            <Show.When isTrue={width && width > 768}>
              <div className="col-span-1 flex items-center">
                <h3 className="flex text-base font-semibold leading-6 text-gray-900">
                  {alias} {unitOfMeasure ? `[${unitOfMeasure}]` : ''}
                  {tooltip && tooltipElement}
                </h3>
              </div>
            </Show.When>
          </Show>
          <div className={classNames(width && width > 768 ? 'col-span-2' : 'col-span-3', 'mt-2')}>
            {Input && (
              <Suspense fallback={<Spinner size={'h-8 w-8  '} />}>
                <Input
                  {...props}
                  name={`parameters.${props.index}.value`}
                  label={
                    width && width < 768 ? (
                      <span className={'flex'}>
                        {alias}
                        {unitOfMeasure ? ' [' + unitOfMeasure + ']' : ''}
                        {tooltip && tooltipElement}
                      </span>
                    ) : (
                      ''
                    )
                  }
                  parameters={parameters}
                  hidden={hide}
                />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
