import { Item } from '../../../api/bom/bomApi';

export const getItemDescendants = (
  searchItem: Item & { parentId?: string },
  recursionItem: Item | null,
  items: Item[],
  suffix: string,
  add: boolean,
): any[] => {
  const descendants: any[] = [];
  if (recursionItem === null) {
    recursionItem = items.find((i) => i.root) as Item;
  }

  // console.log(searchItem, suffix);
  if (!add && recursionItem.id === searchItem.id && searchItem.parentId === suffix) {
    add = true;
  }

  suffix += `:${recursionItem.id}`;

  recursionItem.components?.forEach((c) => {
    if (add) {
      descendants.push({ itemId: `:${c.itemId}`, parentId: suffix });
    }
    const childItem = items.find((i) => i.id === c.itemId) as Item;
    descendants.push(...getItemDescendants(searchItem, childItem, items, suffix, add));
  });

  return descendants;
};
