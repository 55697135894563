import { useNavigate } from 'react-router-dom';
import { ImpactMethodForm } from './ImpactMethodForm';
import toast from 'react-hot-toast/headless';
import {
  ImpactCategory,
  ImpactMethod,
  useCustomizeImpactMethodMutation,
  useGetAllImpactMethodsQuery,
} from '../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { useState } from 'react';
import { SimpleSelect } from '../../../components/input/SimpleSelect';

function CreateCustomImpactMethod() {
  const history = useNavigate();

  const [defaultImpactMethod, setDefaultImpactMethod] = useState<string>('');

  const [postCustomizedImpactMethod] = useCustomizeImpactMethodMutation();
  const { data: impactMethods } = useGetAllImpactMethodsQuery({});

  const onSubmit = async (impactMethod: ImpactMethod) => {
    const newImpactMethod: any = await postCustomizedImpactMethod({
      impactMethod,
    }).unwrap();

    if (newImpactMethod.error) {
      // console.log(newImpactMethod.error);
      toast.error('Failed to create impact method');
    } else {
      history(`/app/impact-methods`);
      toast.success('Customized impact methodology created successfully');
    }
  };

  return (
    <div className="bg-white shadow mt-3 p-4">
      {impactMethods ? (
        <SimpleSelect
          className={'mb-6 mt-3'}
          label={'Impact methodology to copy'}
          id={'defaultImpactMethod'}
          options={impactMethods.map((im: ImpactMethod) => ({
            label: im.name,
            value: im.id,
          }))}
          value={defaultImpactMethod}
          placeholder={'Select the impact method you want to customize'}
          onChange={setDefaultImpactMethod}
        />
      ) : null}

      {defaultImpactMethod ? (
        <ImpactMethodForm
          impactMethod={impactMethods?.find((i: ImpactCategory) => i.id === defaultImpactMethod) as ImpactMethod}
          onSubmit={onSubmit}
          onError={(errors) => toast.error('Error in form')}
          submitLabel={'Create customized impact method'}
        />
      ) : null}
    </div>
  );
}

export default CreateCustomImpactMethod;
