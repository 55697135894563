export enum SkipTests {
  DEPENDS_ON_FORMULA_PARAMETER_TEST = 'DEPENDS_ON_FORMULA_PARAMETER_TEST',
  PARAMETER_DISPLAY_TEST = 'PARAMETER_DISPLAY_TEST',
  LINKED_PARAMETERS_TEST = 'LINKED_PARAMETERS_TEST',
  VARIABLE_MAPPING_TEST = 'VARIABLE_MAPPING_TEST',
  REMOTE_FLOW_TEST = 'REMOTE_FLOW_TEST',
  FLOW_SWITCH_TEST = 'FLOW_SWITCH_TEST',
  DISPLAY_TEST = 'DISPLAY_TEST',
  PARAMETER_TEST = 'PARAMETER_TEST',
  FORMULA_TEST = 'FORMULA_TEST',
}
