import { HiFolderOpen } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  ScenarioTemplate,
  useDeleteScenarioTemplateMutation,
  useGetAllScenarioTemplateQuery,
} from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Link, Spinner } from '../../../components/Elements';
import React from 'react';
import { toast } from 'react-hot-toast/headless';
import ButModal from '../../../components/Modal/ButModal';

type ListTemplateProps = {
  type?: 'BOM' | 'BASIC';
};

function ListTemplate(props: ListTemplateProps) {
  const { type } = props;

  const { data, error, isLoading } = useGetAllScenarioTemplateQuery({ type });

  const [deleteScenarioTemplate] = useDeleteScenarioTemplateMutation();

  const handleDelete = async (scenarioTemplateId: string) => {
    const response: any = await deleteScenarioTemplate({ scenarioTemplateId });

    if (response.error) {
      // console.log(response.error);
      toast.error(`Failed to delete scenario: ${response.error.data.message}`);
    } else {
      toast.success('Scenario deleted successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center mx-5" />;
  }

  return (
    <div className="bg-white shadow rounded">
      <ul className="divide-y divide-gray-200">
        {!!data?.length ? (
          Object.values(data).map((template: ScenarioTemplate | undefined) => {
            if (!template) {
              return null;
            }
            return (
              <li key={template.id}>
                <div className="grid grid-cols-12">
                  <Link
                    to={`/app/templates/${template.type}/${template.id}`}
                    className="block col-span-10 hover:bg-blue-50"
                  >
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="truncate text-sm font-medium text-blue-600">{template?.name}</p>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="flex items-center text-sm text-gray-500">{template.description}</p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <p>{new Date(template.created as string).toDateString()}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <ButModal
                    label={<RiDeleteBinLine className="h-6 w-6" />}
                    submitLabel={'Yes'}
                    confirm={() => handleDelete(template.id as string)}
                    className={'flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none'}
                  >
                    <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
                      Are you sure you want to delete the "{template?.name}" scenario template?{' '}
                    </h2>
                  </ButModal>
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No {type} Templates</h3>
          </div>
        )}
      </ul>
    </div>
  );
}

export default ListTemplate;
