import { lightweightOlcaCoreApi as api } from './lightweightOlcaCoreApi';

export const enhancedLightweightOlcaCoreApi = api.enhanceEndpoints({
  addTagTypes: ['ModeledProcess', 'Tree', 'ImpactMethod'],
  endpoints: {
    getAllTreesInfo: {
      providesTags: (result, error, arg) => [{ type: 'Tree', id: 'LIST' }],
    },
    getTreeInfo: {
      providesTags: (result, error, arg) => [{ type: 'Tree', id: arg.treeId }],
    },
    deleteTree: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Tree', id: 'LIST' },
        { type: 'Tree', id: arg.treeId },
      ],
    },
    getAllImpactMethods: {
      providesTags: (result, error, arg) => [{ type: 'ImpactMethod', id: 'LIST' }],
    },
    getAllImpactMethodByRefId: {
      providesTags: (result, error, arg) => [{ type: 'ImpactMethod', id: 'LIST' }],
    },
    getImpactMethodById: {
      providesTags: (result, error, arg) => [{ type: 'ImpactMethod', id: arg.impactMethodId }],
    },
    deleteImpactMethod: {
      invalidatesTags: (result, error, arg) => [
        { type: 'ImpactMethod', id: 'LIST' },
        { type: 'ImpactMethod', id: arg.impactMethodId },
      ],
    },
    updateImpactMethod: {
      invalidatesTags: (result, error, arg) => [
        { type: 'ImpactMethod', id: 'LIST' },
        { type: 'ImpactMethod', id: arg.impactMethod.id },
      ],
    },
    customizeImpactMethod: {
      invalidatesTags: (result, error, arg) => [{ type: 'ImpactMethod', id: 'LIST' }],
    },
    postModeledProcess: {
      invalidatesTags: (result, error, arg) => [{ type: 'Tree', id: 'LIST' }],
    },
    postModeledProcessList: {
      invalidatesTags: (result, error, arg) => [{ type: 'Tree', id: 'LIST' }],
    },
    postImpactMethod: {
      invalidatesTags: (result, error, arg) => [{ type: 'ImpactMethod', id: 'LIST' }],
    },
    postImpactMethodList: {
      invalidatesTags: (result, error, arg) => [{ type: 'ImpactMethod', id: 'LIST' }],
    },
  },
});
