import { ScenarioDto } from '../../../api/project/projectApi';
import { DefaultProps } from '../../../components/carousel/Carousel';
import classNames from '../../../utils/classNames';
import ActionMenu, { MenuItem } from './ActionMenu';
import ScenarioTagSelector from './ScenarioTagSelector';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { PiChartPieSliceDuotone } from 'react-icons/pi';

type CustomizationNavBarProps = {
  scenario: ScenarioDto;
  items: MenuItem[];
  assessmentItem: MenuItem;
} & DefaultProps;

export default function CustomizationNavBar(props: CustomizationNavBarProps) {
  const { scenario, items, assessmentItem, className } = props;

  return (
    <div className={classNames(className ? className : '', 'bg-white flex')}>
      <ScenarioTagSelector scenario={scenario} />

      <PiChartPieSliceDuotone
        className="h-8 w-8 z-50 text-blue-700 bg-blue-100 mr-2 rounded cursor-pointer p-1 hover:bg-blue-200 ml-auto"
        onClick={assessmentItem.onClick}
        id={'assessment'}
      />

      <ActionMenu className="" items={items} />

      <Tooltip
        anchorSelect={'#assessment'}
        place={'right'}
        content={'Execute Assessments'}
        className={'z-20'}
        style={{ backgroundColor: 'white', color: 'rgb(29, 78, 213)', padding: '5px' }}
        border="1px solid rgb(29, 78, 213)"
      />
    </div>
  );
}
