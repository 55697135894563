import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import { FormulaParameter, Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import { evaluate } from 'mathjs';

export function evaluateParameterFormula(
  parameters: Parameter[],
  parameterName: string,
): { error: string | undefined; value: any } {
  // console.log("Scope: ",parameters)
  if (!parameters) return { error: undefined, value: undefined };

  const parameter = parameters?.find((p) => p?.parameterName === parameterName);

  if (!parameter) {
    console.warn('ParameterName: ', parameterName, ' not found in parameters: ', parameters);
    return { error: `ParameterName: ${parameterName} not found in parameters`, value: undefined };
  }

  if (ParameterType[parameter.parameterType] !== ParameterType.FORMULA) {
    return { value: parameter.value, error: undefined };
  }

  const formulaParameter = parameter as FormulaParameter;

  const formulaScope = {};

  const relevantParameters: any = parameters?.filter((p) => ParameterType[p.parameterType] !== ParameterType.FORMULA);

  relevantParameters?.forEach((p) => {
    if (p.parameterName) {
      formulaScope[p.parameterName.replaceAll(':', '_')] = p.value ?? 0;
    }
  });

  formulaParameter.dependsOn?.map((d) => {
    const dependence = parameters.find((p) => p.parameterName === d.parameterName);
    if (dependence) {
      if (ParameterType[dependence.parameterType] === ParameterType.FORMULA) {
        formulaScope[dependence.parameterName.replaceAll(':', '_')] =
          evaluateParameterFormula(parameters, dependence.parameterName)?.value ?? 0;
      }
    } else {
      console.warn(
        'ParameterName: ',
        d.parameterName,
        ' in Formula: ',
        formulaParameter?.formula,
        ' not found with parameters: ',
        parameters,
      );
    }
  });

  try {
    return {
      value: evaluate(formulaParameter.formula?.replaceAll(':', '_') as string, formulaScope),
      error: undefined,
    };
  } catch (e: any) {
    console.warn(
      'ParameterName: ',
      parameterName,
      ' Formula: ',
      formulaParameter?.formula?.replaceAll(':', '_'),
      ' with scope',
      formulaScope,
      'Error:',
      e.message,
    );
    return { value: undefined, error: e.message };
  }
}
