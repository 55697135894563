import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactElement, useMemo } from 'react';
import { HiXMark } from 'react-icons/hi2';
import classNames from '../../utils/classNames';

type SlidePanelProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  label?: string | ReactElement;
  description?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

const sizes = {
  sm: 'w-1/4',
  md: 'w-1/3',
  lg: 'w-1/2',
  xl: 'w-4/6',
};

function SlidePanel(props: SlidePanelProps) {
  const { children, open, setOpen, label, description, size } = props;

  const calculatedSize = useMemo(() => (size ? sizes[size] : sizes['md']), [size]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={classNames(calculatedSize, 'pointer-events-none fixed inset-y-0 right-0 flex pl-10 sm:pl-16')}
              // style={{ width: 'calc(60vw/var(--zoom))' }}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-full  overflow-scroll bg-white">
                  <div className="flex flex-col h-full">
                    {/* Header */}
                    <div className="bg-gray-300 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {label}
                          </Dialog.Title>
                          <p className="text-sm text-gray-500">{description}</p>
                        </div>
                        <div className="absolute right-3 top-">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <HiXMark className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {children ? (
                      children
                    ) : (
                      <div className="text-sm bg-gray-100" style={{ maxHeight: 'calc(100vh/var(--zoom))' }}>
                        {children}
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SlidePanel;
