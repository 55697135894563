import { emptyAuthenticationManagerApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    rootHealthGet: build.query<RootHealthGetApiResponse, RootHealthGetApiArg>({
      query: () => ({ url: `/health` }),
    }),
    loginAuthPost: build.mutation<LoginAuthPostApiResponse, LoginAuthPostApiArg>({
      query: (queryArg) => ({
        url: `/auth`,
        method: 'POST',
        body: queryArg.authenticationDto,
      }),
    }),
    authRefreshTokenRefreshTokenPost: build.mutation<
      AuthRefreshTokenRefreshTokenPostApiResponse,
      AuthRefreshTokenRefreshTokenPostApiArg
    >({
      query: (queryArg) => ({
        url: `/refresh-token`,
        method: 'POST',
        body: queryArg.refreshTokenDto,
      }),
    }),
    logoutLogoutPost: build.mutation<LogoutLogoutPostApiResponse, LogoutLogoutPostApiArg>({
      query: () => ({ url: `/logout`, method: 'POST' }),
    }),
    getAllowedRealmsUserRealmGet: build.query<
      GetAllowedRealmsUserRealmGetApiResponse,
      GetAllowedRealmsUserRealmGetApiArg
    >({
      query: (queryArg) => ({
        url: `/user-realm`,
        params: { username: queryArg.username },
      }),
    }),
    setDefaultRealmDefaultRealmPost: build.mutation<
      SetDefaultRealmDefaultRealmPostApiResponse,
      SetDefaultRealmDefaultRealmPostApiArg
    >({
      query: (queryArg) => ({
        url: `/default-realm`,
        method: 'POST',
        body: queryArg.defaultRealmDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authenticationManagerApi };
export type RootHealthGetApiResponse = /** status 200 Successful Response */ any;
export type RootHealthGetApiArg = void;
export type LoginAuthPostApiResponse = /** status 200 Successful Response */ AuthenticationResponseDto;
export type LoginAuthPostApiArg = {
  authenticationDto: AuthenticationDto;
};
export type AuthRefreshTokenRefreshTokenPostApiResponse = /** status 200 Successful Response */ any;
export type AuthRefreshTokenRefreshTokenPostApiArg = {
  refreshTokenDto: RefreshTokenDto;
};
export type LogoutLogoutPostApiResponse = /** status 200 Successful Response */ any;
export type LogoutLogoutPostApiArg = void;
export type GetAllowedRealmsUserRealmGetApiResponse = /** status 200 Successful Response */ AllowedRealmsResponseDto;
export type GetAllowedRealmsUserRealmGetApiArg = {
  username: string;
};
export type SetDefaultRealmDefaultRealmPostApiResponse = /** status 200 Successful Response */ any;
export type SetDefaultRealmDefaultRealmPostApiArg = {
  defaultRealmDto: DefaultRealmDto;
};
export type AuthenticationResponseDto = {
  access_token: string;
  roles: string[];
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AuthenticationDto = {
  username: string;
  password: string;
  realm?: string;
};
export type RefreshTokenDto = {
  username: string;
  realm?: string;
};
export type AllowedRealmsResponseDto = {
  default_realm: string;
  allowed_realms: string[];
};
export type DefaultRealmDto = {
  username: string;
  default_realm: string;
};
export const {
  useRootHealthGetQuery,
  useLoginAuthPostMutation,
  useAuthRefreshTokenRefreshTokenPostMutation,
  useLogoutLogoutPostMutation,
  useGetAllowedRealmsUserRealmGetQuery,
  useSetDefaultRealmDefaultRealmPostMutation,
} = injectedRtkApi;
