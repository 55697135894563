import { useNavigate } from 'react-router-dom';
import { Tag as TagDto, usePostTagMutation } from '../../../api/project/projectApi';
import { TagForm } from './TagForm';
import toast from 'react-hot-toast/headless';

function CreateTag() {
  const history = useNavigate();
  const [postTag] = usePostTagMutation();

  const onSubmit = async (tag: TagDto) => {
    const newTag: any = await postTag({ tag: tag as TagDto }).unwrap();
    if (newTag.error) {
      // console.log(newTag.error);
      toast.error('Failed to create tag');
    } else {
      toast.success('Tag created successfully');
      history(`../`);
    }
  };

  return <TagForm onSubmit={onSubmit} formMode={'CREATE'} />;
}

export default CreateTag;
