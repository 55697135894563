import { Fragment, useState } from 'react';
import { DisplayConfiguration, useGetScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import CustomizationTemplateForm, { IndexedParameter } from './CustomizationTemplateForm';
import useRenderCounter from '../../../hooks/useRenderCounter';

type BomItemCustomizationProps = {
  parameters: IndexedParameter[] | undefined;
  scenarioTemplateId: string;
  autoFetch?: () => void;
  fromComparison: boolean;
};

const BomItemCustomization = (props: BomItemCustomizationProps) => {
  const { scenarioTemplateId, parameters, autoFetch, fromComparison } = props;

  const [phaseId, setPhaseId] = useState<string>();
  const { data: scenarioTemplate } = useGetScenarioTemplateQuery({
    scenarioTemplateId: scenarioTemplateId as string,
  });
  const renderCounter = useRenderCounter();

  return (
    <Fragment>
      {renderCounter}
      {scenarioTemplate ? (
        <CustomizationTemplateForm
          changePhase={setPhaseId}
          currentPhaseId={phaseId}
          onFormChange={() => autoFetch && autoFetch()}
          parameters={parameters}
          displayConfigurations={scenarioTemplate.displayConfigurations as DisplayConfiguration[]}
          fromComparison={fromComparison}
        />
      ) : null}
    </Fragment>
  );
};

export default BomItemCustomization;
