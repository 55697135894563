import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import { Parameter } from '../../../api/scenarioTemplate/scenarioTemplateApi';

export function evaluateWithScope(scope: any, script: string) {
  if (scope && script) {
    return Function('"use strict";return (' + script + ')').bind(scope)();
  }
  return undefined;
}

export const evaluateScriptWithScope = (
  script: string | undefined,
  parameters: Parameter[],
  parameterName?: string,
) => {
  if (!script) {
    return true;
  }

  const localScope = {};

  const relevantParameters = parameters?.filter((p) => ParameterType[p.parameterType] !== ParameterType.FORMULA);

  relevantParameters?.forEach((p) => {
    localScope[p?.parameterName?.replaceAll(':', '_')] = p.value;
  });

  let result = false;
  try {
    result = evaluateWithScope(localScope, script?.replaceAll(':', '_'));
  } catch (e) {
    console.error(e, 'with local scope: ', localScope, ' and script: ', script, parameterName);
  }
  return result;
};
