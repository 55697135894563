import { IndexedParameter } from '../components/CustomizationTemplateForm';

export function findParameter(
  errorParameters: IndexedParameter[],
  phase: string | undefined,
  tab: string,
): IndexedParameter | undefined {
  let parameter = errorParameters.find((ep) => ep.display?.phaseId === phase && ep.display?.tab === tab);
  if (!parameter) {
    parameter = errorParameters.find((ep) => ep.display?.phaseId === phase);
  }
  if (!parameter) {
    parameter = errorParameters[0];
  }

  return parameter;
}
