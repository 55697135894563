import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { LIGHTWEIGHT_CALCULATION_ENGINE_API_URL } from '../../config';
import { baseQueryWithReauth } from '../utility/queryUtility';
import storage from '../../utils/storage';

const customBaseQuery = fetchBaseQuery({
  baseUrl: LIGHTWEIGHT_CALCULATION_ENGINE_API_URL,
  // mode: 'no-cors',
  prepareHeaders: (headers, { getState }) => {
    const token = storage.getToken();

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptyLightweightCalculationEngineApi = createApi({
  reducerPath: 'lightweightcalculationengine',
  baseQuery: (args, api, extraOptions) => {
    return baseQueryWithReauth(args, customBaseQuery, api, extraOptions);
  },
  endpoints: () => ({}),
});

// Documentation for code generation
//https://redux-toolkit.js.org/rtk-query/usage/code-generation
// npx @rtk-query/codegen-openapi openapi-lightweightcalculationengine-config.json
