import { useEffect } from 'react';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListRemoteFlow from '../components/ListRemoteFlow';

export const RemoteFlows = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'remote flows' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <ListRemoteFlow />
    </div>
  );
};
