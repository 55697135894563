import { Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import { VariableConfiguration } from '../../../api/scenarioTemplate/scenarioTemplateApi';

export const createBasicCustomizationPayload = (
  parameters: Parameter[],
  variableConfigurations: VariableConfiguration[] | undefined,
) => {
  const result = [];
  // console.log('Parameters: ', parameters, '  scenario template: ', variableConfigurations);
  parameters.forEach((p) => {
    if (p.parameterType === 'LIST') {
      p.value = JSON.stringify(p.value);
    }
    if (p.type === 'AMOUNT_CHANGE') {
      if (p.parameterType === 'REMOTE') {
        result.push(p);
      } else {
        const variable = variableConfigurations?.find((v) => v.parameterName === p.parameterName);
        if (variable) {
          //Linking parameter to the correct process parameter (variable)
          result.push({
            ...p,
            parameterName: variable.name,
          });
        } else {
          console.warn('Skipping ', p.parameterName, ' because no variable configuration was found.');
        }
      }
    } else {
      // FLOW_SWITCH and ADDITIONAL_PARAMETER
      result.push(p);
    }
  });

  // console.log({ payload });
  return result;
};
