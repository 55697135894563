import { customizationSpaceApi as api } from './customizationSpaceApi';

export const enhancedCustomizationSpaceApi = api.enhanceEndpoints({
  addTagTypes: ['CustomizationSpace'],
  endpoints: {
    getCustomizationSpaces: {
      providesTags: (result, error, arg) => [{ type: 'CustomizationSpace', id: 'LIST' }],
    },
    getByCustomizationSpaceRefId: {
      providesTags: (result, error, arg) => [{ type: 'CustomizationSpace', id: result?.id }],
    },
    deleteCustomizationSpace: {
      invalidatesTags: (result, error, arg) => [
        { type: 'CustomizationSpace', id: arg.customizationSpaceId },
        { type: 'CustomizationSpace', id: 'LIST' },
      ],
    },
    upsertCustomizationSpace: {
      invalidatesTags: (result, error, arg) => [{ type: 'CustomizationSpace', id: 'LIST' }],
    },
  },
});
