function Col({ span, children }: any) {
  let colSpan = 'col-span-12';
  switch (span) {
    case 4:
      colSpan = 'col-span-4';
      break;
    case 6:
      colSpan = 'col-span-6';
      break;
  }

  return <div className={colSpan}>{children}</div>;
}

export default Col;
