import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import CustomizationTemplateForm from '../../../customization-process/components/CustomizationTemplateForm';
import { CustomizationTemplateProvider } from '../../../customization-process/hooks/CustomizationTemplateProvider';
import { Button } from '../../../../components/Elements';
import { createBasicCustomizationPayload } from '../../../customization-process/utils/createBasicCustomizationPayload';
import { scenarioTemplateSteps } from './ScenarioTemplate';
import GuidedNavigation from '../../../../components/navigation/GuidedNavigation';

function ScenarioTemplateLayout() {
  const navigate = useNavigate();
  const { state } = useStateMachine({ initScenarioTemplateAction });

  const [currentPhase, setCurrentPhase] = useState(state.template?.phaseConfigurations?.[0]?.phaseId as string);

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    }
  }, []);

  const methods = useForm({
    defaultValues: {
      ...state.template,
      parameters: state.template?.parameters.map((p, index) => ({
        ...p,
        index,
      })),
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    formState: { errors },
    getValues,
  } = methods;

  const testPayload = () => {
    const parameters = createBasicCustomizationPayload(getValues().parameters, state.template);
    console.log(parameters);
  };

  const handleNavigate = (href: string) => {
    navigate(href);
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleNavigate(href)}
        className="my-2"
      />
      <CustomizationTemplateProvider identifier={'test'}>
        {state.template && (
          <CustomizationTemplateForm
            displayConfigurations={state.template.displayConfigurations}
            parameters={state.template.parameters.map((p, index) => ({
              ...p,
              index,
            }))}
            currentPhaseId={currentPhase}
            changePhase={setCurrentPhase}
            onFormChange={() => null}
            fromComparison={false}
          />
        )}

        <Button
          className="mt-2 w-auto disabled:bg-gray-500 bg-green-500 mb-6 hover:bg-green-400"
          disabled={Object.keys(errors).length > 0}
          onClick={testPayload}
        >
          Execute Assessment
        </Button>
      </CustomizationTemplateProvider>
    </FormProvider>
  );
}

export default ScenarioTemplateLayout;
