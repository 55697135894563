import React, { useContext, useState } from 'react';

type ContextProps = {
  templateIdentifier: string | undefined;
  identifier: string;
  phases: string[];
  displayErrors: boolean;
  phaseRendered: (phaseId: string) => void;
  setDisplayErrors: (displayStatus: boolean) => void;
};

const CustomizationTemplateContext = React.createContext<ContextProps>({
  templateIdentifier: undefined,
  identifier: '',
  phaseRendered: () => {},
  phases: [],
  displayErrors: false,
  setDisplayErrors: () => {},
});

export const CustomizationTemplateProvider = ({
  initialTemplateIdentifier = undefined,
  identifier,
  children,
}: {
  initialTemplateIdentifier?: string;
  identifier: string;
  children: any;
}) => {
  const [templateIdentifier] = useState(initialTemplateIdentifier);
  const [formIdentifier, setFormIdentifier] = useState(identifier);
  const [phases, setPhases] = useState<string[]>([]);
  const [displayErrors, setDisplayErrors] = useState(false);

  const phaseRendered = (phaseId: string) => {
    if (phases.indexOf(phaseId) === -1) {
      setPhases((prevValue) => [...prevValue, phaseId]);
    }
  };

  return (
    <CustomizationTemplateContext.Provider
      value={{
        templateIdentifier,
        phases,
        displayErrors,
        setDisplayErrors,
        phaseRendered,
        identifier: formIdentifier,
      }}
    >
      {children}
    </CustomizationTemplateContext.Provider>
  );
};

export const useCustomizationTemplateContext = () => {
  const { templateIdentifier, identifier, phases, displayErrors, setDisplayErrors, phaseRendered } =
    useContext(CustomizationTemplateContext);
  return {
    templateIdentifier,
    identifier,
    phases,
    displayErrors,
    setDisplayErrors,
    phaseRendered,
  };
};
