import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import { ImpactMethodForm } from './ImpactMethodForm';
import { toast } from 'react-hot-toast/headless';
import {
  ImpactMethod as ImpactMethodDto,
  useGetImpactMethodByIdQuery,
  useUpdateImpactMethodMutation,
} from '../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';

function ImpactMethod() {
  const { impactMethodId } = useParams();
  const { data: impactMethod, isLoading } = useGetImpactMethodByIdQuery(
    impactMethodId ? { impactMethodId: impactMethodId } : skipToken,
  );
  const [updateImpactMethod] = useUpdateImpactMethodMutation();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (impactMethod) {
      setBreadcrumbs([{ label: 'projects', path: '/app/projects' }, { label: impactMethod.name as string }]);
    }
  }, []);

  const onSubmit = async (impactMethod: ImpactMethodDto) => {
    const updatedImpactMethod: any = await updateImpactMethod({
      impactMethod: impactMethod,
    }).unwrap();
    if (updatedImpactMethod.error) {
      // console.log(updatedImpactMethod.error);
      toast.error('Failed to update impact method');
    } else {
      toast.success('Impact method updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <>
      {impactMethod && (
        <ImpactMethodForm
          impactMethod={impactMethod}
          onSubmit={onSubmit}
          onError={(errors) => toast.error('Errors in configuration')}
          submitLabel={'Update'}
          formMode={'EDIT'}
        />
      )}
    </>
  );
}

export default ImpactMethod;
