import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { refreshToken } from './refreshToken';
import storage from '../../utils/storage';

export const baseQueryWithReauth = async <T extends string | FetchArgs, U>(
  args: T,
  customBaseQueryFn: any,
  api: any, // Replace 'any' with your actual API type
  extraOptions?: any, // Replace 'any' with your actual extra options type
) => {
  let result = await customBaseQueryFn(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    try {
      // Dispatch the refreshTokenAction to refresh the token
      const username: string = storage.getUsername() || '';
      const realm: string = storage.getTargetRealm() || '';
      const success = await refreshToken(username, realm);

      if (success) {
        // Pass the baseUrl to customBaseQueryFn
        return await customBaseQueryFn(args, api, { ...extraOptions });
      }
    } catch (error) {
      // Handle token refresh error here
      throw error;
    }
  }

  return result;
};
