import { useEffect, useState } from 'react';
import { Button, Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import ListImpactMethods from '../components/ListImpactMethods';
import { InputText } from '../../../components/input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';

export const ImpactMethods = () => {
  const [searchImpactMethodName, setSearchImpactMethodName] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'impact methods' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          defaultValue={searchImpactMethodName}
          value={searchImpactMethodName}
          onChange={(e) => setSearchImpactMethodName(e.target.value)}
        />
      </div>

      <ListImpactMethods filter={{ impactMethodName: searchImpactMethodName }} />
    </div>
  );
};
