import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../hooks/breadcrumbs';
import { toast } from 'react-hot-toast/headless';
import { IntegrationForm } from './IntegrationForm';
import {
  PayloadType,
  RemoteFlow,
  useGetAllPayloadTypesQuery,
  useGetAllRemoteFlowsQuery,
  useGetIntegrationTemplateQuery,
  usePostIntegrationTemplateMutation,
} from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ScenarioTemplate, useGetAllScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';

function Integration() {
  const { integrationTemplateId } = useParams();

  const { data: integration, isLoading: isIntegrationLoading } = useGetIntegrationTemplateQuery(
    integrationTemplateId ? { integrationTemplateId } : skipToken,
  );
  const { data: scenarioTemplates, isLoading: areScenarioTemplatesLoading } = useGetAllScenarioTemplateQuery({});
  const { data: flows, isLoading: areFlowsLoading } = useGetAllRemoteFlowsQuery({});
  const { data: payloadTypes, isLoading: arePayloadTypesLoading } = useGetAllPayloadTypesQuery({});
  const [postIntegrationTemplate] = usePostIntegrationTemplateMutation();

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'integrations', path: '/app/integrations' }, { label: `${integration?.name}` }]);
  }, [integration]);

  const onSubmit = async (scenario: any) => {
    // console.log({ scenario });
    scenario.tags = scenario.tags?.map((t: any) => ({ id: t }));
    const updatedScenario: any = await postIntegrationTemplate({
      integrationTemplate: scenario,
    }).unwrap();
    if (updatedScenario.error) {
      // console.log(updatedScenario.error);
      toast.error('Failed to update scenario');
    } else {
      toast.success('Scenario updated successfully');
    }
  };

  if (areFlowsLoading || isIntegrationLoading || arePayloadTypesLoading || areScenarioTemplatesLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  console.log({ integration });

  return (
    <IntegrationForm
      onSubmit={onSubmit}
      initialValues={integration}
      submitLabel={'Update'}
      payloadTypes={payloadTypes as PayloadType[]}
      flows={flows as RemoteFlow[]}
      scenarioTemplates={scenarioTemplates as ScenarioTemplate[]}
    />
  );
}

export default Integration;
