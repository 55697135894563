import { BasicScenarioTemplate, BomScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Bom } from '../../../api/bom/bomApi';

export const checkBomImportMapping = (importJson: string, scenarioTemplates: BasicScenarioTemplate[], boms: Bom[]) => {
  const mapping: any[] = [];
  console.log(importJson);
  const parseBomScenarioTemplate: BomScenarioTemplate = JSON.parse(importJson);

  console.log(parseBomScenarioTemplate);

  if (!boms.some((b) => b.id === parseBomScenarioTemplate.bomId)) {
    mapping.push({
      type: 'BOM',
      id: parseBomScenarioTemplate.bomId,
      name: `Select new bom`,
    });
  }

  parseBomScenarioTemplate.itemScenarioTemplateConfiguration?.forEach((ist: any) => {
    if (!scenarioTemplates.some((st) => st.id === ist.scenarioTemplateId)) {
      mapping.push({
        type: 'SCENARIO_TEMPLATE',
        id: ist.scenarioTemplateId,
        name: `item: ${ist.itemId}`,
      });
    }
  });

  return mapping;
};
