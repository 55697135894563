import { remoteDispatcherApi as api } from './remoteDispatcherApi';

export const enhancedRemoteDispatcherApi = api.enhanceEndpoints({
  addTagTypes: ['RemoteDispatcher'],
  endpoints: {
    getAllRemoteFlows: {
      providesTags: (result, error, arg) => [{ type: 'RemoteDispatcher', id: 'LIST' }],
    },
    postRemoteFlow: {
      invalidatesTags: (result, error, arg) => [
        { type: 'RemoteDispatcher', id: 'LIST' },
        { type: 'RemoteDispatcher', id: result?.id },
      ],
    },
    deleteRemoteFlowByFlowId: {
      invalidatesTags: (result, error, arg) => [{ type: 'RemoteDispatcher', id: 'LIST' }],
    },
    getAllIntegrationTemplate: {
      providesTags: (result, error, arg) => [{ type: 'RemoteDispatcher', id: 'LIST' }],
    },
    deleteIntegrationTemplate: {
      invalidatesTags: (result, error, arg) => [
        { type: 'RemoteDispatcher', id: 'LIST' },
        { type: 'RemoteDispatcher', id: arg?.integrationTemplateId },
      ],
    },
    postIntegrationTemplate: {
      invalidatesTags: (result, error, arg) => [
        { type: 'RemoteDispatcher', id: 'LIST' },
        { type: 'RemoteDispatcher', id: result?.id },
      ],
    },
  },
});
