import { emptyRemoteDispatcherApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPayloadTypes: build.query<GetAllPayloadTypesApiResponse, GetAllPayloadTypesApiArg>({
      query: (queryArg) => ({
        url: `/v1/payload-type/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postPayloadType: build.mutation<PostPayloadTypeApiResponse, PostPayloadTypeApiArg>({
      query: (queryArg) => ({
        url: `/v1/payload-type/`,
        method: 'POST',
        body: queryArg.payloadType,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllIntegrationTemplate: build.query<GetAllIntegrationTemplateApiResponse, GetAllIntegrationTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/integration/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postIntegrationTemplate: build.mutation<PostIntegrationTemplateApiResponse, PostIntegrationTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/integration/`,
        method: 'POST',
        body: queryArg.integrationTemplate,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    invokeFlow: build.mutation<InvokeFlowApiResponse, InvokeFlowApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/invoke/${queryArg.flowId}`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    invokeIntegrationFlow: build.mutation<InvokeIntegrationFlowApiResponse, InvokeIntegrationFlowApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/invoke/integration/${queryArg.flowId}`,
        method: 'POST',
        body: queryArg.remotePayloadDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllRemoteFlows: build.query<GetAllRemoteFlowsApiResponse, GetAllRemoteFlowsApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postRemoteFlow: build.mutation<PostRemoteFlowApiResponse, PostRemoteFlowApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/`,
        method: 'POST',
        body: queryArg.remoteFlow,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getIntegrationTemplate: build.query<GetIntegrationTemplateApiResponse, GetIntegrationTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/integration/${queryArg.integrationTemplateId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteIntegrationTemplate: build.mutation<DeleteIntegrationTemplateApiResponse, DeleteIntegrationTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/integration/${queryArg.integrationTemplateId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getIntegrationTemplateForScenarioTemplates: build.query<
      GetIntegrationTemplateForScenarioTemplatesApiResponse,
      GetIntegrationTemplateForScenarioTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/integration/scenario-template`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { scenarioTemplateIds: queryArg.scenarioTemplateIds },
      }),
    }),
    getIntegrationTemplateForScenarioTemplate: build.query<
      GetIntegrationTemplateForScenarioTemplateApiResponse,
      GetIntegrationTemplateForScenarioTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/integration/scenario-template/${queryArg.scenarioTemplateId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getRemoteFlowByFlowId: build.query<GetRemoteFlowByFlowIdApiResponse, GetRemoteFlowByFlowIdApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/${queryArg.flowId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteRemoteFlowByFlowId: build.mutation<DeleteRemoteFlowByFlowIdApiResponse, DeleteRemoteFlowByFlowIdApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/${queryArg.flowId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getNodeRedFlows: build.query<GetNodeRedFlowsApiResponse, GetNodeRedFlowsApiArg>({
      query: (queryArg) => ({
        url: `/v1/flows/nodered`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deletePayloadType: build.mutation<DeletePayloadTypeApiResponse, DeletePayloadTypeApiArg>({
      query: (queryArg) => ({
        url: `/v1/payload-type/${queryArg.payloadTypeId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as remoteDispatcherApi };
export type GetAllPayloadTypesApiResponse = /** status 200 OK */ PayloadType[];
export type GetAllPayloadTypesApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostPayloadTypeApiResponse = /** status 200 OK */ PayloadType;
export type PostPayloadTypeApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  payloadType: PayloadType;
};
export type GetAllIntegrationTemplateApiResponse = /** status 200 OK */ IntegrationTemplate[];
export type GetAllIntegrationTemplateApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostIntegrationTemplateApiResponse = /** status 200 OK */ IntegrationTemplate;
export type PostIntegrationTemplateApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  integrationTemplate: IntegrationTemplate;
};
export type InvokeFlowApiResponse = /** status 200 OK */ number;
export type InvokeFlowApiArg = {
  flowId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    [key: string]: string;
  };
};
export type InvokeIntegrationFlowApiResponse = /** status 200 OK */ GenericResponse;
export type InvokeIntegrationFlowApiArg = {
  flowId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  remotePayloadDto: RemotePayloadDto;
};
export type GetAllRemoteFlowsApiResponse = /** status 200 OK */ RemoteFlow[];
export type GetAllRemoteFlowsApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostRemoteFlowApiResponse = /** status 200 OK */ RemoteFlow;
export type PostRemoteFlowApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  remoteFlow: RemoteFlow;
};
export type GetIntegrationTemplateApiResponse = /** status 200 OK */ IntegrationTemplate;
export type GetIntegrationTemplateApiArg = {
  integrationTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteIntegrationTemplateApiResponse = /** status 200 OK */ GenericResponse;
export type DeleteIntegrationTemplateApiArg = {
  integrationTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetIntegrationTemplateForScenarioTemplatesApiResponse = /** status 200 OK */ IntegrationTemplate[];
export type GetIntegrationTemplateForScenarioTemplatesApiArg = {
  scenarioTemplateIds: string[];
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetIntegrationTemplateForScenarioTemplateApiResponse = /** status 200 OK */ IntegrationTemplate[];
export type GetIntegrationTemplateForScenarioTemplateApiArg = {
  scenarioTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetRemoteFlowByFlowIdApiResponse = /** status 200 OK */ RemoteFlow;
export type GetRemoteFlowByFlowIdApiArg = {
  flowId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteRemoteFlowByFlowIdApiResponse = /** status 200 OK */ GenericResponse;
export type DeleteRemoteFlowByFlowIdApiArg = {
  flowId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetNodeRedFlowsApiResponse = /** status 200 OK */ FlowDto[];
export type GetNodeRedFlowsApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeletePayloadTypeApiResponse = /** status 200 OK */ GenericResponse;
export type DeletePayloadTypeApiArg = {
  payloadTypeId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PayloadType = {
  id?: string;
  type?: string;
};
export type IntegrationTemplateScenarioTemplate = {
  id?: string;
  integrationTemplate?: IntegrationTemplate;
  scenarioTemplateId?: string;
};
export type IntegrationTemplate = {
  id?: string;
  name?: string;
  label?: string;
  description?: string;
  flowId?: string;
  allowImpactsCustomization?: boolean;
  payload?: PayloadType[];
  integrationTemplateScenarioTemplate?: IntegrationTemplateScenarioTemplate[];
};
export type GenericResponse = {
  message?: string;
  details?: string;
  data?: object;
};
export type RemotePayloadItemDto = {
  id?: string;
  type?: string;
};
export type RemotePayloadItemFileDto = RemotePayloadItemDto & {
  value?: string;
};
export type RemotePayloadDto = {
  payload?: (RemotePayloadItemDto | RemotePayloadItemFileDto)[];
};
export type RemoteFlow = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  flowId?: string;
  flowName?: string;
  endpoint?: string;
};
export type FlowDto = {
  id?: string;
  name?: string;
  endpoint?: string;
  disabled?: boolean;
  description?: string;
};
export const {
  useGetAllPayloadTypesQuery,
  usePostPayloadTypeMutation,
  useGetAllIntegrationTemplateQuery,
  usePostIntegrationTemplateMutation,
  useInvokeFlowMutation,
  useInvokeIntegrationFlowMutation,
  useGetAllRemoteFlowsQuery,
  usePostRemoteFlowMutation,
  useGetIntegrationTemplateQuery,
  useDeleteIntegrationTemplateMutation,
  useGetIntegrationTemplateForScenarioTemplatesQuery,
  useGetIntegrationTemplateForScenarioTemplateQuery,
  useGetRemoteFlowByFlowIdQuery,
  useDeleteRemoteFlowByFlowIdMutation,
  useGetNodeRedFlowsQuery,
  useDeletePayloadTypeMutation,
} = injectedRtkApi;
