import React from 'react';
import { PhaseDto } from '../../../../api/project/projectApi';
import { TreeInfoDto } from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { useForm } from 'react-hook-form';
import { InputList } from '../../../../components/input/InputList';
import { SelectControl } from '../../../../components/input';
import { Button } from '../../../../components/Elements';

type PhaseTreeMappingProps = {
  phases: PhaseDto[];
  trees: TreeInfoDto[];
  importMapping: any[];
  importJson: string;
  onImportJsonChange: (importJson: any, close: boolean) => void;
};

function PhaseTreeMapping(props: PhaseTreeMappingProps) {
  const { importMapping, trees, phases, importJson, onImportJsonChange } = props;

  const submit = (values: any) => {
    // console.log(values)
    let newJson = importJson;
    values.importMapping.forEach((im) => {
      if (im.value) {
        newJson = newJson.replaceAll(im.id, im.value);
      }
    });
    const missingMappings = values.importMapping.filter((im) => !im.value);
    if (missingMappings.length > 0) {
      reset({ importMapping: missingMappings });
    } else {
      onImportJsonChange(newJson, true);
    }
  };

  const methods = useForm({
    defaultValues: {
      importMapping: importMapping.map((im) => {
        const value =
          im.type === 'PHASE' ? phases.find((t) => t.name === im.name)?.id : trees.find((t) => t.name === im.name)?.id;
        return {
          ...im,
          value,
        };
      }),
    },
  });

  const { control, reset, handleSubmit } = methods;

  // console.log(importMapping);

  return (
    <form onSubmit={handleSubmit(submit, console.error)} className={'flex'}>
      <InputList
        control={control}
        name={'importMapping'}
        renderItem={(field, index) => {
          return (
            <div className="col-span-12 my-2">
              <span>
                TYPE: {field.type} id: {field.id} - name: {field.name}
              </span>
              {field.type === 'PHASE' ? (
                <SelectControl
                  control={control}
                  options={phases.map((p: PhaseDto) => ({
                    value: p.id as string,
                    label: p.name as string,
                  }))}
                  name={`importMapping.${index}.value`}
                />
              ) : (
                <SelectControl
                  control={control}
                  options={trees.map((p: TreeInfoDto) => ({
                    value: p.id as string,
                    label: p.name as string,
                  }))}
                  name={`importMapping.${index}.value`}
                />
              )}
            </div>
          );
        }}
      />
      <Button
        type="submit"
        variant="none"
        className="flex-1 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
      >
        Submitting
      </Button>
    </form>
  );
}

export default PhaseTreeMapping;
