import { array, object, string } from 'yup';
import { SkipTests } from './skipTest';
import { validateParameterExists } from './validateParameterExists';

export const optionsValidation = array()
  .of(
    object().shape({
      label: string().nullable(),
      value: string().nullable(),
      linkedParameters: array()
        .of(
          object().shape({
            parameterName: string().when(['$skipTests'], ([skipTests], schema) =>
              schema
                .test({
                  name: SkipTests.LINKED_PARAMETERS_TEST,
                  message: ({ value }) => `Linked parameter "${value}" was not found among the parameters`,
                  test: (value, test) => {
                    if (skipTests && skipTests.includes(SkipTests.LINKED_PARAMETERS_TEST)) return true;
                    const parameters = test.options.context?.parameters;

                    return validateParameterExists(parameters, value);
                  },
                })
                .nullable(),
            ),
          }),
        )
        .nullable(),
    }),
  )
  .nullable();
