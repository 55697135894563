import { array, object, string } from 'yup';
import { SkipTests } from './skipTest';
import { optionsValidation } from './optionsValidation';
import { dependsOnValidation } from './dependsOnValidation';
import {
  BasicScenarioTemplate,
  BomScenarioTemplate,
  DisplayConfiguration,
  ScenarioTemplate,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { get } from '../../../../utils/objectUtils';
import { evaluateParameterFormula } from '../../../customization-process/utils/evaluateParameterFormula';

export const parametersValidation = array().of(
  object().shape({
    alias: string().nullable(),
    parameterName: string().required('Parameter name is required'),
    parameterType: string().required('Parameter type is required'),
    formula: string().when(['$skipTests'], ([skipTests], schema, options) => {
      if (options.parent.parameterType !== 'FORMULA') {
        return schema.nullable();
      }
      const { value, error } = evaluateParameterFormula(options.context.parameters, options.parent.parameterName);
      // console.log(value, error);
      return schema.test({
        name: SkipTests.FORMULA_TEST,
        message: ({ value }) => error,
        test: (value, test) => {
          if (skipTests && skipTests.includes(SkipTests.FORMULA_TEST)) return true;
          return !error;
        },
      });
      // return schema.nullable()
    }),
    display: object()
      .shape({
        phaseId: string()
          .when(['$skipTests'], ([skipTests], schema, options) => {
            return schema.test({
              name: SkipTests.PARAMETER_DISPLAY_TEST,
              message: ({ value }) => `Parameter display phase is not valid.`,
              test: (value, test) => {
                if (skipTests && skipTests.includes(SkipTests.PARAMETER_DISPLAY_TEST)) return true;
                const displayConfigurations = getDisplayConfigurations(
                  test.options.context as ScenarioTemplate,
                  options['path'],
                );
                // console.log({ displayConfigurations });
                return displayConfigurations?.some((dc) => dc.phaseId === value);
              },
            });
          })
          .nullable(),
        tab: string()
          .when(['$skipTests'], ([skipTests], schema, options) => {
            // console.log(options);
            return schema.test({
              name: SkipTests.PARAMETER_DISPLAY_TEST,
              message: ({ value }) => `Parameter display tab is not valid ${value}`,
              test: (value, test) => {
                // console.log(value);
                if (skipTests && skipTests.includes(SkipTests.PARAMETER_DISPLAY_TEST)) return true;
                const displayConfigurations = getDisplayConfigurations(
                  test.options.context as ScenarioTemplate,
                  options['path'],
                );
                const phaseId = options['parent'].phaseId;
                // console.log(value, 'Config: ', displayConfigurations);
                return displayConfigurations
                  ?.find((dc) => dc.phaseId === phaseId)
                  ?.tabs?.some((tab) => tab.name === value);
              },
            });
          })
          .nullable(),
      })
      .nullable(),
    options: optionsValidation,
    dependsOn: dependsOnValidation,
    flowId: string().when(['$skipTests'], ([skipTests], schema, options) => {
      if ((skipTests && skipTests.includes(SkipTests.REMOTE_FLOW_TEST)) || options.parent.parameterType !== 'REMOTE')
        return schema.nullable();
      return schema.required(`Remote flow is not valid`);
    }),
    rootModeledProcessRefId: string().when(['$skipTests'], ([skipTests], schema, options) => {
      if ((skipTests && skipTests.includes(SkipTests.FLOW_SWITCH_TEST)) || options.parent.type !== 'FLOW_SWITCH')
        return schema.nullable();
      return schema.required(`rootModeledProcessRefId is not valid`);
    }),
    parentRefId: string().when(['$skipTests'], ([skipTests], schema, options) => {
      if ((skipTests && skipTests.includes(SkipTests.FLOW_SWITCH_TEST)) || options.parent.type !== 'FLOW_SWITCH')
        return schema.nullable();
      return schema.required(`parentRefId is not valid`);
    }),
  }),
);

const getDisplayConfigurations = (template: ScenarioTemplate, path: string | undefined) => {
  let displayConfigurations: DisplayConfiguration[] | undefined = undefined;
  if (template.type === 'BOM') {
    // if it's a BoM every item has its own displayConfiguration
    const bomTemplate = template as BomScenarioTemplate;
    const itemDisplay = get(template, path ? path?.split('.')?.slice(0, -1)?.join('.') : undefined);
    let itemScenarioTemplate = bomTemplate.itemScenarioTemplateConfiguration?.find(
      (ist) => `:${ist.parentId}:${ist.itemId}` === itemDisplay?.[0].item,
    );
    // Looking for a specific configuration
    if (!itemScenarioTemplate) {
      itemScenarioTemplate = bomTemplate.itemScenarioTemplateConfiguration?.find(
        (ist) => `:${ist.itemId}` === itemDisplay?.[0].item,
      );
    }
    displayConfigurations = itemScenarioTemplate?.['displayConfigurations'];
  } else {
    displayConfigurations = (template as BasicScenarioTemplate).displayConfigurations;
  }

  return displayConfigurations;
};
