import { HiFolderOpen, HiPlusCircle } from 'react-icons/hi2';
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri';
import { Button, Link, Spinner } from '../../../components/Elements';
import {
  ImpactMethod,
  useDeleteImpactMethodMutation,
  useGetAllImpactMethodsQuery,
  usePostImpactMethodListMutation,
} from '../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast/headless';
import ButModal from '../../../components/Modal/ButModal';
import '../style/style.css';

function ListImpactMethods(props: any) {
  const { filter } = props;

  const {
    data: impactMethods,
    error,
    isLoading,
  } = useGetAllImpactMethodsQuery({
    impactMethodName: filter.searchImpactMethodName,
  });
  const [files, setFiles] = useState<FileList | null>();
  const [deleteImpactMethod] = useDeleteImpactMethodMutation();
  const [postImpactMethod] = usePostImpactMethodListMutation();

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files?.length; i++) {
        formData.append(`impactMethodFiles`, files?.item(i) as File);
      }
      const modeledProcess: any = await postImpactMethod({ body: formData });
      if (modeledProcess.error) {
        // console.log(modeledProcess.error);
        toast.error('Failed to upload impact methods');
      } else {
        toast.success('Impanct methods uploaded successfully');
      }
    }
  };

  const renderFileList = () => (
    <ol className="grid grid-cols-3 bg-blue-300 mx-3 p-2 mt-2 rounded">
      {[...(files ?? [])].map((f: any, i: number) => (
        <li key={i} className="col-span-1 my-1 m-2 rounded bg-white p-2">
          {f.name}
        </li>
      ))}
    </ol>
  );

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded">
      <form onSubmit={onSubmit} className={'mt-3 mx-3 flex'}>
        <div className="flex-1">
          <div className="h-36 w-full relative border-2 rounded border-blue-200 border-dashed border-r-0">
            <div className={' absolute h-36 w-full'}>
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">.impactMethod files (MAX. 20Mb)</p>
              </div>
            </div>
            <input
              className="absolute h-36 w-full z-10 bg-transparent text-transparent"
              accept="zip/*"
              type="file"
              onChange={(e) => setFiles(e.target.files)}
              multiple
            />
          </div>
        </div>
        <Button className="h-36 rounded-l-none" type="submit" value="Upload">
          Upload
        </Button>
      </form>
      {files ? <div>{renderFileList()}</div> : null}
      <ul className="divide-y divide-gray-200 mt-3">
        <div className="relative grid grid-cols-12 flex border border-gray-400 border-dashed border-spacing-2">
          <Link
            to={'./new'}
            /*className={'absolute flex w-full h-full text-gray-600 cursor-pointer content-center items-center text-center backdrop-blur-[4px] hover:bg-blue-100 hover:bg-opacity-80 hover:text-blue-600 hover:border-blue-400'}>*/
            className={
              'absolute flex w-full h-full text-gray-600 cursor-pointer bg-opacity-60 bg-white content-center items-center text-center hover:bg-blue-100 hover:bg-opacity-90 hover:text-blue-600 hover:border-blue-400'
            }
          >
            <div className="absolute left-[40%]">
              <HiPlusCircle className="h-6 w-6 mr-2 float-left" /> Create New Impact Methodology
            </div>
          </Link>

          <div className="block col-span-10 hover:bg-blue-50 p-4 bg-white">
            <div className="flex items-center justify-between">
              <p className="truncate text-sm font-medium text-blue-600">New methodology name</p>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <p>{new Date().toDateString()}</p>
              </div>
            </div>
          </div>
          <div
            className={
              'justify-center flex col-span-1 items-center hover:bg-red-100 border-0 text-red-500 rounded-none bg-white'
            }
          >
            <RiDeleteBinLine className="h-6 w-6" />
          </div>
        </div>

        {!!impactMethods?.length ? (
          impactMethods.map((impactMethod: ImpactMethod | undefined) => {
            if (!impactMethod) {
              return null;
            }
            return (
              <li key={impactMethod.id}>
                <div className="grid grid-cols-12">
                  <Link to={`${impactMethod.id}`} className="block col-span-10 hover:bg-blue-50 p-4">
                    <div className="flex items-center">
                      <p className="flex-shrink truncate text-sm font-medium text-blue-600">
                        {impactMethod.name} {impactMethod.defaultMethod ? '(Default Methodology)' : ''} refId:{' '}
                        {impactMethod.refId}
                      </p>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <p>{new Date(impactMethod?.created as string).toDateString()}</p>
                      </div>
                    </div>
                  </Link>
                  {!impactMethod.defaultMethod && (
                    <Link to={`edit/${impactMethod.id}`} className="flex col-span-1 items-center hover:bg-blue-100">
                      <RiEditBoxLine className="h-6 w-6 m-auto text-blue-600"></RiEditBoxLine>
                    </Link>
                  )}
                  <ButModal
                    label={<RiDeleteBinLine className="h-6 w-6" />}
                    submitLabel={'Yes'}
                    confirm={() => deleteImpactMethod({ impactMethodId: impactMethod?.id as string })}
                    className={'flex col-span-1 items-center hover:bg-red-100 border-0 text-red-500 rounded-none'}
                  >
                    <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
                      Are you sure you want to delete the "{impactMethod?.name}" impact method?{' '}
                    </h2>
                  </ButModal>
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No Impact Methods</h3>
          </div>
        )}
      </ul>
    </div>
  );
}

export default ListImpactMethods;
