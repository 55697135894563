import { Route, Routes } from 'react-router-dom';
import { StateMachineProvider } from 'little-state-machine';
import { Spinner } from '../../../components/Elements';
import React, { Suspense } from 'react';

const ScenarioAssessment = React.lazy(() => import('../components/ScenarioAssessment'));

export const AssessmentRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="text-center mt-16">
          <Spinner size={'h-16 w-16'} className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
        </div>
      }
    >
      <StateMachineProvider>
        <Routes>
          <Route path=":scenarioId" element={<ScenarioAssessment />} />
        </Routes>
      </StateMachineProvider>
    </Suspense>
  );
};
