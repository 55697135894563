import { IndexedParameter } from '../../customization-process/components/CustomizationTemplateForm';

export const getParameterErrorsForItem = (
  errors: any,
  parameters: IndexedParameter[],
  identifier?: string,
): IndexedParameter[] => {
  const errorIndexes = errors.parameters?.map((ep, index) => (ep ? index : undefined)).filter((i) => i !== undefined);
  return parameters?.filter((p) => errorIndexes?.includes(p.index) && (p.display?.item === identifier || !identifier));
};
