import React, { Fragment } from 'react';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

export type InputTextProps = {
  className?: string;
  addonBefore?: any;
  addonAfter?: any;
  error?: string | undefined;
  value?: string | number | undefined;
  placeHolder?: string;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  hidden?: boolean;
  cursor?: string;
  type?: string;
};

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const {
    type,
    className,
    addonBefore,
    addonAfter,
    value,
    name,
    placeHolder,
    onChange,
    label,
    error,
    description,
    id,
    disabled,
    hidden,
    cursor,
  } = props;

  const renderCounter = useRenderCounter();

  return (
    <div className={classNames(className ? className : '', hidden ? 'hidden' : '', 'relative')}>
      {renderCounter}
      <label className="whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-50% via-transparent via-55% to-transparent absolute -top-[8px] z-[2] left-4 px-1 text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={classNames(
          error ? 'border-red-500' : '',
          'flex rounded border border-gray-300 focus:border-blue-500',
        )}
      >
        {addonBefore && (
          <span className="inline-flex items-center rounded-l border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonBefore}
          </span>
        )}
        <input
          type={type ? type : 'text'}
          disabled={disabled}
          name={name}
          ref={ref}
          id={id}
          style={{ backgroundColor: '#F8FAFC' }}
          className={classNames(
            disabled ? ' text-gray-400 px-3 bg-gray-50' : '',
            cursor ? cursor : '',
            'block pt-4 p-2 w-full flex-1 rounded leading-5 focus-visible:outline-none',
            addonAfter ? 'rounded-r-none border-r-none' : '',
            addonBefore ? 'rounded-l-none border-l-none' : '',
            error
              ? 'placeholder-red-600 placeholder-opacity-70 placeholder:italic focus:placeholder-transparent'
              : 'focus:ring-1 ring-inset focus:ring-blue-500 ',
          )}
          placeholder={error ? error : placeHolder}
          onChange={onChange}
          value={value}
        />
        {error && (
          <Fragment>
            <div className={'bg-gray-50 rounded'}>
              <HiOutlineExclamationCircle
                id={`${name?.replaceAll('.', '')}-error`}
                className="focus:outline-none w-5 text-red-500 flex-shrink-0 self-center h-full focus-visible:outline-none shadow-none cursor-default mr-2"
              />
            </div>
            <Tooltip
              anchorSelect={`#${name?.replaceAll('.', '')}-error`}
              place={'top-end'}
              className="cursor-none"
              variant={'error'}
              style={{ backgroundColor: 'white', color: 'rgb(239, 68, 68)' }}
              border="1px solid rgb(239, 68, 68)"
            >
              {error}
            </Tooltip>
          </Fragment>
        )}
        {addonAfter && (
          <span className="inline-flex items-center rounded-r bg-gray-50 px-2 text-sm text-gray-500">{addonAfter}</span>
        )}
      </div>

      {/*<div className={'h-4'}>*/}
      {/*{error && <p className="text-sm text-red-500">{error}</p>}*/}
      {/*</div>*/}
      {description && <p className="ml-2 mt-1 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
