import { useFormContext } from 'react-hook-form';
import { InputText, SelectControl } from '../../../../components/input';
import React, { useMemo, useState } from 'react';
import { VariableCustomization } from './index';
import { HiArrowsPointingOut } from 'react-icons/hi2';
import FlowSwitchAdditionalFields from './FlowSwitchAdditionalFields';
import classNames from '../../../../utils/classNames';
import DisplayManagement from '../BasicScenarioTemplate/DisplayManagement';
import { DisplayConfiguration, Parameter } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import SlidePanel from '../../../../components/SlidePanel/SlidePanel';
import { getInputError, getInputErrors } from '../../../../lib/getInputError';
import { CiCircleList } from 'react-icons/ci';
import { PiFlowArrow } from 'react-icons/pi';
import { RiFormula } from 'react-icons/ri';
import { VscSymbolString } from 'react-icons/vsc';
import { GoNumber } from 'react-icons/go';

type VariableFormProps = {
  prefix: string;
  includeDisplay?: boolean;
  displayConfigurations: DisplayConfiguration[];
  expanded?: boolean;
  className?: string;
  color?: string;
  slideSize?: 'sm' | 'md' | 'lg' | 'xl';
  additionalInputs?: (props: VariableFormProps, control: any) => React.ReactNode;
} & Parameter;

function BasicParameterForm(props: VariableFormProps) {
  const {
    prefix,
    parameterName,
    additionalInputs,
    slideSize,
    display,
    expanded,
    includeDisplay,
    color,
    displayConfigurations,
    className,
  } = props;

  const [open, setOpen] = useState(false);

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const parameterType = watch(`${prefix}.parameterType`);
  const type = watch(`${prefix}.type`);

  const Customization = useMemo(() => VariableCustomization[parameterType], [parameterType, type]);

  const render = (
    <div className={'bg-white px-4 h-full'}>
      <InputText
        {...register(`${prefix}.alias`)}
        label={'Alias'}
        className="my-3"
        error={getInputError(errors, `${prefix}.alias`)}
      />
      <InputText {...register(`${prefix}.value`)} label={'Default Value'} className="my-3" />
      <InputText {...register(`${prefix}.description`)} label={'Description'} className="my-3" />
      <InputText {...register(`${prefix}.unitOfMeasure`)} label={'Unit'} className="my-3" />
      <InputText {...register(`${prefix}.tooltip`)} label={'Tooltip description'} className="my-3" />
      <InputText {...register(`${prefix}.editable`)} label={'Editable'} className="my-3" />
      <InputText {...register(`${prefix}.hidden`)} label={'Hidden'} className="my-3" />
      {additionalInputs ? additionalInputs(props, control) : undefined}
      {Customization ? <Customization {...props} /> : null}
    </div>
  );
  return (
    <div className={classNames(className ? className : '', 'w-full')}>
      <div className={'flex bg-transparent'}>
        <InputText
          label={'Parameter name'}
          {...register(`${prefix}.parameterName`)}
          className={classNames(color ? `${color} border-2 rounded` : '', 'flex-1 hover:cursor-pointer')}
          error={
            getInputError(errors, `${prefix}.parameterName`) ??
            (Object.keys(getInputErrors(errors, prefix) ?? {}).length > 0 ? 'Error present inside parameter' : '')
          }
          addonBefore={<HiArrowsPointingOut className="w-6 h-6 mt-2 " onClick={() => setOpen(!open)} />}
          addonAfter={(() => {
            switch (parameterType) {
              case 'OPTION':
                return <i>opt</i>;
              case 'NUMERIC':
                return <GoNumber className={'h-6 w-6'} />;
              case 'FORMULA':
                return <RiFormula className={'h-6 w-6'} />;
              case 'STRING':
                return <VscSymbolString className={'h-6 w-6'} />;
              case 'REMOTE':
                return <PiFlowArrow className={'h-6 w-6'} />;
              case 'LIST':
                return <CiCircleList className={'h-6 w-6'} />;
            }
          })()}
        />
      </div>
      {expanded ? (
        <>{render}</>
      ) : (
        <SlidePanel open={open} size={slideSize ? slideSize : 'xl'} setOpen={setOpen} label={parameterName}>
          {render}
        </SlidePanel>
      )}
    </div>
  );
}

export default BasicParameterForm;
