import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { StateMachineProvider } from 'little-state-machine';

import Customization from '../components/Customization';

export const CustomizationRoutes = () => {
  return (
    <StateMachineProvider>
      <Routes>
        <Route path=":scenarioId" element={<Customization />} />
        <Route path=":scenarioId/project/:projectId" element={<Customization />} />
      </Routes>
    </StateMachineProvider>
  );
};
