import React, { Fragment } from 'react';
import { RefCallBack } from 'react-hook-form';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { Tooltip } from 'react-tooltip';

export type InputProps = {
  className?: string;
  error?: string;
  value?: string | number | undefined;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  key?: string;
  inputRef?: RefCallBack;
  hidden?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, value, name, key, onChange, label, error, description, id, disabled, hidden } = props;

  const renderCounter = useRenderCounter();

  return (
    <div
      className={classNames(
        className ? className : '',
        hidden ? 'hidden' : '',
        'relative border rounded border-gray-300',
      )}
      style={{ backgroundColor: '#F8FAFC' }}
    >
      {renderCounter}
      {label && (
        <label className="whitespace-nowrap left-1 bg-gradient-to-t from-[#F8FAFC] from-65%  via-transparent via-65% to-transparent absolute -top-[8px] z-10 px-1 text-xs text-gray-700 rounded">
          {label}
        </label>
      )}
      <div className={`p-2.5 focus-visible:outline-none focus:border-blue-500 ${error ? 'border-red-500' : ''}`}>
        <input
          key={key}
          type={'checkbox'}
          disabled={disabled}
          name={name}
          ref={ref}
          id={id}
          className={classNames(disabled ? 'border-gray-200 text-gray-500 bg-gray-50' : '', '')}
          onChange={onChange}
          value={value}
        />
        {error && (
          <Fragment>
            <div className={'bg-gray-50 rounded '}>
              <HiOutlineExclamationCircle
                id={`${name?.replaceAll('.', '')}-error`}
                className="focus:outline-none w-5 text-red-500 flex-shrink-0 self-center h-full focus-visible:outline-none shadow-none cursor-default"
              />
            </div>
            <Tooltip
              anchorSelect={`#${name?.replaceAll('.', '')}-error`}
              place={'top-end'}
              className="cursor-none"
              variant={'error'}
              style={{ backgroundColor: 'white', color: 'rgb(239, 68, 68)' }}
              border="1px solid rgb(239, 68, 68)"
            >
              {error}
            </Tooltip>
          </Fragment>
        )}
      </div>
      {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  );
});
