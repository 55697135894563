import { Commit } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import React, { useEffect } from 'react';
import { InputText } from '../../../components/input';
import { useForm } from 'react-hook-form';
import { TextArea } from '../../../components/input/TextArea';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from '../../../utils/classNames';

type CreateCommitProps = {
  onChange: (commit: Commit) => void;
  className?: string;
};

const commitValidation = object().shape({
  id: string().nullable(),
  message: string().required('Message is required'),
  author: string().required('Author is required'),
});

function CreateCommit(props: CreateCommitProps) {
  const { register, watch, trigger } = useForm<Commit>({
    resolver: yupResolver(commitValidation),
  });
  const { onChange, className } = props;

  const message = watch('message');
  const author = watch('author');

  useEffect(() => {
    const update = async () => {
      const result = await trigger();
      if (result) {
        onChange({
          message,
          author,
        });
      }
    };
    update();
  }, [message, author]);

  return (
    <div className={classNames(className ? className : '', 'bg-white flex flex-col gap-3')}>
      <TextArea {...register('message')} label="Commit message" className={'flex-1'} rows={10} />
      <InputText {...register('author')} label="Author" />
    </div>
  );
}

export default CreateCommit;
