import { HiArrowPathRoundedSquare } from 'react-icons/hi2';
import { useState } from 'react';
import { useInvokeFlowMutation } from '../../../../api/remoteDispatcher/remoteDispatcherApi';
import { InputText } from '../../../../components/input';
import { ParameterType } from '../../../../api/customizationSpace/types/ParameterType';
import { IndexedParameter } from '../CustomizationTemplateForm';
import { RemoteParameter } from '../../../../api/customizedScenario/customizedScenarioApi';
import { MdOutlineUpdate } from 'react-icons/md';

type RemoteInputProps = IndexedParameter &
  RemoteParameter & {
    parameters: IndexedParameter[];
    label: string;
  };

export const RemoteInput = (props: RemoteInputProps) => {
  const { parameters, flowId, label } = props;

  const [remoteValue, setRemoteValue] = useState<number>();
  const [invokeFlow] = useInvokeFlowMutation();

  const getRemoteValue = async () => {
    const formulaScope: any = parameters?.reduce((acc, p) => {
      if (p.parameterType === 'LIST') {
        p.value = JSON.stringify(p.value);
      }
      if (ParameterType[p.parameterType] !== ParameterType.FORMULA) {
        return {
          ...acc,
          [p.parameterName]: p.value ?? 0,
        };
      } else {
        return {
          ...acc,
        };
      }
    }, {});

    const result = await invokeFlow({
      flowId: flowId as string,
      body: formulaScope,
    });
    // console.log({ rv })
    if (result && !('error' in result)) {
      setRemoteValue(result.data);
    }
  };

  return (
    <InputText
      label={label}
      disabled={true}
      value={remoteValue}
      placeHolder="This value is received by a remote source"
      className={'relative'}
      addonAfter={
        <HiArrowPathRoundedSquare
          className="absolute rounded bg-blue-100 text-blue-700 h-full w-10 px-1 border-2 border-gray-200 right-0 hover:bg-blue-200 cursor-pointer"
          onClick={getRemoteValue}
        />
      }
    />
  );
};
