export function set(obj: any, path: string, value: any) {
  var schema = obj; // a moving reference to internal objects within obj
  var pList = path.split('.');
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
  return schema;
}

export function setFlat(obj: any, path: string, value: any) {
  obj[path] = { ...obj[path], ...value };
}

export const flatten = (data: any) => {
  var result: any = {};
  function recurse(cur: any, prop: any) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

export const get = (from: any, ...selectors: any) =>
  selectors.filter(Boolean).length > 0
    ? [...selectors].map((s) => {
        // console.log(s, selectors, from)
        return s
          .replace(/\[([^\[\]]*)\]/g, '.$1.')
          .split('.')
          .filter((t: any) => t !== '')
          .reduce((prev: any, cur: any) => prev && prev[cur], from);
      })
    : undefined;
