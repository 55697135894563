import React, { Fragment, useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FieldValue } from 'react-hook-form/dist/types/fields';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';
import classNames from '../../utils/classNames';

type VisibleAccordionProps = {
  expand?: boolean;
  children: React.ReactNode;
  className?: string;
  height?: 'sm' | 'md' | 'lg' | 'xl';
  onClick?: () => void;
};

const heightDefault = {
  sm: 'max-h-[10vh]',
  md: 'max-h-[15vh]',
  lg: 'max-h-[20vh]',
  xl: 'max-h-[30vh]',
};

function VisibleAccordion(props: VisibleAccordionProps) {
  const { expand: deafultExpand, onClick, children, className, height } = props;

  const [expand, setExpand] = useState(deafultExpand);

  const actualHeight = height ? heightDefault[height] : heightDefault['md'];

  const handleClick = () => {
    setExpand(false);
    onClick?.();
  };

  return (
    <div
      className={classNames(
        className ? className : '',
        expand ? 'h-full duration-700' : `${actualHeight} overflow-hidden duration-500`,
        'transition-all ease-out relative z-20 shadow',
      )}
    >
      <span className={'flex justify-center items-center cursor-pointer'} onClick={handleClick}>
        <HiChevronUp
          className={classNames(
            expand ? ' opacity-100' : ' opacity-0 ',
            'transition-all ml-2 h-10 w-10 duration-[500ms] ease-out',
          )}
        />
      </span>
      {children}

      {
        <div
          onClick={() => setExpand(true)}
          className={classNames(
            expand ? 'opacity-0 duration-1000 ease-in-out' : 'opacity-100 duration-400 ease-out',
            ' cursor-pointer transition-all',
          )}
        >
          <HiChevronDown
            className={classNames(
              expand ? 'hidden' : '',
              'h-10 w-10 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-20',
            )}
          />
          <div
            // style={{
            //   transition: "height 2000ms"
            // }}
            className={classNames(
              expand ? 'h-0 delay-200 duration-0' : 'h-full duration-100',
              'transition-all w-full top-0 absolute z-10  bg-gradient-to-b from-gray-200/40 via-gray-200/60 to-white/100',
            )}
          ></div>
          {/*className={classNames(expand ? 'max-h-0' : 'max-h-[100vh] h-full',"w-full transition-all duration-[3000ms] top-0 absolute z-10 ")}></div>*/}
        </div>
      }
    </div>
  );
}

export default VisibleAccordion;
