import { array, object, string } from 'yup';
import { SkipTests } from './skipTest';
import { validateParameterExists } from './validateParameterExists';

export const variableConfigurations = array()
  .of(
    object().shape({
      parameterName: string()
        .when(['$skipTests'], ([skipTests], schema, options) => {
          return schema.test({
            name: SkipTests.VARIABLE_MAPPING_TEST,
            message: (v) => {
              return `"${options.parent.name}" is not valid`;
            },
            test: (value, test) => {
              if (skipTests && skipTests.includes(SkipTests.VARIABLE_MAPPING_TEST)) return true;
              const parameters = test.options.context?.parameters;
              return (
                value === 'UNLINKED' ||
                validateParameterExists(
                  parameters.filter((p) => p.type === 'AMOUNT_CHANGE'),
                  value,
                )
              );
            },
          });
        })
        .nullable(),
      name: string().required('Name is required'),
    }),
  )
  .nullable();
