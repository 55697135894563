import { authenticationManagerApi as api } from './authenticationManagerApi';

export const enhancedAuthenticationManagerApi = api.enhanceEndpoints({
  addTagTypes: ['Auth'],
  endpoints: {
    getAllowedRealmsUserRealmGet: {
      providesTags: (result, error, arg) => [{ type: 'Auth', id: arg.username }],
    },
    setDefaultRealmDefaultRealmPost: {
      invalidatesTags: (result, error, arg) => [{ type: 'Auth', id: arg.defaultRealmDto.default_realm }],
    },
  },
});
