import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast/headless';
import { skipToken } from '@reduxjs/toolkit/query';
import { Fragment, useEffect, useState } from 'react';
import { HiArrowDownOnSquare, HiChartPie } from 'react-icons/hi2';

import { ScenarioDto, usePostScenarioMutation } from '../../../api/project/projectApi';
import {
  BomScenarioTemplate,
  Parameter,
  useGetAllScenarioTemplateQuery,
} from '../../../api/scenarioTemplate/scenarioTemplateApi';
import '../style/style.css';
import BillOfMaterial from './../../bom-tree/components/BillOfMaterial';
import { Bom, useGetBomQuery } from '../../../api/bom/bomApi';
import BomItemCustomization from './BomItemCustomization';
import {
  useGetCustomizedProcessQuery,
  usePostCustomizedBomScenarioMutation,
  useUpsertCustomizedProcessMutation,
} from '../../../api/customizedScenario/customizedScenarioApi';
import { createBomCustomizationPayload } from '../utils/createBomCustomizationPayload';
import CustomizationNavBar from './CustomizationNavBar';
import { MenuItem } from './ActionMenu';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { useCalculateBomCustomizedScenarioWithPayloadMutation } from '../../../api/lightweightCalculationEngine/lightweightCalculationEngineApi';
import { getItemDescendants } from '../utils/getItemDescendants';
import { InputText } from '../../../components/input';
import Modal from '../../../components/Modal/Modal';
import { HiOutlineSaveAs } from 'react-icons/hi';
import { getItemScenarioTemplateConfiguration } from '../../template/utils/getItemScenarioTemplateConfiguration';
import { getParameterErrorsForItem } from '../../comparison/utils/getParameterErrorsForItem';
import { IndexedParameter } from './CustomizationTemplateForm';
import classNames from '../../../utils/classNames';

export type BomCustomizationProps = {
  scenario: ScenarioDto;
  scenarioTemplate: BomScenarioTemplate;
  identifier: string;
  triggerAssessment?: boolean;
  onAutoFetch?: (assessment: any, parameters: Parameter[]) => any;
  fromComparison: boolean;
};

export default function BomCustomization(props: BomCustomizationProps) {
  const { scenario, scenarioTemplate, triggerAssessment, identifier, onAutoFetch, fromComparison } = props;

  const navigate = useNavigate();
  const renderCounter = useRenderCounter();

  const [openSaveAs, setOpenSaveAs] = useState<boolean>(false);

  const [saveAsName, setSaveAsName] = useState<string>();
  const { data: bom, isLoading: isBomLoading } = useGetBomQuery({
    bomId: scenarioTemplate.bomId as string,
  });
  const { data: scenarioTemplates, isLoading: areScenarioTemplatesLoading } = useGetAllScenarioTemplateQuery({
    type: 'BASIC',
  });

  const { data: customizedProcess } = useGetCustomizedProcessQuery(
    scenario.id ? { scenarioId: scenario.id as string } : skipToken,
  );
  const [postScenario] = usePostScenarioMutation();
  const [upsertCustomizedProcess] = useUpsertCustomizedProcessMutation();
  const [postCustomizedBomScenario] = usePostCustomizedBomScenarioMutation();
  const [calculateBomCustomizedScenarioWithPayload, { isLoading }] =
    useCalculateBomCustomizedScenarioWithPayloadMutation();

  const {
    formState: { errors },
    getValues,
    trigger,
    watch,
    reset,
  } = useFormContext();
  const parameters = watch('parameters');

  useEffect(() => {
    if (triggerAssessment) {
      goToAssessment();
    }
  }, [triggerAssessment]);

  useEffect(() => {
    if (!isLoading && !isBomLoading && !areScenarioTemplatesLoading) {
      let initialState: { parameters: IndexedParameter[] } = {
        parameters: scenarioTemplate?.parameters?.map((p, index) => ({
          ...p,
          index,
        })) as IndexedParameter[],
      };
      if (customizedProcess && customizedProcess?.refId === scenario.id) {
        customizedProcess.customization?.['parameters']?.forEach(
          (customizationParameter: Parameter, customizedIndex) => {
            const parameter = initialState.parameters.find(
              (p, index) => p.parameterName === customizationParameter?.parameterName || index === customizedIndex,
            );
            if (parameter) {
              parameter.value = customizationParameter?.value ?? parameter.value;
            } else {
              toast.error('Something when wrong');
            }
          },
        );
      }
      reset(initialState);
      debouncedFetching();
    }
  }, [customizedProcess, scenario, bom, scenarioTemplates]);

  const goToAssessment = async () => {
    if (!(Object.keys(errors).length > 0)) {
      const save = await handleSave();
      if (save) {
        const url = `/app/assessments/${scenario?.id}`;
        fromComparison ? window.open(url, '_blank') : navigate(url);
      } else {
        toast.error('Something went wrong during saving scenario');
      }
    } else {
      toast.error('Unable to execute assessments!');
    }
  };

  const handleSave = async () => {
    upsertCustomizedProcess({
      customizedProcess: {
        refId: scenario?.id as string,
        customization: getValues(),
      },
    });
    const payload: any = createBomCustomizationPayload(getValues().parameters, scenarioTemplate, scenarioTemplates);
    // const payload = {}
    toast.success('Scenario successfully saved!');
    if (payload) {
      await postCustomizedBomScenario({
        customizedBomScenario: {
          scenarioId: scenario?.id,
          bomId: scenarioTemplate.bomId as string,
          assessmentConfigurations: scenarioTemplate.assessmentConfigurations,
          customizedItems: payload,
        },
      });
      return true;
    }
    return false;
  };

  const handleSaveAs = async () => {
    const newScenario: ScenarioDto = await postScenario({
      scenarioDto: {
        name: saveAsName,
        projectId: scenario.projectId,
        scenarioTemplateId: scenario.scenarioTemplateId,
        description: scenario.description,
      },
    }).unwrap();
    upsertCustomizedProcess({
      customizedProcess: {
        refId: newScenario?.id as string,
        customization: getValues(),
      },
    });

    if ('error' in newScenario) {
      toast.error('Failed to create new scenario');
    } else {
      toast.success('Scenario created successfully');
    }

    setSaveAsName(undefined);
    setOpenSaveAs(false);
  };

  const executeAssessment = async (shallow: boolean) => {
    const payload = createBomCustomizationPayload(getValues().parameters, scenarioTemplate, scenarioTemplates);

    if (payload) {
      const calculation = await calculateBomCustomizedScenarioWithPayload({
        customizedBomScenarioDto: {
          bomId: bom?.id,
          scenarioId: scenario.id as string,
          assessmentConfigurations: scenarioTemplate.assessmentConfigurations,
          customizedItems: payload,
        },
        shallow,
      });

      if ('error' in calculation) {
        // Handle the error, for example:
        toast.error(calculation.error.data.message);
        // throw new Error('Failed to execute assessment: ' + calculation.error);
      }

      // If there is no error, we can safely assume that the data property exists
      return calculation.data;
    }
  };

  const debouncedFetching = () => {
    if (onAutoFetch) {
      const getData = setTimeout(async () => {
        const valid = await trigger();
        if (valid) {
          onAutoFetch(executeAssessment(true), getValues().parameters);
        }
      }, 400);
      return () => clearTimeout(getData);
    }
  };

  const items: MenuItem[] = [
    {
      name: 'Save customization',
      description: 'Save the current customization',
      onClick: handleSave,
      Icon: HiArrowDownOnSquare,
    },
    {
      name: 'Save as',
      description: 'Save as a new scenario',
      onClick: () => setOpenSaveAs(true),
      Icon: HiOutlineSaveAs,
    },
  ];

  const assessmentItem: MenuItem = {
    name: 'Execute Assessments',
    description: 'Compute sustainability assessments',
    onClick: goToAssessment,
    Icon: HiChartPie,
  };

  return (
    <>
      {isLoading && areScenarioTemplatesLoading && isBomLoading && (
        <div className={'h-full w-full absolute block z-50 cursor-wait'}></div>
      )}
      {renderCounter}
      <CustomizationNavBar
        scenario={scenario}
        items={items}
        assessmentItem={assessmentItem}
        className={classNames(fromComparison ? '' : 'mt-3', 'rounded-t py-2 px-2 flex-shrink block')}
      />

      {bom && (
        <BillOfMaterial
          key={identifier}
          bom={bom as Bom}
          fromComparison={fromComparison}
          nodeConfiguration={{
            nodeContent: (item) => {
              return item.description;
            },
            backgroundColor: (item) => {
              const config = getItemScenarioTemplateConfiguration(
                scenarioTemplate.itemScenarioTemplateConfiguration,
                item,
              );

              if (config) {
                const errorParameters = getParameterErrorsForItem(
                  errors,
                  parameters,
                  `:${config.parentId ? config.parentId + ':' : ''}${config.itemId}`,
                );
                return errorParameters?.length > 0 ? '#de513c' : '#6fd166';
              } else {
                return 'gray';
              }
            },
            borderColor: (item, items) => {
              let color = '#e8e8e8';
              const descendants = getItemDescendants(item, null, items, 'root', false);
              const errorParameters = getParameterErrorsForItem(errors, parameters);
              if (errorParameters && errorParameters.length > 0) {
                errorParameters?.forEach((p) => {
                  if (descendants.some((d) => p.item === `:${d.parentId}${d.itemId}`)) {
                    color = '#e37968';
                  }
                });
              }
              return color;
            },
          }}
          itemContent={(item) => {
            let config = getItemScenarioTemplateConfiguration(scenarioTemplate.itemScenarioTemplateConfiguration, item);

            if (config) {
              const itemIdentifier = `:${config.parentId ? config.parentId + ':' : ''}${item.id}`;
              return (
                <Fragment key={itemIdentifier}>
                  {renderCounter}
                  <BomItemCustomization
                    autoFetch={onAutoFetch ? debouncedFetching : undefined}
                    scenarioTemplateId={config.scenarioTemplateId as string}
                    parameters={
                      scenarioTemplate.parameters
                        ?.map((p, index) => ({
                          ...p,
                          index,
                        }))
                        .filter((p) => p.display?.item === itemIdentifier) as IndexedParameter[]
                    }
                    fromComparison={fromComparison}
                  />
                </Fragment>
              );
            }
            return undefined;
          }}
        />
      )}
      <Modal open={openSaveAs} handleOpen={setOpenSaveAs} confirm={saveAsName ? handleSaveAs : undefined}>
        <InputText value={saveAsName} onChange={(e) => setSaveAsName(e.target.value)} label={'New scenario name'} />
      </Modal>
    </>
  );
}
