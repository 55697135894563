import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import Col from '../Layout/Col';
import I from '../input';

type FormEditable = {
  editing: string;
};

type UseFormReturnEditable = UseFormReturn & FormEditable;

type ItemProps = {
  type: 'InputText' | 'TextArea' | 'InputNumber' | 'Select' | 'MultipleSelect' | 'ColorPicker';
  span?: string;
  name: string;
  label?: string;
  editable?: boolean;
  hidden?: boolean;
  options?: { label: string; value: string }[];
};

export const Item = ({ name, type, span, editable, ...props }: ItemProps) => {
  const { control, formState, editing } = useFormContext() as UseFormReturnEditable;
  const element = I[type];
  const Input = element.input;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={element.defaultValue}
      render={({ field: { ref, ...field } }) => {
        if (props.hidden) {
          return <></>;
        }
        return (
          <Col span={span}>
            <Input
              {...element.props}
              editable={editable}
              error={formState.errors[name]?.message}
              ref={ref}
              {...props}
              {...field}
              disabled={!(editing && (editable || typeof editable === 'undefined'))}
            />
          </Col>
        );
      }}
    />
  );
};
