import React, { Fragment } from 'react';
// import "./styles.css";
import { Toast, useToaster } from 'react-hot-toast/headless';
import { Transition } from '@headlessui/react';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi2';

function Notifications() {
  const { toasts, handlers } = useToaster();

  const { startPause, endPause } = handlers;

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}

      <div
        style={{
          position: 'fixed',
          top: 8,
          left: 8,
          zIndex: 50,
        }}
        onMouseEnter={startPause}
        onMouseLeave={endPause}
      >
        {toasts.map((toast: Toast) => {
          return (
            <div
              key={toast.id}
              aria-live="assertive"
              className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
              <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                  show={toast.visible}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded bg-white shadow ring-1 ring-black ring-opacity-5">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          {toast.type === 'success' ? (
                            <HiCheckCircle className="text-green-400 h-8 w-8" aria-hidden="true" />
                          ) : (
                            <HiXCircle className="text-red-400 h-8 w-8" aria-hidden="true" />
                          )}
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="mt-1 text-sm font-bold text-gray-600">{toast.message?.toString()}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Notifications;
