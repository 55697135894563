import { Commit, useGetAllCommitQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import React, { Fragment } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

type CommitListProps = {
  scenarioTemplateId: string | undefined;
};

function CommitList(props: CommitListProps) {
  const { scenarioTemplateId } = props;

  const { data: commits } = useGetAllCommitQuery(scenarioTemplateId ? { scenarioTemplateId } : skipToken);

  return (
    <Fragment>
      <ul className="divide-y divide-gray-200">
        {!!commits
          ? commits.map((template: Commit | undefined) => {
              if (!template) {
                return null;
              }
              return (
                <li key={template.id}>
                  <div className="grid grid-cols-12">
                    <div className="block col-span-12">
                      <div className="px-4 py-4 sm:px-6 flex">
                        <p className="flex-1 text-md font-medium text-blue-600">{template?.message}</p>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">Author: {template.author}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          : undefined}
      </ul>
    </Fragment>
  );
}

export default CommitList;
