import React, { createContext, useContext, useState } from 'react';

export type BreadcrumbItem = {
  label: string;
  path?: string;
  onClick?: () => void;
};

type BreadcrumbContextValue = {
  items: BreadcrumbItem[];
  addBreadcrumb: (item: BreadcrumbItem) => void;
  setBreadcrumbs: (item: BreadcrumbItem[]) => void;
};

const BreadcrumbContext = createContext<BreadcrumbContextValue>({
  items: [],
  addBreadcrumb: () => {},
  setBreadcrumbs: () => {},
});

type BreadcrumbProviderProps = {
  children: React.ReactNode;
};

export const BreadcrumbProvider = ({ children }: BreadcrumbProviderProps) => {
  const [items, setItems] = useState<BreadcrumbItem[]>([{ label: 'Home', path: '/app' }]);

  const addBreadcrumb = (item: BreadcrumbItem) => {
    setItems((prevItems) => [...prevItems, item]);
  };

  const setBreadcrumbs = (items: BreadcrumbItem[]) => {
    setItems([{ label: 'Home' }, ...items]);
  };

  return (
    <BreadcrumbContext.Provider value={{ items, addBreadcrumb, setBreadcrumbs }}>{children}</BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  const { items, addBreadcrumb, setBreadcrumbs } = useContext(BreadcrumbContext);
  return { items, addBreadcrumb, setBreadcrumbs };
};
