import React from 'react';
import { SimpleSelect } from '../../../../components/input/SimpleSelect';
import { BasicScenarioTemplate } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { Bom } from '../../../../api/bom/bomApi';

type ScenarioTemplateMappingProps = {
  importMapping: any[];
  scenarioTemplates: BasicScenarioTemplate[];
  boms: Bom[];
  onImportMappingChange: (importMapping) => void;
  importJson: string;
  onImportJsonChange: (importJson) => void;
};

function ScenarioTemplateMapping(props: ScenarioTemplateMappingProps) {
  const { importMapping, scenarioTemplates, boms, onImportMappingChange, onImportJsonChange, importJson } = props;

  const handleReplaceValue = (im: any, value: string) => {
    console.log(importJson);
    const newJson = importJson.replaceAll(im.id, value);
    console.log(newJson);
    const mapItem = importMapping.find((i) => i.id === im.id);
    onImportMappingChange((previousValue) => [
      ...previousValue.map((i) => {
        if (i.id === im.id) {
          i.value = value;
        }
        return i;
      }),
    ]);
    onImportJsonChange(newJson);
  };

  return (
    <div>
      {importMapping.map((im) => (
        <div className="my-4">
          <span>
            TYPE: {im.type} id: {im.id} - name: {im.name}
          </span>
          {im.type === 'SCENARIO_TEMPLATE' ? (
            <SimpleSelect
              options={scenarioTemplates.map((p: BasicScenarioTemplate) => ({
                value: p.id as string,
                label: p.name as string,
              }))}
              onChange={(value) => handleReplaceValue(im, value)}
              value={im.value}
            />
          ) : null}
          {im.type === 'BOM' ? (
            <SimpleSelect
              options={boms.map((p: Bom) => ({
                value: p.id as string,
                label: p.description as string,
              }))}
              onChange={(value) => handleReplaceValue(im, value)}
              value={im.value}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default ScenarioTemplateMapping;
