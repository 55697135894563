import { Route, Routes } from 'react-router-dom';
import { Boms } from './Boms';
import BomComponent from '../components/BomComponent';

export const BomRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Boms />} />
      <Route path="inspect/:bomId" element={<BomComponent />} />
    </Routes>
  );
};
