import React, { useMemo } from 'react';
import { SelectControl } from '../../../../components/input';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  DisplayConfiguration,
  DisplayOption,
  ItemScenarioTemplatePair,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';

type DisplayManagementProps = {
  parameterPrefix: string;
  displayConfigurations: DisplayConfiguration[];
  display: DisplayOption | undefined;
};

function DisplayManagement(props: DisplayManagementProps) {
  const { parameterPrefix, displayConfigurations, display } = props;

  const { setValue } = useFormContext();

  const phaseId = useWatch({ name: `${parameterPrefix}.phaseId` });
  const groups = useWatch({ name: `groups` });
  const parameters = useWatch({ name: `parameters` });
  const itemScenarioTemplateConfiguration: ItemScenarioTemplatePair[] = useWatch({
    name: 'itemScenarioTemplateConfiguration',
  });

  const availableTabs = useMemo(() => {
    return displayConfigurations?.find((pc) => pc.phaseId === phaseId)?.tabs ?? [];
  }, [phaseId, displayConfigurations]);

  return (
    <div className="grid grid-cols-2 gap-2 mt-4">
      {display?.item && (
        <SelectControl
          className={'col-span-2'}
          confirm={true}
          name={`${parameterPrefix}.item`}
          options={itemScenarioTemplateConfiguration?.map((ist) => ({
            label: `:${ist.parentId}:${ist.itemId}`,
            value: `:${ist.parentId}:${ist.itemId}`,
          }))}
        />
      )}
      <SelectControl
        label={'Display phase'}
        className={'col-span-2'}
        name={`${parameterPrefix}.phaseId`}
        options={displayConfigurations?.map((pc) => ({
          label: pc.phaseName as string,
          value: pc.phaseId as string,
        }))}
      />
      <SelectControl
        label={'Display tab'}
        className={'col-span-2 flex-1'}
        name={`${parameterPrefix}.tab`}
        options={
          availableTabs.map((s) => ({
            label: s.name,
            value: s.name,
          })) as []
        }
      />
      <SelectControl
        label={'Display Group'}
        className="flex-1"
        name={`${parameterPrefix}.group`}
        options={[
          ...[...(groups ?? [])].map((g) => ({
            label: g.name,
            value: g.name,
          })),
          { label: 'No group', value: null },
        ]}
      />

      <SelectControl
        label={'Belongs to formula parameter'}
        className="flex-1"
        name={`${parameterPrefix}.formulaParameter`}
        onChange={(e) => {
          const parameter = parameters.find((p) => p.parameterName === e);
          setValue(`${parameterPrefix}.tab`, parameter.display.tab);
          setValue(`${parameterPrefix}.phaseId`, parameter.display.phaseId);
        }}
        options={[
          ...parameters?.map((s) => ({
            label: s.parameterName,
            value: s.parameterName,
          })),
          { label: 'Not a formula parameter', value: null },
        ]}
      />
    </div>
  );
}

export default DisplayManagement;
