import { ItemScenarioTemplatePair } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Item } from '../../../api/bom/bomApi';

export const getItemScenarioTemplateConfiguration = (
  itemScenarioTemplateConfiguration: ItemScenarioTemplatePair[] | undefined,
  item: Item & {
    parentId?: string;
  },
) => {
  let config = itemScenarioTemplateConfiguration?.find((i) => i.itemId === item.id && i.parentId === item.parentId);
  if (!config) {
    //if an individual config does not exist I search for a specific one
    config = itemScenarioTemplateConfiguration?.find((i) => i.itemId === item.id && !i.parentId);
  }
  return config;
};
