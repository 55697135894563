import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from '../../../components/Elements';

const ListCustomizationSpace = React.lazy(() => import('../components/ListCustomizationSpace'));
const CustomizationUnitView = React.lazy(() => import('../components/CustomizationUnitView'));

export const CustomizationSpaceRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="text-center mt-16">
          <Spinner size={'h-16 w-16'} className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
        </div>
      }
    >
      <Routes>
        <Route path="" element={<ListCustomizationSpace />} />
        <Route path=":customizationSpaceRefId" element={<CustomizationUnitView />} />
      </Routes>
    </Suspense>
  );
};
