import { HiFolderOpen } from 'react-icons/hi2';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PhaseDto, useDeletePhaseMutation } from '../../../api/project/projectApi';
import { Button, Link } from '../../../components/Elements';
import React from 'react';
import { toast } from 'react-hot-toast/headless';

type ListPhaseProps = {
  phases?: PhaseDto[];
};

function ListPhase(props: ListPhaseProps) {
  const { phases } = props;

  const [deletePhase] = useDeletePhaseMutation();

  const handleDelete = async (phaseId: string) => {
    const response: any = await deletePhase({ phaseId: phaseId });

    if (response.error) {
      toast.error('Failed to delete phase');
    } else {
      toast.success('Phase deleted successfully');
    }
  };

  return (
    <div className="bg-white shadow rounded">
      <ul className="divide-y divide-gray-200">
        {!!phases?.length ? (
          phases.map((phase: PhaseDto) => {
            if (!phase) {
              return null;
            }
            return (
              <li key={phase.id}>
                <div className="grid grid-cols-12">
                  <Link to={`edit/${phase.id}`} className="block col-span-10 hover:bg-blue-50 p-4">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-600">{phase.name}</p>
                    </div>
                  </Link>
                  <Button
                    variant="inverse"
                    className="flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none"
                    onClick={() => handleDelete(phase.id as string)}
                  >
                    <RiDeleteBinLine className="h-6 w-6" />
                  </Button>
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No Phases</h3>
          </div>
        )}
      </ul>
    </div>
  );
}

export default ListPhase;
