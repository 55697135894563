import '../style/style.css';
import React, { Fragment, useMemo, useState } from 'react';
import { ScenarioDto, Tag, useGetAllTagQuery, usePostScenarioMutation } from '../../../api/project/projectApi';
import { HiXCircle } from 'react-icons/hi2';
import { RxPlus } from 'react-icons/rx';
import { RiCloseLine } from 'react-icons/ri';
import { SimpleSelect } from '../../../components/input/SimpleSelect';
import { Button } from '../../../components/Elements';

type ScenarioTagSelectorProps = {
  scenario: ScenarioDto;
};

export default function ScenarioTagSelector(props: ScenarioTagSelectorProps) {
  const { scenario } = props;

  const [triggerAddTag, setTriggerAddTag] = useState(false);
  const [postScenario] = usePostScenarioMutation();
  const { data: allTags } = useGetAllTagQuery({});

  const availableTags = useMemo(() => {
    return scenario
      ? allTags?.filter((tag) => {
          return !scenario.tags?.some((t) => t.id === tag.id);
        })
      : [];
  }, [scenario, allTags]);

  const handleTriggerAddTag = () => {
    setTriggerAddTag(!triggerAddTag);
  };

  const handleAddTagToScenario = (tagId: string) => {
    setTriggerAddTag(false);
    let tags = scenario.tags?.map((t: Tag) => ({ id: t.id }));
    tags?.push({ id: tagId });
    postScenario({ scenarioDto: { ...scenario, tags: tags } });
  };

  const handleRemoveTag = (tagId: string) => {
    let tags = scenario.tags?.filter((t: Tag) => t.id !== tagId).map((t: Tag) => ({ id: t.id }));
    postScenario({ scenarioDto: { ...scenario, tags: tags } });
  };

  // console.log("s.id: ", scenario.id, " tags:  ", scenario.tags)

  return (
    <Fragment>
      {scenario?.tags?.map((tag) => {
        return (
          <div
            style={{ backgroundColor: tag.color, borderWidth: '1px', borderColor: tag.color }}
            className="h-8 inline-flex items-center mr-2 gap-x-2.5 px-2 text-sm font-semibold text-white rounded shadow"
          >
            <HiXCircle
              className="cursor-pointer h-6 w-6"
              aria-hidden="true"
              onClick={() => handleRemoveTag(tag.id as string)}
            />
            {tag.name}
          </div>
        );
      })}
      <Button
        variant="none"
        className="tag h-8 items-center bg-white hover:bg-blue-400 hover:text-white rounded flex mr-2"
        onClick={handleTriggerAddTag}
        size={'md'}
      >
        {triggerAddTag ? (
          <Fragment>
            <div>
              <RiCloseLine className="mr-2 h-6 w-5" />
            </div>
            <div>Cancel</div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <RxPlus className="mr-2 h-6 w-5" />
            </div>
            <div>tag</div>
          </Fragment>
        )}
      </Button>

      {triggerAddTag && availableTags && (
        <SimpleSelect
          id={`${scenario?.id}.tags`}
          size={'sm'}
          className="-mb-1"
          options={availableTags.map((t) => ({
            value: t?.id as string,
            label: t?.name as string,
          }))}
          value=""
          label={'Tags'}
          onChange={(value) => handleAddTagToScenario(value)}
        />
      )}
    </Fragment>
  );
}
