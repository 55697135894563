import React, { Fragment, ReactElement, useState } from 'react';
import { Button } from '../Elements';
import classNames from '../../utils/classNames';
import SlidePanel from './SlidePanel';

type ButSlidePanelProps = {
  id?: string;
  children: React.ReactNode;
  buttonLabel?: string | ReactElement;
  label?: string | ReactElement;
  variant?: 'primary' | 'inverse' | 'none' | 'danger';
  buttonSize?: 'none' | 'sm' | 'md' | 'lg' | 'xs';
  className?: string;
};

function ButSlidePanel(props: ButSlidePanelProps) {
  const { id, children, label, buttonLabel, buttonSize, variant, className } = props;

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Button
        id={id}
        variant={variant ? variant : 'none'}
        size={buttonSize}
        className={classNames(className ? className : '')}
        onClick={() => setOpen(true)}
      >
        {buttonLabel}
      </Button>
      <SlidePanel {...props} open={open} setOpen={setOpen}>
        {children}
      </SlidePanel>
    </Fragment>
  );
}

export default ButSlidePanel;
