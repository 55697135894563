import { Route, Routes } from 'react-router-dom';
import CreateProject from '../components/CreateProject';
import Project from '../components/Project';
import { Projects } from './Projects';

export const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Projects />} />
      <Route path=":projectId" element={<Project />} />
      <Route path="/edit/:projectId" element={<Project />} />
      <Route path="/new" element={<CreateProject />} />
    </Routes>
  );
};
