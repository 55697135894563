import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useAuth } from '../AuthProvider';
import { AuthDTO } from '../types/authTypes';
import logo from '../../../logo.png';
import { InputText } from '../../../components/input';
import { Button } from '../../../components/Elements';
import React, { useMemo } from 'react';
import { toast } from 'react-hot-toast/headless';
import storage from '../../../utils/storage';
import '../style/style.css';

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const { login } = useAuth();

  // console.log(location.)

  const handleOnSubmit = (values: AuthDTO) => {
    const realm = storage.getLastLoggedInRealm() || '';

    login(
      values.username,
      values.password,
      realm,
      () =>
        queryParams.has('redirectUrl') ? navigate(queryParams.get('redirectUrl') as string) : navigate('/app/projects'),
      () => {
        toast.error('Username or password is incorrect');
        // console.log("Inside the onError: has redirect? ", queryParams.has("redirectUrl"))
        // console.log("Inside the onError: get redirect? ", queryParams.get("redirectUrl"))
        if (queryParams.has('redirectUrl')) {
          // navigate(('/login?redirectUrl=' + queryParams.get('redirectUrl')) as string);
        }

        if (realm != '') {
          storage.setLastLoggedInRealm(realm);
        }
      },
    );
  };

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <div className="bg-[#eef2f6] items-center flex flex-col login-page">
        <div className="w-7xl px-auto px-14 pt-12 pb-16 flex flex-col bg-white rounded mt-36 shadow">
          <div className={'relative w-[21.5rem] h-32'}>
            <div className="absolute top-0 left-0">
              <img className="block h-28 w-auto" src={logo} alt="GRETA" />
            </div>
          </div>

          <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="rounded pt-3 flex gap-9 flex-col">
            <InputText {...methods.register('username')} label="Username" />
            <InputText {...methods.register('password')} label="Password" type="password" />
            <Button
              variant={'none'}
              type="submit"
              className="w-full text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white rounded"
            >
              LOGIN
            </Button>
          </form>
        </div>
      </div>
    </FormProvider>
  );
};
