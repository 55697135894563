import { scenarioTemplateApi as api } from './scenarioTemplateApi';

export const enhancedScenarioTemplateApi = api.enhanceEndpoints({
  addTagTypes: ['Template'],
  endpoints: {
    getScenarioTemplate: {
      providesTags: (result, error, arg) => [{ type: 'Template', id: arg.scenarioTemplateId }],
    },
    getAllScenarioTemplate: {
      providesTags: (result, error, arg) => [{ type: 'Template', id: 'LIST' }],
    },
    postScenarioTemplate: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Template', id: result?.id },
        { type: 'Template', id: 'LIST' },
      ],
    },
    deleteScenarioTemplate: {
      invalidatesTags: (result, error, arg) => [
        { type: 'Template', id: arg.scenarioTemplateId },
        { type: 'Template', id: 'LIST' },
      ],
    },
  },
});
